App.Helpers = {

    // Repeated in php: Gig#setCurrency
    CURRENCIES: [
        '£', // GBP / CYP
        '$', // USD / CAD / ARS / COP / CLP
        '€', // EUR
        '₹', // INR Indian rupee
        '฿', // THB Thai Baht
        '¥', // CNY
        'R$', // BRL
        'RM' // MYR
    ],

    DATEPICKER_CONFIG: {
        format: 'dd M yyyy',
        autoclose: true,
        clearBtn: true,
        todayBtn: true,
        todayHighlight: true,
        weekStart: 1
    },

    Validation: {
        isTime: function(str) {
            return moment(str, ["HH:mm"], true).isValid();
        },

        isInteger: function(value) {
            return !isNaN(value) &&
                parseInt(Number(value)) == value &&
                !isNaN(parseInt(value, 10));
        },

        isFloat: function(n) {
            return n != "" && !isNaN(n) && Math.round(n) != n;
        }
    },

    Format: {
        thousands: function(val) {
            if (isNaN(val)) { return val }
            if (typeof val == 'undefined' || val == null) { return ''; }
            return val == '' ? '' : val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        money: function(val, currency) {
            if (isNaN(val)) { return val }
            if (typeof val == 'undefined' || val == null) { return ''; }
            return val == '' ? '' :
                (currency +
                    App.Helpers.Format.thousands(Math.floor(val)) +
                    '.' +
                    parseFloat(val).toFixed(2).split('.')[1]);
        },

        duration: function(val) {
            if (isNaN(val)) { return val }
            if (typeof val == 'undefined' || val == null || val == '') { return ''; }

            var hours = Math.floor(val / 60),
                minutes = val % 60;

            return hours + "hr " + minutes;
        }
    },

    Notice: {
        _timeout: null,
        show: function(text, type, duration) {
            $('.js-notice-text').html(text);
            $('.js-notice').attr('class', 'notice js-notice notice--visible notice--' + type);
            clearTimeout(App.Helpers.Notice._timeout);
            App.Helpers.Notice._timeout = setTimeout(function() {
                $('.js-notice').removeClass('notice--visible');
            }, duration);
        }
    },

    _templates: {},
    getTemplate: function(selector) {
        if (typeof selector == 'undefined' || selector == '') {
            throw "Empty template selector";
        }

        if (typeof App.Helpers._templates[selector] != 'undefined') {
            return App.Helpers._templates[selector];
        }

        var $el = $(selector);
        if ($el.length == 0) {
            throw ("No template found: " + selector);
        }

        return App.Helpers._templates[selector] =
            _.template( $(selector).html() );
    },

    getGig: function() {
        return App.Models.Gig.findOrCreate(App.Helpers.getGigId());
    },

    getGigId: function() {
        return /\/gigs\/(\d+)/.exec(window.location.href)[1];
    },

    slugifyActName: function(actName) {
        return actName.toLowerCase()
            .replace(/&[^ ]+/g, '') // remove &amp;
            .replace(/\s+/g, '-')
            .replace(/[^a-z0-9\-]/g, '');
    },

    readableAddress: function(address) {
        var str = _.escape(address.street);
        if (address.city != null) {
            str += '<br />' + _.escape(address.city);
            if (address.post_code == null) {
                str += '<br />';
            } else {
                str += ', ';
            }
        }
        if (address.post_code != null) {
            str += _.escape(address.post_code);
        }
        if (address.country != null) {
            str += '<br />' + _.escape(address.country);
        }
        return str;
    },

    readableCity: function(address) {
        var str = _.escape(address.city);
        if (address.country  != null) {
            str += ', ' + _.escape(address.country);
        }
        return str;
    },

    gmapAddress: function(a) {
        var center = a.street;
        if (a.post_code != null) { center += ', ' + a.post_code; }
        if (a.city != null) { center += ', ' + a.city; }
        if (a.country != null) { center += ', ' + a.country; }

        // Or just use lat/long if we have it
        if (a.latitude && a.longitude) {
            center = a.latitude + ',' + a.longitude;
        }

        return center;
    },

    gmapImageUrl: function(address) {
        var center = this.gmapAddress(address);

        return "https://maps.googleapis.com/maps/api/staticmap" +
            "?center=" + encodeURI(center) +
            "&zoom=15" +
            "&size=360x180" +
            "&maptype=roadmap" +
            "&markers=" + encodeURI("color:red|"+ center);
    },

    gmapHrefUrl: function(address) {
        var center = this.gmapAddress(address);

        return "https://maps.google.com/maps" +
            "?q=" + encodeURI(center) +
            "&z=14" +
            "&t=m";
    },

    // Take any format of datetime and turn it into the type which the API expects
    normalizeDateTime: function(string, pattern) {
        var m = moment(string, pattern);
        return m.format('YYYY-MM-DD\THH:mm:ss');
    },

    normalizeDate: function(string, pattern) {
        var m = moment(string, pattern);
        return m.format('YYYY-MM-DD');
    },

    hoursRoundDays: function(hours) {
        if (hours > 48) {
            return Math.floor(hours / 24) + 'd';
        }
        return hours + 'h';
    },

    // 2019 API Platform
    mapIris: function(json, fields) {
        // No need for us to try and support posting collections
        const SKIPS = ['party_users', 'trips'];

        const URL_PART_MAP = {
            account_user: 'account_users',
            folder: 'folders',
            from_address: 'addresses',
            gig: 'gigs',
            hotel: 'hotels',
            loc_address: 'addresses',
            party_user: 'party_users',
            schedule_item: 'schedule_items',
            to_address: 'addresses',
            trips: 'trips',
            venue: 'venues',
        };

        fields.forEach(field => {
            const val = json[field];
            if (!val) return;

            if (SKIPS.includes(field)) {
                if (Array.isArray(val)) {
                    delete json[field];
                }
                return;
            }

            const urlPart = URL_PART_MAP[field];
            if (!urlPart) throw new Error(`mapIris with unknown field: ${field}`);

            const id = typeof val === 'object' ? val.id : val;
            json[field] = `/api-platform/${urlPart}/${id}`
        })

        return json;
    },

    // 2019 API Platform
    stripIris: function(json, fields) {
        function stripIri(input) {
            if (typeof input === 'object') {
                // No need to do anything
                return input;
            } else if (typeof input === 'string') {
                return parseInt(input.replace(/^\/api-platform\/[^w\-]+\//, ''));
            } else {
                throw new Error('Unknown iri piece: ' + typeof input);
            }
        };
        fields.forEach(field => {
            const val = json[field];
            if (!val) return;

            if (Array.isArray(val)) {
                json[field] = val.map(item => stripIri(item));
                return;
            }

            json[field] = stripIri(val);
        });
        return json;
    }
};