App.Models.ScheduleItem = App.Models.Base.extend({
    subModelTypes: {
        'journey': 'App.Models.Journey',
        'memo': 'App.Models.Memo'
    },

    relations: [
        {
            type: Backbone.HasMany,
            key: 'trips',
            relatedModel: 'App.Models.Trip',
            collectionType: 'App.Collections.Trips',
            reverseRelation: {
                key: 'schedule_item',
                includeInJSON: 'id'
            }
        }
    ],

    determineAddressType: function (gig, dir) {
        var journeyAddress = this.get(dir + '_address');
        var venueAddress = gig.get('venue').get('address');
        var hotelAddress = gig.get('hotel') && gig.get('hotel').get('address');

        if (journeyAddress && venueAddress && journeyAddress.id === venueAddress.id) {
            return 'venue';
        } else if (journeyAddress && hotelAddress && journeyAddress.id === hotelAddress.id) {
            return 'hotel';
        } else if (this.get(dir + '_name')) {
            return 'other';
        } else {
            return null;
        }
    }
});