App.Views.Venue = App.Views.BaseItem.extend(
    _.extend({}, App.Mixins.Typeahead, {

    editTemplate: '#template-venue',

    events: {
        "click .js-typeahead-change": 'onChangeTypeahead',
        "click .js-typeahead-add": 'onSaveNewTypeahead'
    },

    initialize: function () {
        this.buildEvents();
    },

    serializeData: function() {
        return {
            gig: this.model.toJSON(),
            errors: this.model.validationError
        };
    },

    onRender: function () {
        this.initTypeahead(
            'venues',
            App.Models.Venue,
            // We can't allow them to add venues, as we don't know the timezone
            { allowAdd: false }
        );
    },

    onBeforeSave: function() {
        // Update the venue for the gig
        this.model.set('venue', this.$('.js-typeahead-id').val());
    },

    onSaveSynced: function() {
        this.render();
    }
}));