// @flow

import type { V_Act_TableDetail } from '../../../../models';

import { darken } from 'polished';
import * as React from 'react';
import { useForm } from 'react-typed-form';
import styled from 'styled-components';

import { useApiFormSubmit } from '../../../../api';
import { Spacer } from '../../../../components';
import { FieldText, FormWrap, createFormValidator } from '../../../../form';

type Props = $ReadOnly<{|
  existing: V_Act_TableDetail,
  onSuccess: () => mixed,
  onDismiss: () => mixed,
|}>;

export default function SectionGeneralForm({
  existing,
  onSuccess,
  onDismiss,
}: Props) {
  const formSubmit = useApiFormSubmit('PUT', `/acts/${existing.id}`, {
    onSuccess,
  });
  // eslint-disable-next-line flowtype/no-weak-types
  const { formErrorList, getField, handleSubmit, isLoading } = useForm<any>({
    pristineValues: existing,
    validate: createFormValidator({
      agencyDiaryLinkList: {
        allLinesFormat: {
          pattern:
            /^([a-z0-9][a-z0-9 -]+?\s+<[a-z0-9.\-:/[\]#]+>\s*|[a-z0-9.\-:/[\]#]+)$/i,
          message: '^Please follow the requested format, with no empty lines',
        },
      },
    }),
    onSubmit: formSubmit,
  });

  return (
    <StyledContainer>
      <FormWrap formErrorList={formErrorList} handleSubmit={handleSubmit}>
        <FieldText field={getField('accountNumber')} />

        <Spacer />
        <div style={{ display: 'flex' }}>
          <StyledButton type="submit" disabled={isLoading} $cta>
            Save
          </StyledButton>
          <Spacer size={0.5} />
          <StyledButton type="button" disabled={isLoading} onClick={onDismiss}>
            Cancel
          </StyledButton>
        </div>
      </FormWrap>
    </StyledContainer>
  );
}

const StyledContainer = styled.div``;

const COLOR_CTA = '#5a9dd7';
const COLOR_NORMAL = '#999';

const StyledButton = styled.button`
  border: 0;
  background: transparent;
  padding: 8px 15px;
  color: ${(p) => (p.$cta ? COLOR_CTA : COLOR_NORMAL)};
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 17px;
  border: 1px #5a9dd7 solid;
  border-color: ${(p) => (p.$cta ? COLOR_CTA : COLOR_NORMAL)};
  border-radius: 8px;

  &:hover:not([disabled]) {
    color: ${(p) => darken(0.2, p.$cta ? COLOR_CTA : COLOR_NORMAL)};
    border-color: ${(p) => darken(0.2, p.$cta ? COLOR_CTA : COLOR_NORMAL)};
  }

  &[disabled] {
    cursor: default;
  }
`;
