// @flow

import { normalize } from 'normalizr';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { useApiCallable } from '../api';
import { SoloMessage, Spinner } from '../components';
import { useAccountUser } from '../hooks';
import * as pages from '../pages';
import * as GigLegacy from '../pods/gig-legacy';
import * as GigsCalendar from '../pods/gigs-calendar';
import * as GigsGrid from '../pods/gigs-grid';
import * as GigsTable from '../pods/gigs-table';
import * as Profile from '../pods/profile';
import * as Static from '../pods/static';
import schemas from '../schemas';
import { logError, snakeToCamel, transformKeysRecursive } from '../util';

export default function AppAuthedAccount() {
  const dispatch = useDispatch();
  const hardEntities = useSelector((state) => state.hardEntities);
  const { callApi } = useApiCallable();
  const user = useAccountUser();

  GigLegacy.useLegacyConfig({ apiKey: user.apiKey, staff: user.staff });

  const [error, setError] = React.useState<'network' | 'unknown' | null>(null);
  React.useEffect(() => {
    async function fetchHardEntities() {
      if (!user.staff) return;

      try {
        const results = await Promise.all([
          callApi({ path: '/acts/hard' }),
          // Fetch venues later as the payload is so massive
          // callApi({ path: '/venues/typeahead' }),
        ]);
        const data = transformKeysRecursive(
          {
            acts: results[0].data,
            // venues: results[1].data,
          },
          snakeToCamel
        );
        dispatch({
          type: 'HARD_ENTITIES_INITIAL_SUCCESS',
          payload: normalize(data, {
            acts: [schemas.Act],
            // venues: [schemas.Venue],
          }).entities,
        });
      } catch (err) {
        if (err.message?.includes('NetworkError')) {
          setError('network');
          return;
        }
        logError(err, 'fetchHardEntities');
        setError('unknown');
        return;
      }
    }
    fetchHardEntities();
  }, [callApi, dispatch, user.staff]);

  if (!user.staff) {
    return (
      <SoloMessage
        title="Access Denied"
        description="Your account cannot access the Rooted website. Please use the mobile apps instead."
      />
    );
  }

  if (error === 'network') {
    return (
      <SoloMessage
        title="Could Not Connect"
        description="Couldn't reach the servers.  Please make sure you're connected to the Internet and try again."
      />
    );
  } else if (error === 'unknown') {
    return (
      <SoloMessage
        title="An Error Occurred"
        description="Sorry, something went wrong.  Please try again, or report the problem if it persists."
      />
    );
  }

  if (!hardEntities) return <Spinner message="Initialising" />;

  return (
    <Switch>
      <Route path="/" exact component={pages.GigsRedirectPage} />
      <Route path="/apps" exact component={Static.AppsPage} />
      <Route path="/login" exact component={pages.GigsRedirectPage} />
      <Route path="/profile" exact component={Profile.ProfilePage} />
      <Route path="/help" exact component={Static.HelpPage} />
      <Route path="/gigs" exact component={pages.GigsRedirectPage} />
      <Route path="/gigs/calendar" exact component={pages.GigsRedirectPage} />
      <Route path="/gigs/grid" exact component={pages.GigsRedirectPage} />
      <Route path="/gigs/table" exact component={pages.GigsRedirectPage} />
      <Route
        path="/gigs/calendar/lead-:leadFilter/act-:actFilter/:periodFilter"
        exact
        component={GigsCalendar.GigsCalendarPage}
      />
      <Route
        path="/gigs/grid/lead-:leadFilter/act-:actFilter/:periodFilter"
        exact
        component={GigsGrid.GigsListPage}
      />
      <Route
        path="/gigs/table/lead-:leadFilter/act-:actFilter/:periodFilter"
        exact
        component={GigsTable.GigsTablePage}
      />
      <Route path="/gigs/:id" exact component={GigLegacy.GigPage} />
    </Switch>
  );
}
