// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';

import SplashWrap from './SplashWrap';

export default function LandingPage() {
  return (
    <SplashWrap>
      <img src="/img/logo-vert.png" className="splash__logo" />
      <Link className="splash__button" to="/login">
        Sign in
      </Link>
    </SplashWrap>
  );
}
