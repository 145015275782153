// @flow

export default {
  updateNotification: 800,

  modal: 700,

  header: 500,

  loading: 300,

  thead: 270,

  dropdown: 250,
  typeaheadSuggestions: 200,

  fab: 20,

  table: 10,
};
