// @flow

import * as React from 'react';
import styled from 'styled-components';

function Ellipsis() {
  return (
    <StyledContainer>
      <div />
      <div />
      <div />
    </StyledContainer>
  );
}

export default React.memo<{||}>(Ellipsis);

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 5px;
    height: 5px;
    background-color: #fff;
    border-radius: 50%;
    margin: 1.5px;
  }
`;
