// @flow

import type { Action } from '../actions';

export type AccountUser = $ReadOnly<{|
  type: 'Account',
  email: string,
  apiKey: string,
  id: number, // AccountUser id
  firstName: string,
  lastName: string,
  staff: boolean,
|}>;

export type TokenUser = $ReadOnly<{|
  type: 'Token',
  token: string,
  id: number,
  gig: number,
|}>;

export type AuthState = $ReadOnly<{|
  user: AccountUser | TokenUser | null,
|}>;

const initialState = {
  user: null,
};

export default function auth(
  state: AuthState = initialState,
  action: Action
): AuthState {
  switch (action.type) {
    case 'LOGIN_ACCOUNT':
      return { user: action.payload };
    case 'LOGIN_TOKEN':
      return { user: action.payload };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}
