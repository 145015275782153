App.Models.Contact = App.Models.Base.extend({

    urlRoot: () => App.config.baseUrl + 'api-platform/contacts',

    defaults: {
        type: 'promoter'
    },
    
    iriFields: ['gig'],

    validate: function(attrs, options) {
        var errors = {};

		if(!attrs.name) {
            errors.name = 'Please supply a contact name';
        }

        if(!attrs.type) {
            errors.type = 'Please supply a contact type';
        }

        if (attrs.type === 'other' && !attrs.type_custom) {
            errors.type_custom = 'Please specify the contact type';
        }

        if(!attrs.phone) {
            errors.phone = 'Please supply a phone number';
        } else {
            if(!attrs.phone.match(/^\+\d{7,15}$/)) {
                errors.phone = 'Please supply a valid phone number'
            }
        }

        if(!_.isEmpty(errors)) {
            return errors;
        }
    }
});