'use strict';

App.Collections.Folders = Backbone.Collection.extend({

    model: App.Models.Folder,
    url: () => App.config.baseUrl + 'api-platform/folders',

    comparator: function(f) {
        return f.get('name').toLowerCase();
    }

});