// @flow

import type { GigTableEntities } from '../../../hooks/entities-store';
import type { V_Gig_Table } from '../../../models';
import type { TableRowBackgroundColor } from '../../../types';
import type { ItemModalState } from '../types';

import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Icon, TableCell, TableCellButton } from '../../../components';
import { Color } from '../../../constants';
import { useHardEntities } from '../../../hooks';
import { deserializeLinkList } from '../../../models/Act';
import { values } from '../../../util';
import { calculateItinStatus } from '../itin-statuses';
import * as Advancing from '../pods/advancing';
import * as Deal from '../pods/deal';
import * as Hotel from '../pods/hotel';
import * as Travel from '../pods/travel';
import * as TravellingParty from '../pods/travelling-party';
import AddPrompt from './AddPrompt';
import Ellipsis from './Ellipsis';
import NotesPrompt from './NotesPrompt';
import PaymentsCell from './PaymentsCell';
import RowStandardLeft from './RowStandardLeft';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  entities: GigTableEntities,
  setItemModal: (ItemModalState) => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
|}>;

export default React.memo<Props>(function GigRow({
  gig,
  entities,
  setItemModal,
}: Props) {
  const act = useHardEntities().Act[gig.act];
  if (!act) throw new Error(`Missing hard act with IRI: ${gig.act}`);
  const sfxLinkList = deserializeLinkList(act.sfxLinkList);

  const venue = entities.Venue[gig.venue];
  if (!venue) throw new Error(`Missing venue with IRI: ${gig.venue}`);

  const gigLink = `/gigs/${gig.id}`;

  const itinStatus = calculateItinStatus(gig, entities);

  const focusSet = values(entities.ArtistSet)
    .filter((as) => as?.gig === gig['@id'])
    .filter(Boolean)
    .find((as) => as.focus);
  let setTimesMatch = null;
  if (focusSet && (gig.contractSetStartAt || gig.contractSetFinishAt)) {
    if (gig.contractSetStartAt && gig.contractSetStartAt !== focusSet.startAt) {
      setTimesMatch = false;
    } else if (
      gig.contractSetFinishAt &&
      gig.contractSetFinishAt !== focusSet.finishAt
    ) {
      setTimesMatch = false;
    } else {
      setTimesMatch = true;
    }
  }

  let backgroundColor: TableRowBackgroundColor = undefined;
  if (gig.offDay || gig.doNotAdvance) backgroundColor = 'grey';
  else if (gig.cancelled) backgroundColor = 'red';
  else if (itinStatus === 'itin_sent') backgroundColor = 'green';

  let locationPrefix = null;
  if (gig.offDay) locationPrefix = 'Off-Day';
  else if (gig.cancelled)
    locationPrefix = `Cancelled (${gig.cancelledChargeAdvance ? 'C' : 'DC'})`;

  return (
    <StyledRow>
      <RowStandardLeft
        act={act}
        date={gig.date}
        gigLink={gigLink}
        statusText={
          gig.statusText ?? (gig.doNotAdvance ? 'DO NOT ADVANCE' : null)
        }
        backgroundColor={backgroundColor}
        itinStatus={itinStatus}
      />
      <TableCell width={220} backgroundColor={backgroundColor}>
        <span>
          {locationPrefix && <strong>{locationPrefix}: </strong>}
          {venue.name}
        </span>
        {venue.address && (
          <span className="detail">
            {venue.address.city}, {venue.address.country}
          </span>
        )}
      </TableCell>
      <TableCell noPadding width={160} backgroundColor={backgroundColor}>
        {gig.contractSetStartAt || gig.contractSetFinishAt ? (
          <TableCellButton
            onClick={() => setItemModal(['Gig', 'setTimes', gig.id])}
          >
            <StyledSetTimesWrap>
              {gig.contractSetStartAt} - {gig.contractSetFinishAt}
              {setTimesMatch === true && (
                <Icon name="ok" color="rgb(0, 217, 163)" size={18} />
              )}
              {setTimesMatch === false && (
                <Icon name="cancel-1" color="rgb(217, 21, 21)" size={24} />
              )}
            </StyledSetTimesWrap>
          </TableCellButton>
        ) : (
          <StyledSpacedAddPrompt
            onClick={() => setItemModal(['Gig', 'setTimes', gig.id])}
          />
        )}
      </TableCell>
      <TableCell gap="left" backgroundColor={backgroundColor}>
        <Deal.DealCell
          gig={gig}
          onClick={() => setItemModal(['Gig', 'deal', gig.id])}
        />
      </TableCell>
      <TableCell align="center" gap="left" backgroundColor={backgroundColor}>
        <Travel.TravelCell
          gig={gig}
          onClick={() => setItemModal(['Gig', 'travel', gig.id])}
        />
      </TableCell>
      <TableCell align="center" backgroundColor={backgroundColor}>
        <Hotel.HotelCell
          gig={gig}
          onClick={() => setItemModal(['Gig', 'hotel', gig.id])}
        />
      </TableCell>
      <TableCell align="center" backgroundColor={backgroundColor} gap="left">
        <NotesPrompt
          content={gig.visaNotes}
          completed={gig.visaCompleted}
          onClick={() => setItemModal(['Gig', 'visa', gig.id])}
          color={
            gig.visaNotRequired
              ? '#999'
              : gig.visaCompleted
              ? Color.green
              : Color.orange
          }
          strikethrough={gig.visaNotRequired ?? false}
        />
      </TableCell>
      <TableCell align="center" backgroundColor={backgroundColor}>
        <NotesPrompt
          content={gig.covidNotes}
          completed={gig.covidCompleted}
          onClick={() => setItemModal(['Gig', 'covid', gig.id])}
          color={gig.covidCompleted ? Color.green : Color.orange}
        />
      </TableCell>
      <TableCell align="center" backgroundColor={backgroundColor}>
        {sfxLinkList.length > 0 && (
          <NotesPrompt
            content={gig.sfxNotes}
            completed={gig.sfxCompleted}
            onClick={() => setItemModal(['Gig', 'sfx', gig.id])}
            color={gig.sfxCompleted ? Color.green : Color.orange}
          />
        )}
      </TableCell>
      <TableCell align="center" backgroundColor={backgroundColor}>
        <NotesPrompt
          content={gig.miscNotes}
          onClick={() => setItemModal(['Gig', 'misc', gig.id])}
          color={Color.blue}
          hoverAttachedSide="right"
        />
      </TableCell>
      <TravellingParty.TourManagerCell
        gig={gig}
        entities={entities}
        setItemModal={setItemModal}
        backgroundColor={backgroundColor}
      />
      <Advancing.AdvancingCell
        gig={gig}
        itinStatus={itinStatus}
        setItemModal={setItemModal}
        backgroundColor={backgroundColor}
      />
      <PaymentsCell gig={gig} backgroundColor={backgroundColor} />
      <StyledSlideOutCell action="go">
        <div className="spacer" />

        <a
          className="delete"
          onClick={() => setItemModal(['Gig', 'more', gig.id])}
        >
          <Ellipsis />
        </a>
        <Link className="go" to={gigLink}>
          <Icon name="left" size={17} />
        </Link>
      </StyledSlideOutCell>
    </StyledRow>
  );
});

const StyledSetTimesWrap = styled.div`
  display: flex;
  align-items: center;
  color: #444;

  i {
    margin-left: 2px;
  }
`;

const StyledSpacedAddPrompt = styled(AddPrompt)`
  margin-left: 15px;
`;

const StyledSlideOutCell = styled.td`
  position: relative;

  div.spacer {
    position: absolute;
    z-index: -2;
    width: 105px;
    top: 0;
    left: 0;
    height: 60px;
  }

  a {
    z-index: -1;
    position: absolute;
    top: 7px;
    left: -28px;
    height: 46px;
    width: 68px;
    border-radius: 23px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding-right: 10px;

    background-color: #4d3a7a;
    color: #fff;

    transform: translateX(-40px);
    opacity: 0.3;
    transition: all 0.25s;

    i {
      transform: rotate(180deg);
    }

    &.delete {
      background-color: #777;
      width: 46px;
      left: 47px;
      transform: translateX(-115px);
    }

    &:hover {
      opacity: 1;
      color: #fff;
    }
  }
`;

const StyledRow = styled.tr`
  &:hover {
    ${StyledSlideOutCell} a {
      transform: translateX(0);
    }
  }

  /* When they hover over one of the first few cells that are 
     <Link to={/gigs/{id}}>, light up the go arrow at the end. */
  td.to-link:hover ~ ${StyledSlideOutCell} a.go {
    opacity: 1;
  }
`;
