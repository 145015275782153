// @flow

import type { DayNote } from '../../../../models';

import * as React from 'react';
import { useForm } from 'react-typed-form';

import { useApiFormSubmit } from '../../../../api';
import { ModalMinimalSubmitButton } from '../../../../components';
import { FormWrap } from '../../../../form';
import schemas from '../../../../schemas';
import { FieldModalTextarea, GigModalWrap } from '../../components';

export type ModalField = 'flights' | 'visa' | 'hotel' | 'misc';

type Props = $ReadOnly<{|
  dayNote: DayNote,
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
|}>;

export default React.memo<Props>(function DayNoteEditModal({
  dayNote,
  onDismiss,
  upsertEntities,
}: Props) {
  const formSubmit = useApiFormSubmit('PUT', `/day_notes/${dayNote.id}`, {
    onSuccess: (responseData) => {
      upsertEntities(responseData, schemas.DayNote);
      onDismiss();
    },
  });
  // eslint-disable-next-line flowtype/no-weak-types
  const { formErrorList, getField, handleSubmit, isLoading } = useForm<any>({
    pristineValues: dayNote,
    onSubmit: formSubmit,
  });

  return (
    <GigModalWrap title="Day Note" gig={dayNote} onDismiss={onDismiss}>
      <FormWrap formErrorList={formErrorList} handleSubmit={handleSubmit}>
        <FieldModalTextarea field={getField('content')} />
        <ModalMinimalSubmitButton isLoading={isLoading} />
      </FormWrap>
    </GigModalWrap>
  );
});
