// @flow

import type { Entities } from '../types';
import type { ActHard } from './Act';

import {
  addDays,
  addMonths,
  endOfDay,
  format,
  getYear,
  isAfter,
  isBefore,
  startOfDay,
  subDays,
} from 'date-fns';

import { idToIri } from '../util';

export type Gig = {|
  '@id': string,
  '@type': 'Gig',
  id: number,
  date: string,
  act: string,
  venue: string,
  offDay: boolean | null,
  cancelled: boolean | null,
  cancelledChargeAdvance: boolean | null,
  verified: boolean,
  name: string | null,
  openTime: string | null,
  closeTime: string | null,
  pdfSecret: string,
  // New fields post 2019 migration
  statusText: string | null,
  dealTravel: boolean | null,
  dealHotel: boolean | null,
  dealGround: boolean | null,
  travelOptionsSentIn: boolean | null,
  travelOptionsSentOut: boolean | null,
  travelIssuedIn: boolean | null,
  travelIssuedOut: boolean | null,
  travelAddedToAppIn: boolean | null,
  travelAddedToAppOut: boolean | null,
  travelPromoterCost: boolean | null,
  inboundTravelNotRequired: boolean | null,
  outboundTravelNotRequired: boolean | null,
  hotelArtistPaying: boolean | null,
  hotelNtrpBooking: boolean | null,
  hotelBooked: boolean | null,
  hotelOptionsSent: boolean | null,
  hotelNotes: string | null,
  contractSetStartAt: string | null,
  contractSetFinishAt: string | null,
  visaNotes: string | null,
  visaNotRequired: boolean | null,
  visaCompleted: boolean,
  miscNotes: string | null,
  advancingContactList: string | null,
|};

export type V_Gig_Table = {|
  ...Gig,
  doNotAdvance: boolean | null,
  covidNotes: string | null,
  covidCompleted: boolean,
  techRiderNotes: string | null,
  techRiderCompleted: boolean,
  visualsNotes: string | null,
  visualsCompleted: boolean,
  sfxNotes: string | null,
  sfxCompleted: boolean,
|};

export type V_Gig_Deal = {|
  ...Gig,
  dealTravel: boolean | null,
  dealTravelType: string | null,
  dealTravelNotes: string | null,
  dealTravelNumSeatsEco: number | null,
  dealTravelNumSeatsPremium: number | null,
  dealTravelNumSeatsFirst: number | null,
  dealTravelNumSeatsBusiness: number | null,
  dealTravelNumSeatsOther: number | null,
  dealTravel: boolean | null,
  dealHotelStars: number | null,
  dealHotelNumNights: number | null,
  dealHotelNotes: string | null,
  dealHotelNumRoomsDouble: number | null,
  dealHotelNumRoomsTwin: number | null,
  dealHotelNumRoomsKing: number | null,
  dealHotelNumRoomsSuperior: number | null,
  dealHotelNumRoomsExecutive: number | null,
  dealHotelNumRoomsJuniorSuite: number | null,
  dealHotelNumRoomsSuite: number | null,
  dealHotelNumRoomsOther: number | null,
  dealGround: boolean | null,
  dealGroundAirportToHotel: boolean | null,
  dealGroundVenueToHotel: boolean | null,
  dealGroundNotes: string | null,
|};

export type V_Gig_Hotel = {|
  id: number,
  hotelArtistPaying: boolean | null,
  hotelNtrpBooking: boolean | null,
  hotelOptionsSent: boolean | null,
  hotelBooked: boolean | null,
  hotelAddedToApp: boolean | null,
  hotelNotes: string | null,
|};

export type V_Gig_Travel = {|
  id: number,
  travelOptionsSentIn: boolean | null,
  travelOptionsSentOut: boolean | null,
  travelIssuedIn: boolean | null,
  travelIssuedOut: boolean | null,
  travelAddedToAppIn: boolean | null,
  travelAddedToAppOut: boolean | null,
  travelPromoterCost: boolean | null,
  inboundTravelNotRequired: boolean | null,
  outboundTravelNotRequired: boolean | null,
  travelNotes: string | null,
|};

export function gigsFilterUrlParams(
  leadFilter: ?string,
  actFilter: ?string,
  periodFilter: string
): string {
  const parts = [];

  if (leadFilter && leadFilter !== 'all') {
    parts.push(`act.leads=${leadFilter}`);
  }

  if (actFilter && actFilter !== 'all') {
    parts.push(`act=${actFilter}`);
  }

  if (periodFilter === 'upcoming') {
    parts.push(`date[after]=${format(subDays(new Date(), 3), 'YYYY-MM-DD')}`);
  }

  if (periodFilter === 'upcoming-month') {
    parts.push(`date[after]=${format(subDays(new Date(), 3), 'YYYY-MM-DD')}`);
    parts.push(
      `date[before]=${format(addMonths(new Date(), 1), 'YYYY-MM-DD')}`
    );
  }

  if (periodFilter.match(/^\d{4}$/)) {
    parts.push(`date[after]=${periodFilter}-01-01`);
    parts.push(`date[before]=${periodFilter}-12-31`);
  }

  if (periodFilter.match(/^\d{4}-\d{2}$/)) {
    parts.push(`date[after]=${periodFilter}-01`);
    parts.push(`date[before]=${periodFilter}-31`);
  }

  // Advance weeks
  const matches = periodFilter.match(/^adv-week-(\d{4}-\d{2}-\d{2})$/);
  if (matches) {
    parts.push(`date[after]=${matches[1]}`);
    parts.push(`date[before]=${format(addDays(matches[1], 6), 'YYYY-MM-DD')}`);
  }

  return parts.join('&');
}

/**
 * Given a Gig (and its Act), should it be displayed given this set of filters?
 */
export function gigsFilterEntry(
  gig: Gig | V_Gig_Table,
  hardActs: Entities<ActHard>,
  leadFilter: ?string,
  actFilter: ?string,
  periodFilter: string
): boolean {
  const act = hardActs[gig.act];
  if (!act)
    throw new Error(`gigFilterEntry: Hard act not found with IRI: ${gig.act}`);

  if (leadFilter && leadFilter !== 'all') {
    if (!act.leads.includes(idToIri('account_users', leadFilter))) return false;
  }

  if (actFilter && actFilter !== 'all') {
    if (idToIri('acts', actFilter) !== gig.act) return false;
  }

  if (periodFilter === 'upcoming') {
    if (isBefore(gig.date, startOfDay(subDays(new Date(), 3)))) return false;
  }

  if (periodFilter === 'upcoming-month') {
    if (isBefore(gig.date, startOfDay(subDays(new Date(), 3)))) return false;
    if (isAfter(gig.date, addMonths(new Date(), 1))) return false;
  }

  if (periodFilter.match(/^\d{4}$/)) {
    if (getYear(gig.date) !== parseInt(periodFilter, 10)) return false;
  }

  if (periodFilter.match(/^\d{4}-\d{2}$/)) {
    if (format(gig.date, 'YYYY-MM') !== periodFilter) return false;
  }

  // Advance weeks
  const matches = periodFilter.match(/^adv-week-(\d{4}-\d{2}-\d{2})$/);
  if (matches) {
    if (isBefore(gig.date, startOfDay(matches[1]))) return false;
    if (isAfter(gig.date, endOfDay(addDays(matches[1], 6)))) return false;
  }

  return true;
}

/**
 * Implementation should match getDeserializedAdvancingContactList on server
 */
export function deserializeAdvancingContactList(
  input: string | null
): $ReadOnlyArray<$ReadOnly<{| name: string | null, email: string |}>> {
  if (!input) return [];
  return input
    .split('\n')
    .filter(Boolean)
    .map((s) => {
      const matches = s.trim().match(/(.+) <(.+)>/);
      if (matches) return { name: matches[1], email: matches[2] };
      return { name: null, email: s };
    });
}
