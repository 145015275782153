// @flow

import type { DayNote, Gig, V_Gig_Table } from './models';

import * as Sentry from '@sentry/browser';

import { APP_ENV } from './config';

// Like Object.values but type hinted usefully
export function values<T>(obj: $ReadOnly<{ [key: string]: T }>): T[] {
  return Object.keys(obj).map((key) => obj[key]);
}

export function camelToSnake(input: string): string {
  return input.replace(
    /[a-z][A-Z]/g,
    (match) => `${match[0]}_${match[1].toLowerCase()}`
  );
}

export function capitalize(name: string): string {
  return name.charAt(0).toUpperCase() + name.slice(1);
}

/**
 * Comparator input for a sort function.
 */
export function compareGigsOrDayNotes(
  a: Gig | V_Gig_Table | DayNote,
  b: Gig | V_Gig_Table | DayNote
): number {
  const aDate = a.date.substr(0, 10);
  const bDate = b.date.substr(0, 10);
  if (aDate !== bDate) return aDate.localeCompare(bDate);

  const getOrdinal = (item: Gig | V_Gig_Table | DayNote): number => {
    if (item['@type'] !== 'Gig' || !item.contractSetStartAt) return 0;

    const time = parseInt(item.contractSetStartAt.trim().replace(':', ''), 10);
    // Boost early morning sets so they appear after the late night sets
    return time < 1100 ? time + 10000 : time;
  };

  return getOrdinal(a) - getOrdinal(b);
}

export function idToIri(entityName: string, id: number | string): string {
  return `/api-platform/${entityName}/${id.toString()}`;
}

export function logError(
  err: {},
  type: string,
  options?: $ReadOnly<{|
    level?: 'fatal' | 'error' | 'warning' | 'info' | 'debug',
    extras?: {},
  |}>
) {
  const { level, extras } = options || {};
  if (APP_ENV === 'dev') {
    console.error(err); // eslint-disable-line no-console
    console.log('with options', type, options); // eslint-disable-line no-console
  } else {
    Sentry.withScope(function (scope) {
      scope.setExtra('type', type);
      level && scope.setLevel(level);
      extras &&
        Object.entries(extras).forEach(([key, val]) => {
          scope.setExtra(key, val);
        });
      Sentry.captureException(err);
    });
  }
}

export function snakeToCamel(input: string): string {
  return input.replace(
    /[a-z0-9]_[a-z0-9]/g,
    (match) => `${match[0]}${match[2].toUpperCase()}`
  );
}

export function transformKeys(
  input: {} | Array<mixed>,
  transform: (string) => string
): {} | Array<mixed> {
  if (Array.isArray(input)) return input;
  const result = {};
  Object.keys(input).forEach((key) => {
    result[transform(key)] = input[key];
  });
  return result;
}

export function transformKeysRecursive(
  input: {} | Array<mixed>,
  transform: (string) => string
): {} | Array<mixed> {
  const result = transformKeys(input, transform);
  const iterable = Array.isArray(result) ? result.keys() : Object.keys(result);
  for (const key of iterable) {
    const value = result[key];
    if (typeof value === 'object' && value != null) {
      result[key] = transformKeysRecursive(value, transform);
    }
  }
  return result;
}
