// @flow

import * as React from 'react';

import AddPrompt from './AddPrompt';
import CircleIcon from './CircleIcon';
import HoverPrompt from './HoverPrompt';
import NotesDropdownPreview from './NotesDropdownPreview';

type Props = $ReadOnly<{|
  onClick: () => mixed,
  content: string | null,
  completed?: boolean,
  color?: string,
  hoverAttachedSide?: 'left' | 'center' | 'right',
  strikethrough?: boolean,
|}>;

export default React.memo<Props>(function NotesPrompt({
  onClick,
  content,
  completed,
  color,
  hoverAttachedSide,
  strikethrough,
}: Props) {
  if (!content && !completed && !strikethrough) {
    return <AddPrompt onClick={onClick} />;
  }

  return (
    <HoverPrompt
      onClick={onClick}
      promptContent={
        <CircleIcon
          iconName="info"
          color={color ?? '#bbb'}
          strikethrough={strikethrough}
        />
      }
      dropdownContent={content && <NotesDropdownPreview content={content} />}
      hoverAttachedSide={hoverAttachedSide}
    />
  );
});
