App.Collections.FlightStats = Backbone.Collection.extend({
    model : App.Models.FlightStat,
    //url : flighturl,
    sync : function(method, collection, options) {
        options.dataType = "jsonp";
        return Backbone.sync(method, collection, options);
    },
    parse : function(response) {
        return response.flightStatus;
    }
});