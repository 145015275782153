App.Models.Journey = App.Models.ScheduleItem.extend({

    urlRoot: () => App.config.baseUrl + 'api-platform/journeys',

    iriFields: ['from_address', 'gig', 'to_address', 'trips'],

    defaults: {
        discr: 'CarJourney'
    },

    relations: [
        {
            type: Backbone.HasOne,
            key: 'from_address',
            relatedModel: 'App.Models.Address',
            includeInJSON: Backbone.Model.prototype.idAttribute
        },
        {
            type: Backbone.HasOne,
            key: 'to_address',
            relatedModel: 'App.Models.Address',
            includeInJSON: Backbone.Model.prototype.idAttribute
        }
    ],

    url: function() {
        var base =
            _.result(this, 'urlRoot') ||
            _.result(this.collection, 'url') ||
            urlError();
        base = base
            .replace('journey', this.get('discr'))
            .replace(/([a-z])([A-Z])/g, '$1_$2')
            .toLowerCase();
        if (this.isNew()) {
            return base;
        }
        var id = this.get(this.idAttribute);
        return base.replace(/[^\/]$/, '$&/') + encodeURIComponent(id);
    },

    validate: function (attrs, options) {
        var errors = {};

        if (!attrs.discr) {
            errors.discr = 'Please supply a journey type';
        }

        _.each(['from', 'to'], function(dir) {
            if (attrs[dir + '_type'] === 'other') {
                if (!attrs[dir + '_name']) {
                    errors[dir + '_name'] = 'Please supply a name';
                }

                if (!attrs[dir + '_address_short'] || !attrs[dir + '_lat']) {
                    errors[dir + '_map'] = 'Please start typing an address, and select a match from the list of suggestions';
                }
            }
        });
        if (!attrs.from) {
            // errors.from = 'Please supply a from location';
        }

        if (!attrs.to) {
            // errors.to = 'Please supply a to location';
        }

        if (!attrs.depart_at) {
            errors.depart_at = 'Please supply a departure date and time';
        }

        if (!attrs.arrive_at) {
            errors.arrive_at = 'Please supply a arrival date and time';
        }

        if (!_.isEmpty(errors)) {
            return errors;
        }
    }
});