// @flow

import type { GigTableEntities } from '../../../../hooks/entities-store';
import type { V_Gig_Table } from '../../../../models';

import * as React from 'react';
import { useForm } from 'react-typed-form';
import styled from 'styled-components';

import { useApiFormSubmit } from '../../../../api';
import { ModalMinimalSubmitButton } from '../../../../components';
import { FormWrap, createFormValidator } from '../../../../form';
import schemas from '../../../../schemas';
import { FieldModalTextarea, GigModalWrap } from '../../components';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  entities: GigTableEntities,
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
|}>;

export default React.memo<Props>(function ContactListModal({
  gig,
  entities,
  onDismiss,
  upsertEntities,
}: Props) {
  const formSubmit = useApiFormSubmit('PUT', `/gigs/${gig.id}`, {
    onSuccess: (responseData) => {
      upsertEntities(responseData, schemas.Gig);
      onDismiss();
    },
  });
  // eslint-disable-next-line flowtype/no-weak-types
  const { formErrorList, getField, handleSubmit, isLoading } = useForm<any>({
    pristineValues: gig,
    validate: createFormValidator({
      advancingContactList: {
        allLinesFormat: {
          pattern: /^([a-z][a-z -]+?)\s+<([a-z0-9_+.-]+@[a-z0-9.-]+)>\s*$/i,
          message: '^Please follow the requested format, with no empty lines',
        },
      },
    }),
    onSubmit: formSubmit,
  });

  return (
    <GigModalWrap
      title={`Advancing Contacts`}
      gig={gig}
      entities={entities}
      onDismiss={onDismiss}
    >
      <FormWrap formErrorList={formErrorList} handleSubmit={handleSubmit}>
        <FieldModalTextarea
          field={getField('advancingContactList')}
          hasContentBelow
        />
        <StyledBottomBar>
          One per line:
          <br />
          <br />
          Name &lt;email@example.com&gt;
        </StyledBottomBar>
        <ModalMinimalSubmitButton isLoading={isLoading} />
      </FormWrap>
    </GigModalWrap>
  );
});

const StyledBottomBar = styled.div`
  background-color: #f2f2f2;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px #ddd solid;
  padding: 8px 15px;
  color: #585858;
`;
