'use strict';

App.Views.Document = App.Views.BaseItem.extend({

    viewTemplate: '#template-document',
    editing: false,

    tagName: 'div',
    className: 'doc js-draggable-doc',

    events: {
        'click .js-delete-doc': 'onDeleteClicked'
    },

    initialize: function(options) {
        this.buildEvents();
        this.model.startTracking();
    },

    serializeData: function() {
        var data = this.model.toJSON();

        var folder = this.model.get('folder');
        data.folder = folder ? folder.toJSON() : null;

        var ext = this.model.getExtension();
        data.icon = 'doc-text';
        if (ext == 'pdf') { data.icon = 'file-pdf'; }
        if (_.contains(['doc', 'docx', 'rtf'], ext)) { data.icon = 'file-word'; }
        if (_.contains(['xls', 'xlsx', 'ods'], ext)) { data.icon = 'file-excel'; }
        if (_.contains(['ppt', 'pptx'], ext)) { data.icon = 'file-powerpoint'; }
        if (_.contains(['jpg', 'jpeg', 'png', 'gif', 'bmp'], ext)) { data.icon = 'file-image'; }
        if (_.contains(['zip', 'rar'], ext)) { data.icon = 'file-archive'; }
        if (_.contains(['mp3', 'wav'], ext)) { data.icon = 'file-audio'; }
        if (_.contains(['mp4', 'mpg', 'wmv', 'avi'], ext)) { data.icon = 'file-video'; }
        if (_.contains(['html', 'xml'], ext)) { data.icon = 'file-code'; }
        // todo: image icon

        return data;
    },

    onRender: function() {
        this.$el.data('id', this.model.get('id'));
    },

    onDeleteClicked: function() {
        if (confirm('Are you sure you want to delete the document "' + this.model.get('filename') + '"?')) {
            this.model.destroy();
            App.Helpers.Notice.show('Document deleted', 'info', 2000);
        }
    }

});