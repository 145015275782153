App.Views.ArtistSets = App.Views.BaseCollection.extend({

    viewTemplate: '#template-artist-sets',

    childView: App.Views.ArtistSet,
    childModel: App.Models.ArtistSet,
    childViewContainer: 'tfoot',

    initialize: function () {
        this.buildEvents();
    },

    attachHtml: function(childView) {
        this.$(this.childViewContainer).before( childView.render().el );
    }
});