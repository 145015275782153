// @flow

import * as React from 'react';
import styled from 'styled-components';

import { ZIndex } from '../constants';

type Props = $ReadOnly<{|
  onDismiss: () => mixed,
  wide?: boolean,
  children: React.Node,
|}>;

export default function Modal({ onDismiss, wide, children }: Props) {
  return (
    <StyledContainer>
      <StyledMask onClick={onDismiss} />
      <StyledContent wide={wide}>{children}</StyledContent>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZIndex.modal};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledMask = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledContent = styled.div`
  position: relative;
  width: ${(p) => (p.wide ? '640px' : '480px')};
`;
