// @flow

import type { Reducer } from 'redux';
import type { Action } from '../types';

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth, { type AuthState } from './auth';
import hardEntities, { type HardEntitiesState } from './hard-entities';
import tempOptions, { type TempOptionsState } from './temp-options';
import versions, { type VersionsState } from './versions';

export type RootState = $ReadOnly<{|
  auth: AuthState,
  hardEntities: HardEntitiesState,
  tempOptions: TempOptionsState,
  versions: VersionsState,
|}>;

const APP_ENV = 'dev';
const persistConfig = {
  debug: APP_ENV === 'dev',
  keyPrefix: `rooted-${APP_ENV}-`,
  storage,
};

const reducers: Reducer<RootState, Action> = combineReducers({
  // eslint-disable-next-line flowtype/no-weak-types
  auth: (persistReducer({ key: 'auth', ...persistConfig }, auth): any),
  hardEntities,
  tempOptions,
  versions: (persistReducer(
    { key: 'versions', ...persistConfig },
    versions
    // eslint-disable-next-line flowtype/no-weak-types
  ): any),
});

export default reducers;
