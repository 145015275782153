// @flow

import * as React from 'react';
import styled from 'styled-components';

import ErrorList from './ErrorList';

type Props = $ReadOnly<{|
  label?: string,
  multiline?: boolean,
  className?: string,
  hint?: React.Node,
  errors?: string[],

  // Pass through to input
  disabled?: boolean,
  onChange?: (SyntheticInputEvent<EventTarget>) => void,
  onClick?: (SyntheticInputEvent<EventTarget>) => void,
  value?: ?string,
|}>;

export default function TextInput({
  label,
  multiline,
  className,
  hint,
  errors,
  ...rest
}: Props) {
  return (
    <div className={className}>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput
        as={multiline ? 'textarea' : undefined}
        type="text"
        {...rest}
      />
      {hint && <StyledHint>{hint}</StyledHint>}
      <ErrorList errors={errors} />
    </div>
  );
}

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 3px;
  color: #585858;
`;

const StyledInput = styled.input`
  padding: 4px 8px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) inset;
  border: 1px #aaa solid;
`;

const StyledHint = styled.div`
  font-size: 14px;
  color: #777;
  margin: 8px 0 0;
`;
