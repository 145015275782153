// @flow

import type { V_Act_TableDetail } from '../../../../models';

import * as React from 'react';
import styled from 'styled-components';

import { Dimen } from '../../../../constants';
import Section from './Section';
import SectionGeneralForm from './SectionGeneralForm';

type Props = $ReadOnly<{|
  act: V_Act_TableDetail,
  invalidate: () => void,
|}>;

export default function SectionGeneral({ act, invalidate }: Props) {
  const [editing, setEditing] = React.useState<boolean>(false);

  return (
    <Section
      title="General"
      editing={editing}
      onEditPress={() => setEditing(true)}
    >
      {editing ? (
        <SectionGeneralForm
          existing={act}
          onSuccess={() => {
            setEditing(false);
            invalidate();
          }}
          onDismiss={() => setEditing(false)}
        />
      ) : (
        <StyledField>
          <StyledLabel>Account Number</StyledLabel>
          <div>{act.accountNumber || <StyledNotSet>Not set</StyledNotSet>}</div>
        </StyledField>
      )}
    </Section>
  );
}

const StyledField = styled.div`
  max-width: 340px;
  overflow: hidden;

  .faint {
    color: #777;
  }
`;

const StyledLabel = styled.div`
  color: #939393;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: ${Dimen.spacing * 0.25}px;
`;

const StyledNotSet = styled.span`
  color: #777;
`;
