// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Dimen } from '../constants';
import PlusGlyph from './PlusGlyph';
import Spacer from './Spacer';

type Props = $ReadOnly<{|
  title: string,
  subTitle?: string,
  onDismiss: () => mixed,
|}>;

export default function ModalMinimalHeader({
  title,
  subTitle,
  onDismiss,
}: Props) {
  return (
    <StyledContainer>
      <StyledMain>
        <h2>{title}</h2>
        <Spacer size={0.5} />
        {subTitle && <h3>{subTitle}</h3>}
      </StyledMain>
      <StyledDismiss onClick={onDismiss}>
        <PlusGlyph diagonal color="#fff" width={2} length={22} />
      </StyledDismiss>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  margin-bottom: ${Dimen.spacing}px;
`;

const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-left: 30px;

  h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    line-height: 1;
  }

  h3 {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    line-height: 1;
  }
`;

const StyledDismiss = styled.a`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.25s;

  &:hover {
    background-color: #fff6;
  }
`;
