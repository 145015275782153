// @flow

import type { GigsMinimalEntities } from '../../hooks/entities-store';
import type { Gig } from '../../models';
import type { RootState } from '../../types';

import { format, isToday } from 'date-fns';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { actAvatarUrl, venueImageUrl } from '../../image-urls';

type Props = $ReadOnly<{|
  gig: Gig,
  entities: GigsMinimalEntities,
|}>;

export default function GigBox({ gig, entities }: Props) {
  const act = useSelector<RootState, _>(
    (state) => state.hardEntities?.Act[gig.act]
  );

  const venue = entities.Venue[gig.venue];
  if (!venue) throw new Error(`Missing venue with IRI: ${gig.venue}`);

  if (!act || !venue) throw new Error('Missing entity');

  let nameLabel = gig.name || venue.name;
  if (gig.cancelled)
    nameLabel = `Cancelled (${
      gig.cancelledChargeAdvance ? 'C' : 'DC'
    }): ${nameLabel}`;
  if (gig.offDay) nameLabel = 'Off-Day';

  return (
    <StyledContainer
      to={`/gigs/${gig.id}`}
      className="gig-box"
      style={
        venue.imageFilename
          ? {
              backgroundImage: `url(${venueImageUrl(venue.imageFilename, {
                w: 368 * 2,
                h: 200 * 2,
              })})`,
            }
          : {}
      }
    >
      <div className="gig-box__mask"></div>
      <div className="gig-box__artist">
        <img
          src={
            act.avatarFilename
              ? actAvatarUrl(act.avatarFilename, 50 * 2)
              : undefined
          }
        />
        <span>{act.name}</span>
      </div>

      {gig.verified && (
        <div className="gig-box__verified verified">
          <i className="icon-ok"></i>
        </div>
      )}

      <div className="gig-box__bottom">
        <div className="gig-box__date">
          <div className="main-date">
            {isToday(gig.date) && (
              <span className="main-date__warn main-date__warn--today">
                Today
              </span>
            )}
            <span className="main-date__day">{format(gig.date, 'DD')}</span>
            <span className="main-date__month">{format(gig.date, 'MMM')}</span>
          </div>
        </div>
        <div className="gig-box__venue">
          <strong>{nameLabel}</strong>
          {venue.address && (
            <span>
              {venue.address.city}, {venue.address.country}
            </span>
          )}
        </div>
      </div>
    </StyledContainer>
  );
}

const StyledContainer = styled(Link)`
  display: block;
  color: #fff !important;
`;
