// @flow

import type { RootState } from '../types';

import * as Sentry from '@sentry/browser';
import * as React from 'react';
import { useSelector } from 'react-redux';

export function useSentryUserManager() {
  const { user } = useSelector<RootState, _>((state) => state.auth);
  React.useEffect(() => {
    let sentryUser = null;
    if (user?.type === 'Account') {
      sentryUser = { email: user.email };
    } else if (user?.type === 'Token') {
      sentryUser = { username: `token:${user.token}` };
    }

    Sentry.setUser(sentryUser);
  }, [user]);
}
