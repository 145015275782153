// @flow

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-typed-form';

import { SERVER_ROOT } from '../../config';
import SplashFieldText from './SplashFieldText';
import SplashWrap from './SplashWrap';

export default function LoginPage() {
  const dispatch = useDispatch();

  const { formErrorList, getField, handleSubmit } = useForm<{|
    username: string,
    password: string,
  |}>({
    defaultValues: { username: '', password: '' },
    onSubmit: async ({ username, password }, { addError, setLoading }) => {
      setLoading(true);

      const Authorization = `Basic ${btoa(`${username}:${password}`)}`;
      const response = await fetch(`${SERVER_ROOT}/auth/lookup`, {
        headers: { Authorization },
      });

      if (response.status === 401) {
        addError('_form', 'Email address or password was incorrect');
        setLoading(false);
        return;
      }

      if (response.status === 402) {
        addError('_form', 'Payment has not been provided for this account');
        setLoading(false);
        return;
      }

      const data = await response.json();
      console.log(data); // eslint-disable-line no-console
      const { apiKey, email, id, firstName, lastName, staff } = data;
      dispatch({
        type: 'LOGIN_ACCOUNT',
        payload: {
          type: 'Account',
          apiKey,
          email,
          id,
          firstName,
          lastName,
          staff,
        },
      });
      setLoading(false);
    },
  });

  return (
    <SplashWrap>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {formErrorList &&
          formErrorList.map((error) => (
            <div key={error} className="error">
              {error}
            </div>
          ))}

        <SplashFieldText
          field={{ ...getField('username'), label: 'E-mail' }}
          type="email"
          required
        />
        <SplashFieldText
          field={{ ...getField('password'), label: 'Password' }}
          type="password"
          required
        />

        <div className="splash__form-row">
          <button className="splash__button">Sign in</button>
        </div>
      </form>
    </SplashWrap>
  );
}
