App.Views.ArtistSet = App.Views.BaseItem.extend({

    tagName: 'tbody',
    editTemplate: '#template-artist-set',
    viewTemplate: '#template-artist-set',
    editing: false,

    initialize: function(options) {
        this.options = _.extend({}, this._options, {
            // defaults
        }, options);

        this.buildEvents();
        this.model.startTracking();
    },

    serializeData: function() {
        return {
            set: this.model.toJSON(),
            editing: this.editing
        };
    },

    onBeforeSave: function() {
        this.attachGig();
    },

    onSave: function() {
        // Sort order may have changed, re-render the collection
        this.model.trigger('sort-order-changed');
    }
});