// @flow

import type { GigTableEntities } from '../../hooks/entities-store';
import type { V_Gig_Table } from '../../models';

import { values } from '../../util';

const ITIN_STATUSES = {
  not_started: 'Not Started',
  advance_sent: 'Advance Sent',
  in_progress: 'In Progress',
  itin_ready: 'Itinerary Ready',
  itin_sent: 'Itinerary Sent',
};

export type ItinStatus = $Keys<typeof ITIN_STATUSES>;

export function itinStatusLabel(
  status: ItinStatus
): $Values<typeof ITIN_STATUSES> {
  return ITIN_STATUSES[status];
}

export function calculateItinStatus(
  gig: V_Gig_Table,
  entities: GigTableEntities
): ItinStatus {
  const advancingEmails = values(entities.AdvancingEmail)
    .filter((ae) => ae?.gig === gig['@id'])
    .filter(Boolean);

  let status = 'not_started';
  const templatesSent = advancingEmails.map((ae) => ae.templateName);
  if (templatesSent.includes('final_itin')) {
    status = 'itin_sent';
  } else if (gig.verified) {
    status = 'itin_ready';
  } else if (
    templatesSent.includes('advance') &&
    (gig.openTime || gig.closeTime)
  ) {
    status = 'in_progress';
  } else if (templatesSent.includes('advance')) {
    status = 'advance_sent';
  }

  return status;
}
