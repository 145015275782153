// @flow

import * as React from 'react';

export default function useDropdown() {
  const [open, setOpen] = React.useState(false);

  const triggerEl = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    function onClick(ev) {
      if (triggerEl.current !== ev.target) setOpen(false);
    }

    window.addEventListener('click', onClick);

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, []);

  function handleTriggerClick() {
    setOpen(!open);
  }

  return { open, handleTriggerClick, triggerEl };
}
