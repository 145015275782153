// @flow

import type { GigTableEntities } from '../../../hooks/entities-store';
import type { V_Gig_Table } from '../../../models';
import type { NotesModalField } from '../types';

import * as React from 'react';
import { useForm } from 'react-typed-form';
import styled from 'styled-components';

import { useApiFormSubmit } from '../../../api';
import { ModalMinimalSubmitButton } from '../../../components';
import { Dimen } from '../../../constants';
import { FieldSwitch, FormWrap } from '../../../form';
import schemas from '../../../schemas';
import { capitalize } from '../../../util';
import FieldModalTextarea from './FieldModalTextarea';
import GigModalWrap from './GigModalWrap';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  entities: GigTableEntities,
  fieldName: NotesModalField,
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
|}>;

function NotesModal({
  gig,
  entities,
  fieldName,
  onDismiss,
  upsertEntities,
}: Props) {
  const formSubmit = useApiFormSubmit('PUT', `/gigs/${gig.id}`, {
    onSuccess: (responseData) => {
      upsertEntities(responseData, schemas.Gig);
      onDismiss();
    },
  });
  // eslint-disable-next-line flowtype/no-weak-types
  const { formErrorList, getField, handleSubmit, isLoading } = useForm<any>({
    pristineValues: {
      ...gig,
      visaNotes:
        gig.visaNotes ||
        'Visa type: \nTurnaround time: \nPassport ending: \nTime line of actions: ',
    },
    onSubmit: formSubmit,
  });

  const hasCompletedField = ['visa', 'covid', 'sfx'].includes(fieldName);

  return (
    <GigModalWrap
      title={`${capitalize(
        fieldName.replace(/([a-z])([A-Z])/, '$1 $2')
      )} Notes`}
      gig={gig}
      entities={entities}
      onDismiss={onDismiss}
    >
      <FormWrap formErrorList={formErrorList} handleSubmit={handleSubmit}>
        <FieldModalTextarea
          field={getField(`${fieldName}Notes`)}
          hasContentBelow={hasCompletedField}
        />
        {hasCompletedField && (
          <StyledBottom>
            <StyledBottomCol>
              <FieldSwitch
                field={{
                  ...getField(`${fieldName}Completed`),
                  label: 'Complete',
                }}
                colorOff={
                  getField(`${fieldName}Notes`).value ? '#FF7F00' : undefined
                }
                colorOn="#00B285"
              />
            </StyledBottomCol>
            {fieldName === 'visa' && (
              <StyledBottomCol>
                <FieldSwitch
                  field={{
                    ...getField('visaNotRequired'),
                    label: 'Not Required',
                  }}
                />
              </StyledBottomCol>
            )}
          </StyledBottom>
        )}
        <ModalMinimalSubmitButton isLoading={isLoading} />
      </FormWrap>
    </GigModalWrap>
  );
}

export default React.memo<Props>(NotesModal);

const StyledBottom = styled.div`
  background-color: #eee;
  display: flex;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px #ddd solid;
`;

const StyledBottomCol = styled.div`
  flex: 1;
  padding: ${Dimen.spacing / 2}px ${Dimen.spacing}px;

  &:not(:last-child) {
    border-right: 1px #ccc solid;
  }
`;
