// @flow

import * as React from 'react';

import { Icon } from '../../../../components';

type Props = $ReadOnly<{|
  type: 'travel' | 'hotel' | 'ground',
  enabled: boolean | null,
  size: number,
|}>;

const SYMBOLS = {
  travel: 'flight',
  hotel: 'bed',
  ground: 'cab',
};

const color = (v) => {
  if (v === true) return '#777';
  if (v === false) return '#ccc';
  return '#aaa';
};

export default function DealIcon({ type, enabled, size }: Props) {
  return <Icon name={SYMBOLS[type]} color={color(enabled)} size={size} />;
}
