// @flow

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import { SoloMessage, Spinner } from '../components';
import { SERVER_ROOT } from '../config';
import * as Static from '../pods/static';

export default function AppAnon() {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const [checkingToken, setCheckingToken] = React.useState(false);

  /**
   * See if we can try and login a TokenUser from the query string
   */
  const [error, setError] = React.useState<'forbidden' | null>(null);
  React.useEffect(() => {
    async function checkPromoterLogin() {
      const matches = search?.match(/.+token=([A-Za-z0-9]+)/);
      if (!matches) return;

      setCheckingToken(true);

      const Authorization = `Basic ${btoa(`${matches[1]}:`)}`;
      const response = await fetch(`${SERVER_ROOT}/auth/token-lookup`, {
        headers: { Authorization },
      });

      if (response.status === 401) {
        setError('forbidden');
        return;
      }

      const data = await response.json();
      console.log(data); // eslint-disable-line no-console
      const { token, id, gig } = data;
      if (!token || !id || !gig)
        throw new Error('Bad token response' + JSON.stringify(data));
      dispatch({
        type: 'LOGIN_TOKEN',
        payload: { type: 'Token', token, id, gig },
      });
    }
    checkPromoterLogin();
  }, [search, dispatch]);

  if (error === 'forbidden') {
    return (
      <SoloMessage
        title="Invalid Link"
        description="Your link was not valid, it may have expired.  Please double check the link, or request to be sent a new one."
      />
    );
  }

  if (checkingToken) {
    return <Spinner message="Logging you in" />;
  }

  return (
    <Switch>
      <Route path="/apps" exact component={Static.AppsPage} />
      <Route path="/login" exact component={Static.LoginPage} />
      <Route path="/privacy" exact component={Static.PrivacyPage} />
      <Route component={Static.LandingPage} />
    </Switch>
  );
}
