// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  children: React.Node,
  to?: string,
  onClick?: () => mixed,
  onMouseOver?: () => mixed,
  onMouseOut?: () => mixed,
|}>;

export default function TableCellButton({ children, ...rest }: Props) {
  return (
    <StyledA as={rest.to ? Link : 'a'} {...rest}>
      {children}
    </StyledA>
  );
}

const StyledA = styled.a`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 5px;
  padding: 0 10px;
  border-radius: 5px;
  color: inherit;
  transition: background-color 0.25s;

  &:hover {
    background-color: #efecf3;
    color: inherit;
  }
`;
