// @flow

import type { TypedFieldProp } from 'react-typed-form';

import * as React from 'react';
import styled from 'styled-components';

import { Color } from '../../../constants';
import { FieldCheckbox } from '../../../form';

type Props = $ReadOnly<{|
  rowTitle?: string,
  steps: $ReadOnlyArray<TypedFieldProp<boolean>>,
|}>;

function Ellipsis() {
  return (
    <StyledEllipsis>
      <div />
      <div />
      <div />
    </StyledEllipsis>
  );
}

export default function WorkflowCheckboxes({ rowTitle, steps }: Props) {
  let nextAvailableMarked = false;
  return (
    <StyledRow>
      {rowTitle && (
        <StyledCell>
          <StyledTitle>{rowTitle}</StyledTitle>
        </StyledCell>
      )}
      {steps.map((step, i) => {
        let mark = false;
        if (!nextAvailableMarked && !step.value) {
          mark = true;
          nextAvailableMarked = true;
        }
        return (
          <>
            <StyledCell>
              <FieldCheckbox
                field={step}
                label={null}
                colorOff={mark ? Color.orange : undefined}
              />
            </StyledCell>
            {i < steps.length - 1 && (
              <StyledCell>
                <Ellipsis />
              </StyledCell>
            )}
          </>
        );
      })}
    </StyledRow>
  );
}

const StyledEllipsis = styled.div`
  display: flex;
  align-items: center;

  div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCell = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.span`
  font-weight: 600;
  text-transform: uppercase;
`;
