App.Collections.Contacts = Backbone.Collection.extend({
    model: App.Models.Contact,
    url: () => App.config.baseUrl + 'api-platform/contacts',

    comparator: function(c) {
        var cc = c.get('name').toLowerCase().charCodeAt(0),
            type = c.get('type').toLowerCase(),
            typeWeight = 5;
        if (type == 'promoter') { typeWeight = 1; }
        else if (type == 'emergency') { typeWeight = 2; }

        return typeWeight * 1000 + cc;
    }
});