// @flow

import { schema } from 'normalizr';

import { values } from './util';

const TYPES_TO_IRI_PARTS = {
  AccountUser: 'account_users',
  AdvancingEmail: 'advancing_emails',
  DayNote: 'day_notes',
  Gig: 'gigs',
  PartyUser: 'party_users',
};

export function iriPartToType(iriPart: string): string {
  for (const [key, value] of Object.entries(TYPES_TO_IRI_PARTS)) {
    if (value === iriPart) return key;
  }
  throw new Error(
    `Bad iri part: ${iriPart} - should be one of: ${values(
      TYPES_TO_IRI_PARTS
    ).join(', ')}`
  );
}

function attachStrategy(entityType: $Keys<typeof TYPES_TO_IRI_PARTS>) {
  return function generatedStrategy(value, parent) {
    if (
      !value.gig &&
      parent &&
      parent['@id'] &&
      parent['@id'].includes(`/${TYPES_TO_IRI_PARTS[entityType]}/`)
    ) {
      return { ...value, gig: parent['@id'] };
    }
    return value;
  };
}

const schemaDefaults = { idAttribute: '@id' };

/**
 * These names should match with the @type (and @context part) returned by
 * API Platform.
 */
const schemas = {
  AccountUser: new schema.Entity('AccountUser', {}, schemaDefaults),
  Act: new schema.Entity('Act', {}, schemaDefaults),
  AdvancingEmail: new schema.Entity(
    'AdvancingEmail',
    {},
    { ...schemaDefaults, processStrategy: attachStrategy('Gig') }
  ),
  ArtistSet: new schema.Entity(
    'ArtistSet',
    {},
    { ...schemaDefaults, processStrategy: attachStrategy('Gig') }
  ),
  DayNote: new schema.Entity('DayNote', {}, schemaDefaults),
  Gig: new schema.Entity('Gig', {}, schemaDefaults),
  PartyUser: new schema.Entity(
    'PartyUser',
    {},
    { ...schemaDefaults, processStrategy: attachStrategy('Gig') }
  ),
  Venue: new schema.Entity(
    'Venue',
    {},
    { ...schemaDefaults, processStrategy: attachStrategy('Gig') }
  ),
};

schemas.Act.define({
  leads: [schemas.AccountUser],
});

schemas.DayNote.define({
  venue: schemas.Venue,
});

schemas.Gig.define({
  act: schemas.Act,
  advancingEmails: [schemas.AdvancingEmail],
  artistSets: [schemas.ArtistSet],
  partyUsers: [schemas.PartyUser],
  venue: schemas.Venue,
});

export default schemas;
