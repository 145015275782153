'use strict';

App.Collections.PartyUsers = Backbone.Collection.extend({

    model: App.Models.PartyUser,

    comparator: function(u) {
        return u.get('account_user').get('first_name').toLowerCase();
    }

});