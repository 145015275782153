// @flow

import type { TypedFormProp } from 'react-typed-form';

import * as React from 'react';

import { SERVER_ROOT } from './config';
import { useMixedUser } from './hooks';
import { logError, snakeToCamel, transformKeysRecursive } from './util';

type ApiOptions = $ReadOnly<{|
  path: string,
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
  // eslint-disable-next-line flowtype/no-weak-types
  jsonBody?: any,
|}>;

// Loosely based on axios schema
type ApiResponse<T> = $ReadOnly<{
  status: number,
  headers: {},
  data: T,
}>;

type ApiErrorTextResponse = $ReadOnly<{|
  status: number,
  headers: {},
  text: string,
|}>;

class ApiError extends Error {
  // eslint-disable-next-line flowtype/no-weak-types
  response: ApiResponse<any> | ApiErrorTextResponse;
  constructor(
    message: string,
    // eslint-disable-next-line flowtype/no-weak-types
    response: ApiResponse<any> | ApiErrorTextResponse
  ) {
    super(message);
    this.response = response;
  }
}

export function useApiCallable() {
  const user = useMixedUser();
  const authUsername = user.type === 'Account' ? user.apiKey : user.token;

  const abortControllerRef = React.useRef<AbortController | null>(null);

  const callApi = React.useCallback(
    async function callApiFn<T>(options: ApiOptions): Promise<ApiResponse<T>> {
      const { path, method = 'GET', jsonBody } = options;

      const controller = new AbortController();
      abortControllerRef.current = controller;

      const response = await fetch(`${SERVER_ROOT}/api-platform${path}`, {
        method,
        headers: {
          Accept: 'application/ld+json',
          Authorization: `Basic ${window.btoa(authUsername)}`,
          'Cache-Control': 'no-cache',
          ...(jsonBody ? { 'Content-Type': 'application/json' } : { ...null }),
        },
        body: jsonBody ? JSON.stringify(jsonBody) : undefined,
        signal: controller.signal,
      });

      abortControllerRef.current = null;

      if (response.status === 401) {
        // eslint-disable-next-line no-console
        console.error('401, you should log out');
      }

      const contentTypes = response.headers.get('content-type') || '';
      if (
        !contentTypes.includes('application/json') &&
        !contentTypes.includes('application/ld+json') &&
        response.status !== 204 // empty response might have misleading content type
      ) {
        const text = await response.text();
        throw new ApiError(
          `${method} ${path} failed (${response.status}); no json`,
          {
            status: response.status,
            headers: response.headers,
            text,
          }
        );
      }

      // eslint-disable-next-line flowtype/no-weak-types
      const json = response.status === 204 ? ({}: any) : await response.json();

      const apiResponse: ApiResponse<T> = {
        data: json['hydra:member'] != null ? json['hydra:member'] : json,
        status: response.status,
        headers: response.headers,
      };

      if (!response.ok) {
        throw new ApiError(
          `${method} ${path} failed (${response.status})`,
          apiResponse
        );
      }

      return apiResponse;
    },
    [authUsername]
  );

  const abortIfInFlight = React.useCallback(() => {
    abortControllerRef.current?.abort();
  }, []);

  return { callApi, abortIfInFlight };
}

export function useApiRead<D>({ path, method }: ApiOptions): $ReadOnly<{|
  data: D | void,
  error: Error | void,
  invalidate: () => void,
|}> {
  const { callApi } = useApiCallable();

  const [error, setError] = React.useState(undefined);
  const [data, setData] = React.useState(undefined);
  // Allow invalidation
  const [invalidateToken, setInvalidateToken] = React.useState(0);

  React.useEffect(() => {
    setError(undefined);
    setData(undefined);
    callApi({ path, method })
      // $FlowFixMe
      .then((r) => setData(transformKeysRecursive(r.data, snakeToCamel)))
      .catch((err) => {
        logError(err, 'useApiRead');
        setError(err);
      });
  }, [invalidateToken, callApi, path, method]);

  const invalidate = () => setInvalidateToken(Math.random());

  return { error, data, invalidate };
}

export function useApiFormSubmit<T>(
  method: 'PUT' | 'POST',
  path: string,
  extras?: {
    onSuccess?: (responseData: T) => mixed,
  } = {}
): (values: T, form: TypedFormProp<T>) => void | Promise<void> {
  const { callApi } = useApiCallable();
  const { onSuccess } = extras;

  const callback = React.useCallback(
    async function (values: T, { addError, setLoading }: TypedFormProp<T>) {
      setLoading(true);
      try {
        const response = await callApi<T>({ path, method, jsonBody: values });
        setLoading(false);
        onSuccess && onSuccess(response.data);
      } catch (err) {
        const errData = (err.response && err.response.data) || {};

        if (errData['@type'] === 'ConstraintViolationList') {
          errData.violations.forEach((violation) => {
            if (!addError) {
              throw new Error(
                'handleError: Got a ConstraintViolationList but no addError option'
              );
            }
            // $FlowFixMe
            addError(snakeToCamel(violation.propertyPath), violation.message);
          });
        } else {
          alert('Unhandled error');
          throw err;
        }

        setLoading(false);
      }
    },
    [callApi, method, path, onSuccess]
  );

  return callback;
}
