App.Views.Trips = App.Views.BaseCollection.extend({

    viewTemplate: '#template-trips',

    childView: App.Views.Trip,
    childModel: App.Models.Trip,
    childViewContainer: 'table',

    childViewOptions: function() {
        return {
            scheduleItemView: this.options.scheduleItemView
        };
    },

    initialize: function (options) {
        this.options = options;
        this.buildEvents();
    },

    attachHtml: function(childView) {
        this.$(this.childViewContainer).append( childView.render().el );
    }

});