// @flow

import type { GigsMinimalEntities } from '../../hooks/entities-store';

import * as React from 'react';
import { Helmet } from 'react-helmet';

import { GigsSubHeader, PageLoading } from '../../components';
import PageWrap from '../../components/PageWrap';
import { useEntitiesStore, useLastGigsPath } from '../../hooks';
import { gigsFilterUrlParams } from '../../models/Gig';
import schemas from '../../schemas';
import { compareGigsOrDayNotes, values } from '../../util';
import GigBox from './GigBox';

type Props = $ReadOnly<{|
  match: {|
    params: {| leadFilter: string, actFilter: string, periodFilter: string |},
  |},
|}>;

export default function GigsListPage({ match }: Props) {
  const { leadFilter, actFilter, periodFilter } = match.params;
  useLastGigsPath();

  const { entities, isLoading } = useEntitiesStore<GigsMinimalEntities>(
    `/gigs/minimal?${gigsFilterUrlParams(leadFilter, actFilter, periodFilter)}`,
    [schemas.Gig]
  );

  const gigs = entities
    ? values(entities.Gig).filter(Boolean).sort(compareGigsOrDayNotes)
    : null;

  return (
    <PageWrap
      subHeader={
        <GigsSubHeader
          viewMode="grid"
          leadFilter={leadFilter}
          actFilter={actFilter}
          periodFilter={periodFilter}
        />
      }
    >
      <Helmet>
        <title>Gigs</title>
      </Helmet>
      {!gigs || !entities ? (
        <PageLoading />
      ) : (
        <div className="hcont hcont--padded" style={{ paddingTop: 1 }}>
          <div className="gig-boxes">
            {isLoading && <PageLoading />}

            {gigs.length === 0 ? (
              <div className="no-results">
                There are no gigs to display. If you have filters applied try
                making them less specific
              </div>
            ) : (
              <div>
                {gigs.map((gig) => (
                  <GigBox key={gig.id} gig={gig} entities={entities} />
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </PageWrap>
  );
}
