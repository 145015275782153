// @flow

import type { TableRowBackgroundColor } from '../types';

import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  to?: string,
  gap?: 'left' | 'right',
  colSpan?: number,
  first?: boolean,
  last?: boolean,
  align?: 'flex-start' | 'center' | 'flex-end',
  backgroundColor?: TableRowBackgroundColor,
  noPadding?: boolean,
  className?: string,
  width?: number,
  children?: React.Node,
|}>;

function TableCell({ to, colSpan, children, ...rest }: Props) {
  return (
    <td className={to ? 'to-link' : undefined} colSpan={colSpan}>
      <StyledInner to={to} {...rest}>
        {children}
      </StyledInner>
    </td>
  );
}

export default React.memo<Props>(TableCell);

function backgroundColorValue(color: string): string {
  return (
    {
      grey: '#e6e6e6',
      red: '#fdd',
      green: '#eaffea',
    }[color] || '#fff'
  );
}

const StyledInner = styled(({ to, className, children }) => {
  const commonProps = { className, children };
  return to ? <Link to={to} {...commonProps} /> : <div {...commonProps} />;
})`
  box-sizing: border-box;
  height: 60px;
  min-width: 60px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: ${(p) => p.align};
  background-color: ${(p) => backgroundColorValue(p.backgroundColor)};
  color: #444;
  font-size: 16px;
  line-height: 1.3;
  white-space: nowrap;
  ${(p) => p.width && `width: ${p.width}px`};

  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .detail {
    font-size: 13px;
  }

  ${(p) => !p.noPadding && `padding: 0 15px`};
  ${(p) => p.gap && `margin-${p.gap}: 3px`};
  ${(p) =>
    p.first &&
    `
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  `};
  ${(p) =>
    p.last &&
    `
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  `};

  strong {
    font-weight: 500;
  }

  &:hover {
    color: #444;
  }
`;
