// @flow

// import type { Store } from './types';
// eslint-disable-next-line flowtype/no-weak-types
type Store = any;

import { compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';

import reducers from './reducers';

// Current schema versions, bump to wipe the persisted store.
// See "versions" reducer for more info.
const AUTH_VERSION = 0;
const DATA_VERSION = 0;

type MakeResult = $ReadOnly<{|
  store: Store,
  persistor: { purge: () => Promise<void>, flush: () => Promise<void> },
|}>;

function makePersistedStore(onComplete: ?() => void): MakeResult {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store: Store = createStore(reducers, undefined, composeEnhancers());
  const persistor = persistStore(store, {}, () => {
    onComplete && onComplete();
  });
  return { persistor, store };
}

async function makePersistedStoreAsync(): Promise<MakeResult> {
  return new Promise((resolve) => {
    let makeResult; // eslint-disable-line prefer-const
    const callback = () => {
      resolve(makeResult);
    };
    makeResult = makePersistedStore(callback);
  });
}

export async function configureStoreAsync() {
  let {
    store,
    persistor, // eslint-disable-line prefer-const
  } = await makePersistedStoreAsync();

  const versionsState = store.getState().versions;
  const authVersion = versionsState && versionsState.auth;
  const dataVersion = versionsState && versionsState.data;

  if (authVersion !== AUTH_VERSION) {
    // eslint-disable-next-line no-console
    console.log(
      `Auth version: State[${
        authVersion || ''
      }] Code[${AUTH_VERSION}], purging store`
    );
    await persistor.flush(); // Flush all pending writes
    await persistor.purge(); // Clear the LocalStorage
    // Fetch a completely new store, which will have nothing to rehydrate from
    // so revert to its initial state
    const makeResult = await makePersistedStoreAsync();
    store = makeResult.store;
  } else if (dataVersion !== DATA_VERSION) {
    // eslint-disable-next-line no-console
    console.log(
      `Data version: State[${
        dataVersion || ''
      }] Code[${DATA_VERSION}], re-logging in`
    );
    const userState = store.getState().auth.user;
    // Only need to do anything if user is logged in
    if (userState) {
      store.dispatch({ type: 'UNAUTH_USER' });
      store.dispatch({
        type: 'AUTH_USER',
        payload: {
          id: userState.id,
          email: userState.email,
          jwt: userState.jwt,
        },
      });
    }
  }

  // Make sure the versions are up to date (could be a redundant call)
  store.dispatch({
    type: 'UPDATE_VERSIONS',
    payload: { auth: AUTH_VERSION, data: DATA_VERSION },
  });

  return store;
}
