// @flow

import * as React from 'react';
import styled from 'styled-components';

import { ZIndex } from '../constants';

export default function UpdateChecker() {
  const interval = React.useRef<IntervalID | null>(null);
  const [outdated, setOutdated] = React.useState(false);

  React.useEffect(() => {
    // $FlowFixMe
    const currentScript = new URL(document.querySelector('body script').src)
      .pathname;

    async function checkLatestScript() {
      const response = await fetch(window.location.origin);
      const text = await response.text();
      const matches = text.match(/(\/main\.bundle\.[a-f0-9]+\.js)/);
      if (!matches)
        throw new Error('Could not find main.bundle in response ' + text);
      const latestScript = matches[1];

      if (currentScript !== latestScript) {
        // eslint-disable-next-line no-console
        console.log(
          `New version available; old ${currentScript}, new ${latestScript}`
        );
        setOutdated(true);
      }
    }

    // Check for updates every 5 minutes
    interval.current = setInterval(() => {
      checkLatestScript();
    }, 60 * 5 * 1000);

    return () => {
      interval.current && clearInterval(interval.current);
      interval.current = null;
    };
  }, []);

  if (!outdated) return null;

  return (
    <StyledUpdateAvailable>
      A new version of the app is available. Please{' '}
      <a onClick={() => location.reload(true)}>refresh</a> to get it.
    </StyledUpdateAvailable>
  );
}

const StyledUpdateAvailable = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 230px;
  background-color: #503f74;
  border: 1px #888 solid;
  box-shadow: 0 0 8px #0004;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  color: #fffd;
  z-index: ${ZIndex.updateNotification};

  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: #fff9;
    transition: all 0.25s;
    cursor: pointer;

    &:hover {
      color: #fff;
      text-decoration-color: #fff;
    }
  }
`;
