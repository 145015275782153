App.Mixins.Typeahead = {

    _taModelType: null,

    initTypeahead: function(endpoint, modelType, options) {
        options = options || {};
        options = _.extend({
            allowAdd: false
        }, options);

        this._taModelType = modelType;

        var bloodhound = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            limit: 10,
            prefetch: {
                url: App.config.baseUrl + 'api-platform/' + endpoint + '/typeahead',
                cache: false,
                prepare: function(settings) {
                    settings.headers = Backbone.BasicAuth.getMyHeader();
                    return settings;
                }
            }
        });

        // Clear the cache (remove this when things are more stable)
        bloodhound.clear();
        bloodhound.clearPrefetchCache();

        bloodhound.initialize();

        var that = this;
        this.$('.js-typeahead').typeahead(null, {
            name: 'my-typeahead',
            displayKey: 'name',
            source: bloodhound.ttAdapter(),
            templates: {
                empty: [
                    '<div class="empty-message">',
                    'No matching ' + endpoint.substr(0, 5) + ' found.',
                    options.allowAdd ? 'Click "Add" to create a new one.' : '',
                    '</div>'
                ].join('\n'),
                suggestion: _.compile('<p><%- name %> <span><%- id %></span></p>')
            }
        }).on('typeahead:selected', function($e, datum){
            that.$('.js-typeahead-id').val( datum['id'] );
            that.triggerSave();
        }).on('keyup', function() {
            var inputName = that.$('.tt-input').val();
            var numSuggestions = that.$('.tt-suggestion').length;

            if (inputName && numSuggestions == 0) {
                that.$('.js-typeahead-id').val('');
                that.$('.js-typeahead-add').removeAttr('disabled');
            } else {
                that.$(".js-typeahead-add").attr('disabled', 'disabled');
            }
        });
    },

    onChangeTypeahead: function() {
        this.$('.js-typeahead-row').toggle();
    },

    onSaveNewTypeahead: function() {
        if(this.$('.js-typeahead-add').attr('disabled') != 'disabled') {
            //Get the venue id and check to see if its the same
            //If there is no venue id it means its a new venue
            var selectionId = this.$(".js-typeahead-id").val();

            if(!selectionId) {
                // Create a new venue
                var model = new this._taModelType({
                    name: this.$('.tt-input').val(),
                    gig: App.Helpers.getGigId()
                });
                model.save().then($.proxy(function() {
                    this.$(".js-typeahead-id").val( model.get('id') );
                    this.triggerSave();
                }, this));

                // Add the disabled class back onto the button
                this.$(".js-typeahead-add").attr('disabled', 'disabled');
            }
        }
    }

};