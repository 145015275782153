// @flow

import { addWeeks, format, getYear, isThursday, subDays } from 'date-fns';
import * as React from 'react';

import { useHardEntities } from '../hooks';
import { capitalize, idToIri, values } from '../util';
import DropdownSelectItem from './DropdownSelectItem';
import SubHeader from './SubHeader';
import SubHeaderSelect from './SubHeaderSelect';
import SubHeaderSpacer from './SubHeaderSpacer';
import SubHeaderTab from './SubHeaderTab';

type ViewMode = 'grid' | 'table' | 'calendar';

type Props = $ReadOnly<{|
  viewMode: ViewMode,
  leadFilter: string,
  actFilter: string,
  // Calendar doesn't use this same type of period filtering
  periodFilter?: string,
|}>;

export default function GigsSubHeader({
  viewMode,
  leadFilter,
  actFilter,
  periodFilter,
}: Props) {
  const acts = useHardEntities().Act;
  const leads = useHardEntities().AccountUser;

  let selectedLead = null;
  let selectedLeadLabel = 'All';
  if (leadFilter !== 'all') {
    selectedLead = leads[idToIri('account_users', leadFilter)];
    if (!selectedLead) throw new Error('Lead not found');
    selectedLeadLabel = selectedLead.firstName;
  }

  let selectedArtistLabel = 'All';
  if (actFilter !== 'all') {
    const selectedAct = acts[idToIri('acts', actFilter)];
    if (!selectedAct) throw new Error('Act not found');
    selectedArtistLabel = selectedAct.name;
  }

  /**
   * Get the label from an Advance Week period filter
   * (starting `adv-week-`)
   */
  function labelFromWeek(input: string): string {
    return `Adv. week: ${format(input.substr(9), 'D MMM')}`;
  }

  let periodLabel = periodFilter && capitalize(periodFilter);
  if (periodFilter === 'upcoming-month') periodLabel = 'Upcoming (month)';
  if (periodFilter && periodFilter.substr(0, 9) === 'adv-week-')
    periodLabel = labelFromWeek(periodFilter);

  // Create some options for the dropdowns
  const periodYears = [];
  for (let i = getYear(new Date()); i >= 2017; i--) {
    periodYears.push(i.toString());
  }

  // The team want to see the upcoming 3 "Advance Weeks", which run from
  // Thursday - Wednesday
  const periodAdvanceWeeks = [];
  let current = new Date();
  while (!isThursday(current)) {
    current = subDays(current, 1);
  }
  for (let i = 0; i < 4; i++) {
    periodAdvanceWeeks.push(`adv-week-${format(current, 'YYYY-MM-DD')}`);
    current = addWeeks(current, 1);
  }

  // Uses current values as default if something isn't provided
  function filterUrl({
    view = viewMode,
    lead = leadFilter,
    act = actFilter,
    period,
  }: {|
    view?: ViewMode,
    lead?: string | number,
    act?: string | number,
    period?: string,
  |}): string {
    const periodPart =
      period ||
      (view === 'calendar'
        ? format(new Date(), 'YYYY-MM')
        : periodFilter ||
          (leadFilter === 'all' ? 'upcoming-month' : 'upcoming'));
    return `/gigs/${view}/lead-${lead}/act-${act}/${periodPart}`;
  }

  function ensureUpcomingIsMonth(forceMonth: boolean): string {
    if (periodFilter === 'upcoming' && forceMonth) return 'upcoming-month';
    if (periodFilter === 'upcoming-month' && !forceMonth) return 'upcoming';
    return (
      periodFilter || (actFilter === 'all' ? 'upcoming-month' : 'upcoming')
    );
  }

  return (
    <SubHeader>
      <SubHeaderSelect label="Lead" selectedLabel={selectedLeadLabel}>
        <DropdownSelectItem
          label="All Leads"
          to={filterUrl({
            lead: 'all',
            act: 'all',
            period: ensureUpcomingIsMonth(true),
          })}
          selected={leadFilter === 'all'}
        />
        {values(leads)
          .filter(Boolean)
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map((lead) => (
            <DropdownSelectItem
              key={lead.id}
              label={lead.firstName}
              to={filterUrl({
                lead: lead.id,
                act: 'all',
                period: ensureUpcomingIsMonth(false),
              })}
              selected={leadFilter === lead.id.toString()}
            />
          ))}
      </SubHeaderSelect>
      <SubHeaderSelect label="Artists" selectedLabel={selectedArtistLabel}>
        <DropdownSelectItem
          label="All Artists"
          to={filterUrl({ act: 'all', period: ensureUpcomingIsMonth(true) })}
          selected={actFilter === 'all'}
        />
        {values(acts)
          .filter(Boolean)
          .filter((act) =>
            selectedLead ? act.leads.includes(selectedLead['@id']) : true
          )
          .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
          )
          .map((act) => (
            <DropdownSelectItem
              key={act.id}
              label={act.name}
              to={filterUrl({
                act: act.id,
                period: ensureUpcomingIsMonth(false),
              })}
              selected={actFilter === act.id.toString()}
            />
          ))}
      </SubHeaderSelect>
      {periodFilter && (
        <SubHeaderSelect label="Period" selectedLabel={periodLabel || ''}>
          {actFilter === 'all' ? (
            <DropdownSelectItem
              label="Upcoming (month)"
              to={filterUrl({ period: 'upcoming-month' })}
              selected={periodFilter === 'upcoming-month'}
            />
          ) : (
            <DropdownSelectItem
              label="Upcoming"
              to={filterUrl({ period: 'upcoming' })}
              selected={periodFilter === 'upcoming'}
            />
          )}
          {periodYears.map((year) => (
            <DropdownSelectItem
              key={year}
              label={year}
              to={filterUrl({ period: year })}
              selected={periodFilter === year}
            />
          ))}
          {periodAdvanceWeeks.map((week) => (
            <DropdownSelectItem
              key={week}
              label={labelFromWeek(week)}
              to={filterUrl({ period: week })}
              selected={periodFilter === week}
            />
          ))}
        </SubHeaderSelect>
      )}

      <SubHeaderSpacer />

      <SubHeaderTab
        label="Table"
        iconName="list"
        selected={viewMode === 'table'}
        to={filterUrl({ view: 'table' })}
      />
      <SubHeaderTab
        label="Grid"
        iconName="th-large"
        selected={viewMode === 'grid'}
        to={filterUrl({ view: 'grid' })}
      />
      <SubHeaderTab
        label="Calendar"
        iconName="calendar"
        selected={viewMode === 'calendar'}
        to={filterUrl({ view: 'calendar' })}
      />
    </SubHeader>
  );
}
