// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  errors?: string[],
|}>;

export default function ErrorList({ errors }: Props) {
  if (!errors || errors.length === 0) return null;

  return (
    <StyledList>
      {errors.map((error) => (
        <div key={error}>{error}</div>
      ))}
    </StyledList>
  );
}

const StyledList = styled('div')`
  padding: 10px 0 10px;
  > div {
    padding-bottom: 10px;
    color: red;
    font-size: 14px;
  }
`;
