// @flow

import * as React from 'react';
import styled from 'styled-components';

import { ZIndex } from '../constants';
import Spinner from './Spinner';

export default function PageLoading() {
  return (
    <StyledContainer>
      <Spinner message="Loading" />
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${ZIndex.loading};
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;
