App.Models.Gig = App.Models.Base.extend({

    urlRoot: () => App.config.baseUrl + 'api-platform/gigs',

    iriFields: ['hotel', 'party_users', 'venue'],

    parse: function(resp, options) {
        resp = App.Models.Base.prototype.parse.call(this, resp, options);

        if (options.patch) {
            // 2019: Simulate the different API response in legacy PATCH
            delete resp.schedule_items;
            delete resp.artist_sets;
            delete resp.documents;
            delete resp.folders;
            delete resp.contacts;
            delete resp.party_users;
            delete resp.access_users;
        }
        
        return resp;
    },

    validate: function(attrs, options) {
        var errors = {};
        if(!attrs.date) {
            errors.date = 'Please supply a gig date';
        }

        if (attrs.show_type === 'other' && !attrs.show_type_custom) {
            errors.show_type_custom = 'Please specify the show type';
        }

        if(attrs.open_time && !App.Helpers.Validation.isTime(attrs.open_time)) {
            errors.open_time = 'Please supply a valid 24 hour (HH:MM) open time';
        }
        if(attrs.close_time && !App.Helpers.Validation.isTime(attrs.close_time)) {
            errors.close_time = 'Please supply a valid 24 hour (HH:MM) close time';
        }
        if(attrs.soundcheck_time && !App.Helpers.Validation.isTime(attrs.soundcheck_time)) {
            errors.soundcheck_time = 'Please supply a valid 24 hour (HH:MM) soundcheck time';
        }

        if(typeof attrs.capacity !== 'undefined' && isNaN(attrs.capacity)) {
            errors.close_time = 'Please supply a numeric venue capacity';
        }

        if (typeof attrs.fee !== 'undefined' && isNaN(attrs.fee)) {
            errors.fee = 'Please supply a valid fee, e.g. £1,000';
        }
        if (typeof attrs.fee_plus_vat !== 'undefined' && isNaN(attrs.fee_plus_vat)) {
            errors.fee_plus_vat = 'Please supply a valid plus VAT, e.g. £1,000';
        }
        if (typeof attrs.received !== 'undefined' && isNaN(attrs.received)) {
            errors.received = 'Please supply a valid received, e.g. £1,000';
        }
        if (typeof attrs.collect !== 'undefined' && isNaN(attrs.collect)) {
            errors.collect = 'Please supply a valid collect in cash, e.g. £1,000';
        }

        if(attrs.check_in_time) {
            if (!App.Helpers.Validation.isTime(attrs.check_in_time)) {
                errors.check_in_time = 'Please supply a valid 24 hours (hh:mm) check in time';
            }
        }
        if(attrs.check_out_time) {
            if (!App.Helpers.Validation.isTime(attrs.check_out_time)) {
                errors.check_out_time = 'Please supply a valid 24 hours (hh:mm) check in time';
            }
        }
        //if(!attrs.venue.id) {
            //errros.venue_id = 'Please supply a venue id';
        //}
        if(!_.isEmpty(errors)) {
            return errors;
        }
    },

    relations: [
        {
            type: Backbone.HasOne,
            key: 'act',
            relatedModel: 'App.Models.Act'
        },
        {
            type: Backbone.HasOne,
            key: 'venue',
            relatedModel: 'App.Models.Venue'
        },
        {
            type: Backbone.HasOne,
            key: 'hotel',
            relatedModel: 'App.Models.Hotel'
            /*reverseRelation: {
                key: 'gig',
                includeInJSON: 'id',
            },*/
        },
        {
            type: Backbone.HasMany,
            key: 'documents',
            relatedModel: 'App.Models.Document',
            collectionType: 'App.Collections.Documents',
            includeInJSON: false,
            reverseRelation: {
                key: 'gig',
                includeInJSON: 'id'
            }
        },
        {
            type: Backbone.HasMany,
            key: 'folders',
            relatedModel: 'App.Models.Folder',
            collectionType: 'App.Collections.Folders',
            includeInJSON: false,
            reverseRelation: {
                key: 'gig',
                includeInJSON: 'id'
            }
        },
        {
            type: Backbone.HasMany,
            key: 'schedule_items',
            relatedModel: 'App.Models.ScheduleItem',
            collectionType: 'App.Collections.ScheduleItems',
            includeInJSON: false,
            reverseRelation: {
                key: 'gig',
                includeInJSON: 'id'
            }
        },
        {
            type: Backbone.HasMany,
            key: 'contacts',
            relatedModel: 'App.Models.Contact',
            collectionType: 'App.Collections.Contacts',
            includeInJSON: false,
            reverseRelation: {
                key: 'gig',
                includeInJSON: 'id'
            }
        },
        {
            type: Backbone.HasMany,
            key: 'artist_sets',
            relatedModel: 'App.Models.ArtistSet',
            collectionType: 'App.Collections.ArtistSets',
            includeInJSON: false,
            reverseRelation: {
                key: 'gig',
                includeInJSON: 'id'
            }
        },
        {
            type: Backbone.HasMany,
            key: 'access_users',
            relatedModel: 'App.Models.AccountUser',
            collectionType: 'App.Collections.AccountUsers',
            includeInJSON: false
        },
        {
            type: Backbone.HasMany,
            key: 'party_users',
            relatedModel: 'App.Models.PartyUser',
            collectionType: 'App.Collections.PartyUsers',
            includeInJSON: false,
            reverseRelation: {
                key: 'gig',
                includeInJSON: 'id'
            }
        }
    ],

    findFocusArtistSet: function() {
        return this.get('artist_sets').findWhere({ focus: true });
    }
});