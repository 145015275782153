// @flow

import * as React from 'react';

import useDebounce from './use-debounce';
import useScrollTop from './use-scroll-top';
import useWindowDimensions from './use-window-dimensions';

/**
 * Provide the tools for "virtualising" the rows of a table, i.e. only
 * rendering the rows which are in view (or nearly in view).
 */
export default function useVirtualizedTable(): $ReadOnly<{|
  shouldRenderRow: (top: number) => boolean,
|}> {
  const { scrollTop } = useScrollTop();
  const debouncedScrollTop = useDebounce(scrollTop, 200);
  const { height: windowHeight } = useWindowDimensions();

  const BUFFER_TOP = 500;
  const BUFFER_BOTTOM = 500;

  const shouldRenderRow = React.useCallback(
    function shouldRenderRowFn(top: number) {
      return (
        top > debouncedScrollTop - BUFFER_TOP &&
        top < debouncedScrollTop + windowHeight + BUFFER_BOTTOM
      );
    },
    [debouncedScrollTop, windowHeight]
  );

  return {
    shouldRenderRow,
  };
}
