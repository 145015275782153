// @flow

import type { GigTableEntities } from '../../../../hooks/entities-store';
import type { V_Gig_Table } from '../../../../models';

import * as React from 'react';

import { useApiRead } from '../../../../api';
import { ModalStandardWrap, Spinner } from '../../../../components';
import { GigModalWrap } from '../../components';
import TravellingPartyForm from './TravellingPartyForm';

export type ModalField = 'flights' | 'visa' | 'hotel' | 'misc';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  entities: GigTableEntities,
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
  deleteEntity: (iri: string) => void,
|}>;

function TravellingPartyModal({ gig, entities, onDismiss, ...rest }: Props) {
  // Fetch a fresh copy of the gig here with some extra data (access users)
  const { data, error } = useApiRead({
    path: `/gigs/${gig.id}/access-users`,
  });

  let inner;
  if (error) inner = <ModalStandardWrap>Error</ModalStandardWrap>;
  else if (!data)
    inner = (
      <ModalStandardWrap>
        <Spinner />
      </ModalStandardWrap>
    );
  else
    inner = <TravellingPartyForm data={data} onDismiss={onDismiss} {...rest} />;

  return (
    <GigModalWrap
      title="Travelling Party"
      gig={gig}
      entities={entities}
      onDismiss={onDismiss}
    >
      {inner}
    </GigModalWrap>
  );
}

export default React.memo<Props>(TravellingPartyModal);
