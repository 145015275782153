// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Dimen } from '../constants';

type Props = $ReadOnly<{
  isLoading: boolean,
  label?: string,
  as?: string,
  onClick?: () => mixed,
  /**
   * Is it in a row with other buttons?
   */
  inRow?: boolean,
}>;

export default function ModalMinimalSubmitButton({
  isLoading,
  label = 'Save',
  as,
  onClick,
  inRow,
}: Props) {
  return (
    <StyledButton disabled={isLoading} as={as} onClick={onClick} $inRow={inRow}>
      {label}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  box-sizing: border-box;
  height: 46px;
  min-width: 200px;
  padding: 0 25px;
  margin: ${Dimen.spacing}px ${(p) => (p.$inRow ? '15px' : 'auto')} 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: 1px #fff solid;
  border-radius: 23px;
  cursor: pointer;

  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  text-transform: uppercase;

  transition: all 0.25s;

  &:hover {
    background-color: #fff;
    color: #333;
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;

    &:hover {
      color: #fff;
      background-color: transparent;
    }
  }
`;
