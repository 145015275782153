// @flow

import type { GigTableEntities } from '../../../hooks/entities-store';
import type { DayNote, V_Gig_Table } from '../../../models';

import { format } from 'date-fns';
import * as React from 'react';

import { Modal, ModalMinimalHeader } from '../../../components';
import { useHardEntities } from '../../../hooks';

type Props = $ReadOnly<{|
  title: string,
  /**
   * Support DayNotes too, as they have the same relevant fields
   */
  gig: DayNote | V_Gig_Table, // $ReadOnly<{ act: string, venue?: string, date: string }>,
  entities?: GigTableEntities,
  onDismiss: () => mixed,
  wide?: boolean,
  children: React.Node,
|}>;

export default function GigModalWrap({
  title,
  gig,
  entities,
  onDismiss,
  wide,
  children,
}: Props) {
  const act = useHardEntities().Act[gig.act];
  const venue = gig.venue ? entities?.Venue[gig.venue] : null;
  if (!act) throw new Error('Missing act entity');
  if (gig.venue && !venue) throw new Error('Missing venue entity');

  return (
    <Modal onDismiss={onDismiss} wide={wide}>
      <ModalMinimalHeader
        title={title}
        subTitle={`${act.name} - ${format(gig.date, 'ddd D MMM YYYY')}${
          venue ? ` - ${venue.name}` : ''
        }`}
        onDismiss={onDismiss}
      />
      {children}
    </Modal>
  );
}
