// @flow

import type { TypedFieldProp } from 'react-typed-form';

import * as React from 'react';
import styled from 'styled-components';

type Choice = $ReadOnly<{|
  // eslint-disable-next-line flowtype/no-weak-types
  value: any,
  label: string,
|}>;

type Props = $ReadOnly<{|
  // eslint-disable-next-line flowtype/no-weak-types
  field: TypedFieldProp<any>,
  choices: $ReadOnlyArray<Choice>,
  disabled?: boolean,
|}>;

export default React.memo<Props>(function FieldSelectMini({
  field,
  choices,
}: Props) {
  const currentChoice = choices.find((c) => c.value === field.value);
  return (
    <StyledContainer>
      <StyledSelect
        value={currentChoice && currentChoice.label}
        onChange={(e) => {
          const label = e.target.value;
          const choice = choices.find((c) => c.label === label);
          choice && field.handleValueChange(choice.value);
        }}
      >
        {choices.map((choice) => (
          <option key={choice.label} value={choice.label}>
            {choice.label}
          </option>
        ))}
      </StyledSelect>
      <StyledArrow>▾</StyledArrow>
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  position: relative;
`;

const StyledSelect = styled.select`
  box-sizing: border-box;
  width: 100%;
  padding: 6px 10px;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 1px 3px #0002;

  -webkit-appearance: none;

  &:focus {
    outline: 0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

const StyledArrow = styled.span`
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 15px;
  pointer-events: none;
  user-select: none;
`;
