// Select which of a gig's partyUsers are joining a ScheduleItem
App.Views.Widgets.TripSelection = Backbone.View.extend({

    template: '#template-widget-trip-selection',

    isSaving: false,

    events: {
        'change .js-tripping': 'onTrippingToggled',
        'click .js-save-from-trip': 'onSaveClicked'
    },

    initialize: function(options) {
        this.options = options;
    },

    serializeData: function() {
        var gig = App.Helpers.getGig();
        var users = [];
        gig.get('party_users').each($.proxy(function(partyUser) {
            var data = {
                partyUser: partyUser.toJSON(),
                accountUser: partyUser.get('account_user').toJSON()
            };
            var trip = this.model.get('trips')
                .findWhere({ party_user: partyUser });
            if (trip) {
                data.trip = trip.toJSON();
            }
            users.push(data);
        }, this));

        return {
            users: users,
            model: this.model.toJSON(),
            isSaving: this.isSaving,
            options: this.options
        }
    },

    render: function() {
        var template = App.Helpers.getTemplate( this.template );
        this.$el.html(template(this.serializeData()));

        return this;
    },

    onSaveClicked: function() {
        // If the journey isn't synced yet, make sure it is...

        if (!this.model.isValid()) {
            App.Helpers.Notice.show('There is an error with your changes', 'danger', 2000);
            this.options.parentView.render();
            return;
        }

        // Mirror what happens in JourneyView.onBeforeSave so the from/to_type
        // doesn't get "dropped"
        _.each(['from', 'to'], function(dir) {
            this.options.parentView.locViews[dir].onBeforeSave();
        }.bind(this));

        // However, make sure gig is attached silently to prevent collection chaos
        // in parent view
        this.model.save({
            gig: App.Helpers.getGigId()
        }, {
            silent: true,
            success: $.proxy(function() {
                this.render();
            }, this)
        })
    },

    onTrippingToggled: function(e) {
        var $target = $(e.currentTarget);
        var isChecked = $target.is(':checked');
        var partyUserId = parseInt($target.data('party-user-id'));

        var gig = App.Helpers.getGig();
        var partyUser = gig.get('party_users').get(partyUserId);
        var trip = this.model.get('trips')
            .findWhere({ party_user: partyUser });

        if (isChecked && !trip) {
            // Create partyUser
            this.model.get('trips').create({
                schedule_item: this.model,
                party_user: partyUser
            });
        } else if (!isChecked && trip) {
            // Delete partyUser
            this.model.get('trips').remove(trip);
            trip.destroy();
        }

        this.options.parentView.enableSave();
        if (this.options.parentView.onTripsListChanged) {
            this.options.parentView.onTripsListChanged();
        }
    }

});