App.Views.Journey = App.Views.BaseItem.extend({

    editTemplate: '#template-journey-edit',
    viewTemplate: '#template-journey',
    editing: false,

    airlines: null,
    lazyCheckFlightStatus: null,

    locViews: {},

    events: {
        "change .js-discr": 'onDiscrToggled',

        'click .js-estimate-arrival': 'onEstimateArrivalClicked',

        "click .js-populate": 'populateFlight',
        'blur .js-flight_number': 'onBlurFlightNumber'
    },

    initialize: function(options) {
        this.options = _.extend({}, this._options, {
            scrollToItem: true
        }, options);

        this.airlines = options.airlines;
        this.buildEvents();
        this.model.startTracking();

        this.listenTo(this.model, 'change:discr', this.render);
        this.listenTo(this.model, 'change:carrier_name', this.renderIfNotEditing); // ajax response
        this.listenTo(this.model, 'change:from_timezone change:to_timezone', this.calculateDuration);
        this.listenTo(this.model.get('trips'), 'add remove', this.disableCancel);

        this.lazyCheckFlightStatus = _.debounce(this.checkFlightStatus, 250);
    },

    serializeData: function() {
        var gig = App.Helpers.getGig();
        var tabPartyUser = this.options.currentTab.partyUser;
        var tabTrip = tabPartyUser ?
            this.model.get('trips').findWhere({ party_user: tabPartyUser }) :
            null;

        return {
            serviceClasses: ['first', 'economy', 'business', 'premium', 'standard', 'premiere', 'excellence', 'duo'],
            journey: this.model.toJSON(),
            venue: gig.get('venue').toJSON(),
            hotel: gig.get('hotel') ? gig.get('hotel').toJSON() : null,
            airlines: this.airlines.models,
            getSimpleDuration: this.getSimpleDuration,
            errors: this.model.validationError,
            options: this.options,
            tabTrip: tabTrip ? tabTrip.toJSON() : null
        };
    },

    renderIfNotEditing: function() {
        if (!this.editing) {
            this.render();
        }
    },

    injectSubviews: function() {
        if (this.editing) {
            // Depart At / Arrive At subviews
            var departView = new App.Views.Widgets.DateTime({
                parentView: this, model: this.model, dirName: 'depart', hasTimezone: true,
                dirLabel: 'Depart (local time)', showAsterisk: (this.model.get('discr') === 'PlaneJourney')
            });
            this.$('.js-depart-view').html(departView.render().el);
            this.$('.js-arrive-view').html(new App.Views.Widgets.DateTime({
                parentView: this, model: this.model, dirName: 'arrive', locName: 'to', hasTimezone: true,
                dirLabel: 'Arrive (local time)', syncWith: departView
            }).render().el);

            // From / To location picker subviews
            _.each(['from', 'to'], $.proxy(function(dir) {
                var locView = new App.Views.Widgets.Location({
                    model: this.model, dirName: dir
                });
                this.locViews[dir] = locView;
                this.$('.js-' + dir + '-location').html(locView.render().el);

                this.listenTo(locView, 'timezone-changed', this.calculateDuration);
            }, this));

            this.$('.js-trip-selection').html(new App.Views.Widgets.TripSelection({
                parentView: this, model: this.model, label: 'Who\'s travelling?'
            }).render().el);

            this.$('.js-trips').html(new App.Views.Trips({
                collection: this.model.get('trips'),
                scheduleItemView: this
            }).render().el);
            this.onTripsListChanged();
        }
    },

    onTripsListChanged: function() {
        var isVisible = _.contains(
                ['BoatJourney', 'EurostarJourney', 'PlaneJourney', 'TrainJourney'],
                this.model.get('discr')
            )
            && this.model.get('trips').length > 0;

        this.$('.js-trips').toggle(isVisible);
    },

    onRender: function() {
        if (this.editing) {
            this.$('.js-driver_phone').intlTelInput({
                defaultCountry: 'gb',
                utilsScript: '/js/intl-tel-input-utils.js'
            });

        } else {
            this.lazyCheckFlightStatus();

            var $bottom = this.$('.js-journey-bottom');
            if ($bottom.text().trim() === '') {
                $bottom.hide();
            }
        }
    },

    onBeforeValidate: function() {
        if (this.model.get('discr') == 'CarJourney') {
            // Get the phone number including country code
            var mobile = this.$('.js-driver_phone').intlTelInput('getNumber');
            this.model.set('driver_phone', typeof mobile == 'string' ? mobile : null);
        }
    },

    onBeforeSave: function() {
        this.attachGig();

        // Save the address ID
        _.each(['from', 'to'], $.proxy(function(dir) {
            this.locViews[dir].onBeforeSave();
        }, this));

        // Save all embedded trip objects
        this.$('.js-trips .js-save-changes').trigger('click');
    },

    onSave: function() {
        // Sort order may have changed, re-render the collection
        this.model.trigger('sort-order-changed');
    },

    onCancelClicked: function() {
        // Cancel all embedded trip objects
        this.$('.js-trips .js-cancel').trigger('click');
        App.Views.BaseItem.prototype.onCancelClicked.apply(this, arguments);
    },

    onDiscrToggled: function() {
        this.model.set("discr", this.$(".js-discr").val());
        App.setUnsaved(this.model);
        this.enableSave();
    },

    // Strip the code out of the flight number if necessary
    onBlurFlightNumber: function() {
        var airline = this.$('.js-airline').val();
        var number = this.$('.js-flight_number').val() || '';

        if (!number) return;

        if (airline && airline === number.substr(0, airline.length)) {
            number = number.substr(airline.length);
        }
        // Strip leading zero
        if (number.substr(0, 1) == '0') {
            number = number.substr(1);
        }
        this.$('.js-flight_number').val(number);
    },

    // Departure/arrival date/time fields have been changed
    onDateTimesChanged: function() {
        // Recalculate the duration
        this.calculateDuration();
    },

    // Simple view helper: journey time calculation that can be used when timezones are the same
    getSimpleDuration: function (depart_date, arrive_date) {
        var format = 'YYYY-MM-DD\THH:mm:ss';
        var diff = moment(arrive_date, format).valueOf() - moment(depart_date, format).valueOf();
        return App.Helpers.Format.duration(diff / (1000 * 60));
    },

    calculateDuration: function() {
        var venueTimezone = App.Helpers.getGig().get('venue').get('timezone');
        var fromTimezone = (this.$('.js-from_timezone').val() || '').trim() || venueTimezone || 'Europe/London';
        var toTimezone = (this.$('.js-to_timezone').val() || '').trim() || venueTimezone || 'Europe/London';

        var depart = moment.tz(this.model.get('depart_at'), 'YYYY-MM-DD\THH:mm:ss', fromTimezone);
        var arrive = moment.tz(this.model.get('arrive_at'), 'YYYY-MM-DD\THH:mm:ss', toTimezone);

        if (!depart.isValid() || !arrive.isValid()) {
            return;
        }

        var duration = arrive.diff(depart, 'minutes');
        console.log('Duration calculated as', App.Helpers.Format.duration(duration));
        this.$('.js-duration').val(App.Helpers.Format.duration(duration)).trigger('change');
    },

    onEstimateArrivalClicked: function() {
        var gig = App.Helpers.getGig();
        var journey = this.model;
        var waypoints = {from: {}, to: {}};
        _.each(['from', 'to'], function(dir) {
            var type = journey.get(dir + '_type');
            if (type === 'venue' || type === 'hotel') {
                waypoints[dir]['lat'] = gig.get(type).get('address').get('lat');
                waypoints[dir]['lng'] = gig.get(type).get('address').get('lng');
            } else {
                waypoints[dir]['lat'] = journey.get(dir + '_lat');
                waypoints[dir]['lng'] = journey.get(dir + '_lng');
            }
        });

        var venueTimezone = App.Helpers.getGig().get('venue').get('timezone');
        var fromTimezone = (this.$('.js-from_timezone').val() || '').trim() || venueTimezone || 'Europe/London';
        var depart = moment.tz(this.model.get('depart_at'), 'YYYY-MM-DD\THH:mm:ss', fromTimezone);

        var directionsService = new google.maps.DirectionsService();
        var request = {
            origin: new google.maps.LatLng(waypoints.from.lat, waypoints.from.lng),
            destination: new google.maps.LatLng(waypoints.to.lat, waypoints.to.lng),
            travelMode: google.maps.TravelMode.DRIVING,
            drivingOptions: {
                // This doesn't seem to produce a duration_in_traffic unless account has premium plan
                // https://developers.google.com/maps/documentation/javascript/directions#Legs
                departureTime: depart.toDate(),
                trafficModel: google.maps.TrafficModel.BEST_GUESS
            }
        };
        directionsService.route(request, $.proxy(function(response, status) {
            try {
                var durationSecs = response.routes[0].legs[0].duration.value;
                var durationMins = Math.ceil(durationSecs / 60);

                var arrive = depart.clone();
                arrive.add(durationMins, 'minute');
                this.$('.js-arrive_date').val(arrive.format('DD MMM YYYY')).trigger('change');
                this.$('.js-arrive_time').val(arrive.format('HH:mm')).trigger('change');
                App.Helpers.Notice.show('Estimated duration loaded', 'info', 2000);
            } catch (e) {
                App.Helpers.Notice.show('Error fetching estimated duration', 'danger', 2000);
            }
        }, this));
    },

    // Update the view template with some live status of the flight
    checkFlightStatus: function() {
        var flightNumber = this.model.get('flight_number'),
            carrier = this.model.get('carrier'),
            $status = this.$('.js-status');

        // If they've just created it, and it's going to refresh itself after an ajax
        // fetch, then don't bother checking status yet
        if (!this.model.get('carrier_name')) {
            return;
        }

        // For unknown reasons (bug elsewhere?) check this isn't NaN
        if (isNaN(this.model.get('depart_at'))) {
            return;
        }

        //Work out how long is left until the flight departs
        var diff = (new Date()).getTime() - new Date(this.model.get('depart_at')).getTime();
        //If it's more than 24 hours no point showing a status
        if(Math.abs(diff / 1000) > 86400) {
            return;
        }

        $status.show();
        $status.html('<b>Checking status</b>');

        var depart = moment(this.model.get('depart_at')),
            url = App.config.baseUrl + 'flightstats/status/' +
                carrier + '/' + flightNumber + '/departing/' +
                depart.year() + '/' + depart.format('MM') + '/' + depart.date();

        $.get(url, $.proxy(function(data) {

            var flightStatus = {A:"Active",C:"Canceled",D:"Diverted",DN:"Data source needed",L:"Landed",NO:"Not Operational",R:"Redirected",S:"Scheduled",U:"Unknown"};

            $status.removeClass('journey__status--delayed');
            $status.removeClass('journey__status--on-time');

            if (!data.success) {
                $status.html('<b>Couldn\'t fetch status</b>');
            } else if (data.delayed) {
                $status.addClass('journey__status--delayed');
                $status.html('<b>Delayed</b>' +
                    '<span>Departure: ' + data.departureGateDelayMinutes + ' mins</span>' +
                    '<span>Arrival: ' + data.arrivalGateDelayMinutes + ' mins</span>'
                );
            } else if (data.status == 'A' || data.status == 'S') {
                $status.addClass('journey__status--on-time');
                $status.html('<b>On schedule</b>');
            } else if (data.status) {
                $status.html('<b>' + flightStatus[data.status] + '</b>');
            }

        }, this));
    },

    populateFlight: function() {

        this.$(".js-flight_number").removeClass('form__input--error');

        var flightNumber = false;

        //Get the flight number from the model or the form input
        if(this.editing) {
            flightNumber = this.$(".js-flight_number").val();
        } else {
            //Work out how long is left until the flight departs
            var diff = - new Date().getTime() - new Date(this.model.get('depart_at')).getTime();
            
            //If it's less than 24 hours get the flight number so we can show the status
            if(Math.floor(diff / 1000) <= 86400) {
                flightNumber = this.model.get('flight_number');
            }
        }

        var carrier = this.$('.js-airline').val();

        //Make sure we have a flight number for doing anything
        if(flightNumber) {

            var departVal = $('.js-depart_date').val().trim();
            var depart = departVal ? moment(departVal, 'DD MMM YYYY') : moment();
            var url = App.config.baseUrl + 'flightstats/schedule/' +
                    carrier + '/' + flightNumber + '/departing/' +
                    depart.year() + '/' + depart.format('MM') + '/' + depart.date();

            $.get(url, $.proxy(function (data) {
                if (data.success) {
                    this.$('.js-depart_date').val(moment(data.departureTime).format('DD MMM YYYY')).trigger('change');
                    this.$('.js-depart_time').val(moment(data.departureTime).format('HH:mm')).trigger('change');
                    this.$('.js-arrive_date').val(moment(data.arrivalTime).format('DD MMM YYYY')).trigger('change');
                    this.$('.js-arrive_time').val(moment(data.arrivalTime).format('HH:mm')).trigger('change');

                    this.$('.js-from-type-other').prop('checked', true).trigger('change');
                    this.$('.js-to-type-other').prop('checked', true).trigger('change');

                    this.$('.js-from_name').val(data.departureAirport +
                    (data.departureTerminal ? ' T' + data.departureTerminal : '')).trigger('change');
                    this.$('.js-to_name').val(data.arrivalAirport +
                    (data.arrivalTerminal ? ' T' + data.arrivalTerminal : '')).trigger('change');

                    this.$('.js-from_address-map-input').val(data.departureAddress);
                    this.$('.js-from_address_short').val(data.departureAddress).trigger('change');
                    this.$('.js-to_address-map-input').val(data.arrivalAddress);
                    this.$('.js-to_address_short').val(data.arrivalAddress).trigger('change');

                    this.$('.js-from_lat').val(data.departurePos['lat']).trigger('change');
                    this.$('.js-from_lng').val(data.departurePos['lng']).trigger('change');
                    this.$('.js-to_lat').val(data.arrivalPos['lat']).trigger('change');
                    this.$('.js-to_lng').val(data.arrivalPos['lng']).trigger('change');

                    if (this.locViews['from'] && this.locViews['from'].map) {
                        this.locViews['from'].map.setCenter(data.departurePos);
                    }
                    if (this.locViews['to'] && this.locViews['to'].map) {
                        this.locViews['to'].map.setCenter(data.arrivalPos);
                    }

                    this.$('.js-from_timezone').val(data.departureTimezone).trigger('change');
                    this.$('.js-to_timezone').val(data.arrivalTimezone).trigger('change');

                    // Now should be done by automatic calculations
                    // this.$('.js-duration').val(App.Helpers.Format.duration(data.duration)).trigger('change');
                    console.log('FlightStats expected duration', App.Helpers.Format.duration(data.duration));
                    // Not sure why this is needed, but it is, as timezone changes
                    // don't seem to be triggering the new calculation
                    this.calculateDuration();

                    App.Helpers.Notice.show('Flight details loaded', 'info', 2000);
                } else {
                    this.$(".js-flight_number").addClass('form__input--error');
                    App.Helpers.Notice.show('Error fetching flight details', 'danger', 2000);
                }
            }, this))
                .fail($.proxy(function () {
                    this.$(".js-flight_number").addClass('form__input--error');
                    App.Helpers.Notice.show('Error fetching flight details', 'danger', 2000);
                }, this));

        }
    }
});