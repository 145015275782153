// @flow

import type { TypedFieldProp } from 'react-typed-form';

import * as React from 'react';

import { Switch } from '../components';

type Props = $ReadOnly<{|
  field: TypedFieldProp<boolean>,
  label?: ?string,
  colorOn?: string,
  colorOff?: string,
  disabled?: boolean,
|}>;

export default function FieldSwitch({ field, ...rest }: Props) {
  return (
    <Switch
      value={field.value || false}
      label={field.label}
      onToggle={() => field.handleValueChange(!field.value)}
      disabled={field.isLoading}
      {...rest}
    />
  );
}
