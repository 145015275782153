// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  title: string,
  subTitle?: string,
|}>;

export default function ModalHeader({ title, subTitle }: Props) {
  return (
    <StyledContainer>
      <h2>{title}</h2>
      {subTitle && <h3>{subTitle}</h3>}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  background-color: #f5f5f5;
  border: 1px #e1e1e1 solid;
  padding: 30px;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  h2 {
    font-size: 23px;
    font-weight: 500;
    margin: 0;
    color: #333;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    margin: 2px 0 0;
    color: #888;
  }
`;
