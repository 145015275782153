// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Dropdown } from '../../../components';

type Props = $ReadOnly<{|
  onClick: () => mixed,
  promptContent: React.Node,
  dropdownContent: React.Node | null,
  dropdownLazy?: boolean,
  hoverAttachedSide?: 'left' | 'center' | 'right',
|}>;

/**
 * A generic prompt icon, which when hovered, shows some content in a dropdown.
 *
 * Concrete implementations of this will provide their own contents for the
 * square space, and content for the dropdown.
 *
 * Note that this will disable any hover effects on children.  Can only be used
 * for simple icons.
 */
export default React.memo<Props>(function HoverPrompt({
  onClick,
  promptContent,
  dropdownContent,
  dropdownLazy,
  hoverAttachedSide = 'center',
}: Props) {
  const [hover, setHover] = React.useState(false);

  return (
    <StyledContainer>
      <StyledTarget
        onClick={onClick}
        onMouseOut={() => setHover(false)}
        onMouseOver={() => setHover(true)}
      >
        {promptContent}
      </StyledTarget>
      {dropdownContent && (!dropdownLazy || hover) && (
        <StyledAnchor>
          <Dropdown open={hover} attachedSide={hoverAttachedSide}>
            {dropdownContent}
          </Dropdown>
        </StyledAnchor>
      )}
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
`;

const StyledTarget = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  /* Prevent double onMouseOver bug */
  > * {
    pointer-events: none;
  }
`;

const StyledAnchor = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0px;
  right: 50%;
`;
