// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  length: number,
  width: number,
  color: string,
  diagonal?: boolean, // Show it as an X instead of +
|}>;

export default function PlusGlyph({ ...rest }: Props) {
  return <StyledPlus {...rest} />;
}

const StyledPlus = styled.span`
  width: ${(p) => p.length}px;
  height: ${(p) => p.length}px;
  position: relative;
  display: block;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: ${(p) => p.color};
    top: 0;
    left: 0;
    ${(p) => p.diagonal && `transform: rotate(45deg);`};
  }

  &::before {
    width: ${(p) => p.width}px;
    height: ${(p) => p.length}px;
    left: ${(p) => (p.length - p.width) / 2}px;
  }

  &::after {
    width: ${(p) => p.length}px;
    height: ${(p) => p.width}px;
    top: ${(p) => (p.length - p.width) / 2}px;
  }
`;
