// @flow

import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  title: string,
  description?: React.Node,
|}>;

/**
 * User must be logged in.
 */
const SoloMessage = ({ title, description }: Props) => {
  const dispatch = useDispatch();
  return (
    <StyledContainer>
      <h1>{title}</h1>
      {description && <p>{description}</p>}
      <p>
        <a
          onClick={() => {
            dispatch({ type: 'LOGOUT' });
          }}
        >
          Log out
        </a>
      </p>
    </StyledContainer>
  );
};

export default SoloMessage;

const StyledContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    font-weight: inherit;
    font-size: 40px;
    max-width: 500px;
    margin: 20px 0 0;
  }

  p {
    max-width: 500px;
    color: #999;
    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
