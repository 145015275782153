'use strict';

App.Collections.ScheduleItems = Backbone.Collection.extend({

    model: App.Models.ScheduleItem,

    comparator: function(item) {
        var val = item instanceof App.Models.Journey ? item.get('depart_at') : item.get('start_at');
        return moment(val).format('X');
    }

});