// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  rowTitleSpace?: boolean,
  titles: $ReadOnlyArray<string>,
|}>;

export default function WorkflowTitles({ rowTitleSpace, titles }: Props) {
  return (
    <StyledRow>
      {rowTitleSpace && <StyledCell />}
      {titles.map((title, i) => (
        <>
          <StyledCell>
            <StyledTitle>{title}</StyledTitle>
          </StyledCell>
          {i < titles.length - 1 && <StyledCell />}
        </>
      ))}
    </StyledRow>
  );
}

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCell = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTitle = styled.span`
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
`;
