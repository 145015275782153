// @flow

import * as React from 'react';
import { useForm } from 'react-typed-form';

import { useApiFormSubmit } from '../../../../api';
import {
  ButtonLarge,
  Modal,
  ModalHeader,
  ModalStandardWrap,
} from '../../../../components';
import {
  FieldDate,
  FieldText,
  FieldTypeahead,
  FormWrap,
  createFormValidator,
} from '../../../../form';
import { useHardEntities } from '../../../../hooks';
import schemas from '../../../../schemas';
import { values } from '../../../../util';

type Props = $ReadOnly<{|
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
|}>;

export default function CreateDayNoteModal({
  onDismiss,
  upsertEntities,
}: Props) {
  const acts = values(useHardEntities().Act).filter(Boolean);

  const formSubmit = useApiFormSubmit('POST', `/day_notes`, {
    onSuccess: (responseData) => {
      upsertEntities(responseData, schemas.DayNote);
      onDismiss();
    },
  });
  // eslint-disable-next-line flowtype/no-weak-types
  const { formErrorList, getField, handleSubmit, isLoading } = useForm<any>({
    validate: createFormValidator({
      date: { strictPresence: true },
      act: { strictPresence: true, typeahead: true },
      content: { strictPresence: true },
    }),
    onSubmit: formSubmit,
  });

  return (
    <Modal wide onDismiss={onDismiss}>
      <ModalStandardWrap
        header={
          <ModalHeader
            title="New Day Note"
            subTitle="Create a private note, which only appears to NTRP staff in the Table view"
          />
        }
      >
        <FormWrap formErrorList={formErrorList} handleSubmit={handleSubmit}>
          <FieldDate field={getField('date')} />
          <FieldTypeahead
            field={getField('act')}
            choices={acts.map((act) => ({
              value: act['@id'],
              title: act.name,
            }))}
          />
          <FieldText field={getField('content')} multiline />
          <ButtonLarge cta type="submit" label="Save" disabled={isLoading} />
          <ButtonLarge
            label="Cancel"
            disabled={isLoading}
            onClick={() => {
              onDismiss();
            }}
          />
        </FormWrap>
      </ModalStandardWrap>
    </Modal>
  );
}
