// @flow

import type { V_Act_TableDetail } from '../../../../models';

import { format } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';

import { useApiCallable } from '../../../../api';
import { Icon } from '../../../../components';
import { Color } from '../../../../constants';
import { useAccountUser } from '../../../../hooks';
import { idToIri } from '../../../../util';
import AgencyDiaryForm from './AgencyDiaryForm';

type Props = $ReadOnly<{|
  diary: $ElementType<
    $PropertyType<V_Act_TableDetail, 'agencyDiaries'>,
    number
  >,
  actId: number,
  invalidate: () => void,
|}>;

export default function AgencyDiaryRow({ diary, actId, invalidate }: Props) {
  const accountUser = useAccountUser();
  const { callApi } = useApiCallable();

  const [editing, setEditing] = React.useState<boolean>(false);
  const [loadingCrossCheck, setLoadingCrossCheck] =
    React.useState<boolean>(false);

  const nameText = diary.name || diary.link || 'Unknown';
  const crossCheckedText = diary.crossCheckedAt
    ? `${format(diary.crossCheckedAt, 'ddd D MMM YYYY')}${
        diary.crossCheckedBy ? ` by ${diary.crossCheckedBy.firstName}` : ''
      }`
    : `Never`;

  return (
    <StyledRow>
      {editing ? (
        <AgencyDiaryForm
          existing={diary}
          actId={actId}
          onSuccess={invalidate}
          onDismiss={() => setEditing(false)}
        />
      ) : (
        <>
          <div className="action-buttons">
            <a onClick={() => setEditing(true)}>
              <Icon name="pencil" />
            </a>
          </div>

          {diary.link ? (
            <a href={diary.link} target="_blank" rel="noopener noreferrer">
              {nameText}
            </a>
          ) : (
            <span>{nameText}</span>
          )}
          <div className="detail">
            <span>{crossCheckedText}</span>{' '}
            <a
              disabled={loadingCrossCheck}
              onClick={async () => {
                if (loadingCrossCheck) return;
                setLoadingCrossCheck(true);

                if (
                  confirm(
                    `This will mark you as being the last person to cross-check the diary "${nameText}" - are you sure?`
                  )
                ) {
                  await callApi({
                    method: 'PUT',
                    path: `/agency_diaries/${diary.id}`,
                    jsonBody: {
                      crossCheckedAt: format(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
                      crossCheckedBy: idToIri('account_users', accountUser.id),
                    },
                  });
                  invalidate();
                  setLoadingCrossCheck(false);
                }
              }}
            >
              <Icon name="ok" />
            </a>
          </div>
        </>
      )}
    </StyledRow>
  );
}

const StyledRow = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  padding: 5px 12px;
  margin: 5px 0;
  border-left: 2px ${Color.purple} solid;

  .detail {
    color: #777;
    font-size: 14px;
  }

  .action-buttons {
    position: absolute;
    top: 3px;
    right: 3px;
  }
`;
