// @flow

import * as React from 'react';
import styled from 'styled-components';

import { useDropdown } from '../hooks';
import Dropdown from './Dropdown';

type Props = $ReadOnly<{|
  label: string,
  selectedLabel: string,
  children: React.Node,
|}>;

export default function SubHeaderSelect({
  label,
  selectedLabel,
  children,
}: Props) {
  const { open, triggerEl, handleTriggerClick } = useDropdown();
  return (
    <StyledContainer>
      <StyledTrigger ref={triggerEl} onClick={handleTriggerClick}>
        {label}: <b>{selectedLabel}</b>
      </StyledTrigger>
      <StyledAnchor>
        <Dropdown open={open}>{children}</Dropdown>
      </StyledAnchor>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
`;

const StyledTrigger = styled.a`
  display: flex;
  align-items: center;
  padding: 0 48px 0 30px;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px #d3d3d3 solid;
  border-right: 1px #d3d3d3 solid;
  user-select: none;

  transition: background-color 0.25s;

  * {
    pointer-events: none;
  }

  b {
    margin-left: 5px;
  }

  &:hover {
    color: #333;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 7px solid #bbb;
    position: absolute;
    top: 23px;
    right: 25px;
  }
`;

const StyledAnchor = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
`;
