// @flow

import type { TypedFieldProp } from 'react-typed-form';

import * as React from 'react';

type Props = $ReadOnly<{|
  field: TypedFieldProp<string>,
  type?: string,
  required?: boolean,
|}>;

export default function SplashFieldText({ field, type, required }: Props) {
  return (
    <div className="splash__form-row">
      <input
        value={field.value || ''}
        onChange={(e) => field.handleValueChange(e.target.value || null)}
        placeholder={field.label}
        type={type}
        required={required && 'required'}
        disabled={field.isLoading}
        className="splash__form-input"
      />
    </div>
  );
}
