App.Views.Rooming = App.Views.BaseCollection.extend({

    viewTemplate: '#template-rooming',

    childView: App.Views.Room,
    childModel: App.Models.PartyUser,
    childViewContainer: 'table',

    initialize: function () {
        this.buildEvents();
    },

    attachHtml: function(childView) {
        this.$(this.childViewContainer).append( childView.render().el );
    }

});