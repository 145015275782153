App.Models.ArtistSet = App.Models.Base.extend({

    urlRoot: () => App.config.baseUrl + 'api-platform/artist_sets',
    
    iriFields: ['gig'],

    validate: function(attrs, options) {
        var errors = {};

        if(!attrs.artist_name) {
            errors.artist_name = 'Please supply an artist name';
        }

        if(!attrs.start_at || !App.Helpers.Validation.isTime(attrs.start_at)) {
            errors.start_at = 'Please supply a valid 24 hour (HH:MM) start time';
        }

        if(!attrs.finish_at || !App.Helpers.Validation.isTime(attrs.finish_at)) {
            errors.finish_at = 'Please supply a valid 24 hour (HH:MM) finish time';
        }
        
        if(!_.isEmpty(errors)) {
            return errors;
        }
    },

    getOrdinal: function() {
        var time = parseInt(this.get('start_at').trim().replace(':', ''));

        // Boost early morning sets so they appear after the late night sets
        if (time < 1100) {
            time += 10000;
        }

        return time;
    }
});