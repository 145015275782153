// Basic Auth stuff based on https://github.com/fiznool/backbone.basicauth
// And, as of 2019, some API Platform compat

;(function() {

    var encode = function(credentials) {
        return btoa(unescape(encodeURIComponent(
            [credentials.username, credentials.password].join(':'))));
    };

    Backbone.BasicAuth = {
        getHeader: function(credentials) {
            return { 'Authorization': 'Basic ' + encode(credentials) };
        },

        getMyHeader: function() {
            var credentials = { username: App.config.apiKey, password: '' };
            return this.getHeader(credentials);
        }
    };

    // Store a copy of the original Backbone.sync
    var originalSync = Backbone.sync;

    // Allow headers to be accessed by random ajax calls
    Backbone.RestSync = {
        getHeaders: function() {
            var headers = {};
            _.extend(headers, Backbone.BasicAuth.getMyHeader());
            return headers;
        }
    };

    /**
     * Override Backbone.sync
     *
     * @param  {string} method  Contains the backbone operation. e.g.: read, reset, set
     * @param  {object} model   A Backbone model or collection
     * @param  {object} options Options to be passed over to Backbone.sync and jQuery
     * @return {object}         Reference to Backbone.sync for chaining
     */
    Backbone.sync = function (method, model, options) {
        options = options || {};

        // Add auth credentials
        options.headers = options.headers || {};
        _.extend(options.headers, Backbone.RestSync.getHeaders());

        // 2019 general changes, related to API Platform, not auth.
        if (model.iriFields && options.attrs) {
            options.attrs = App.Helpers.mapIris(options.attrs, model.iriFields);
        }
        // Let the helpers know that the upcoming toJSON call is to be sent to
        // the server.
        options.fromSync = true;

        // Perform the sync
        return originalSync.call(model, method, model, options);
    };

    
    // Store a copy of the original Backbone.sync
    var originalAjax = Backbone.ajax;
    Backbone.ajax = function(request) {
        if (request.type === 'PATCH') request.type = 'PUT';
        return Backbone.$.ajax.call(Backbone.$, request);
    };
})();