App.Views.GuestList = App.Views.BaseItem.extend({

    template: _.template($("#template-guest-list").html()),

    initialize: function () {
        this.buildEvents();
    },

    serializeData: function() {
        return { gig: this.model.toJSON() };
    }

});