// @flow

import type { TypedFieldProp } from 'react-typed-form';

import * as React from 'react';
import styled from 'styled-components';

import { ErrorList, Spacer } from '../../../../components';

type Props = $ReadOnly<{|
  field: TypedFieldProp<number | null>,
  label: string,
  disabled?: boolean,
|}>;

/**
 * | [input] [label] |
 * | [errors]        |
 */
export default function FieldTextMini({ field, label, disabled }: Props) {
  return (
    <div>
      <StyledMainRow>
        <StyledInput
          value={field.value || ''}
          onChange={(e) =>
            field.handleValueChange(
              e.target.value ? parseInt(e.target.value) : null
            )
          }
          disabled={field.isLoading || disabled}
        />
        <Spacer size={0.5} />
        <span>{label}</span>
      </StyledMainRow>
      {field.lastErrorList && (
        <StyledAreaErrors>
          <ErrorList errors={field.lastErrorList} />
        </StyledAreaErrors>
      )}
    </div>
  );
}

const StyledMainRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  width: 24px;
  padding: 6px 10px;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 1px 3px #0002;
`;

const StyledAreaErrors = styled.div`
  background-color: #f2f2f2;
  padding: 8px 12px 0;
`;
