// @flow

import type { GigTableEntities } from '../../../../hooks/entities-store';
import type { V_Gig_Table } from '../../../../models';

import * as React from 'react';
import { useForm } from 'react-typed-form';

import { useApiFormSubmit } from '../../../../api';
import {
  ModalMinimalSubmitButton,
  ModalStandardWrap,
} from '../../../../components';
import { FieldText, FormWrap, createFormValidator } from '../../../../form';
import schemas from '../../../../schemas';
import { values } from '../../../../util';
import { GigModalWrap } from '../../components';

export type ModalField = 'flights' | 'visa' | 'hotel' | 'misc';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  entities: GigTableEntities,
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
|}>;

function SetTimesModal({ gig, entities, onDismiss, upsertEntities }: Props) {
  const formSubmit = useApiFormSubmit('PUT', `/gigs/${gig.id}`, {
    onSuccess: (responseData) => {
      upsertEntities(responseData, schemas.Gig);
      onDismiss();
    },
  });
  const fieldValidation = {
    optionalFormat: {
      pattern: /\d\d:\d\d/,
      message: 'must be a valid 24hr timestamp (HH:MM)',
    },
  };
  // eslint-disable-next-line flowtype/no-weak-types
  const { formErrorList, getField, handleSubmit, isLoading } = useForm<any>({
    pristineValues: gig,
    validate: createFormValidator({
      contractSetStartAt: fieldValidation,
      contractSetFinishAt: fieldValidation,
    }),
    onSubmit: formSubmit,
  });

  const focusSet = values(entities.ArtistSet).find(
    (as) => as?.gig === gig['@id'] && as?.focus
  );

  return (
    <GigModalWrap
      title={`Contract Set Times`}
      gig={gig}
      entities={entities}
      onDismiss={onDismiss}
    >
      <FormWrap formErrorList={formErrorList} handleSubmit={handleSubmit}>
        <ModalStandardWrap>
          <FieldText field={getField('contractSetStartAt')} />
          <FieldText field={getField('contractSetFinishAt')} />

          {focusSet && (
            <p>
              Promoter&apos;s set times: {focusSet.startAt} -{' '}
              {focusSet.finishAt}
            </p>
          )}
        </ModalStandardWrap>
        <ModalMinimalSubmitButton isLoading={isLoading} />
      </FormWrap>
    </GigModalWrap>
  );
}

export default React.memo<Props>(SetTimesModal);
