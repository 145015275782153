// @flow

import styled from 'styled-components';

export default styled.div`
  width: 40px;
  height: 40px;
  position: relative;

  &::after {
    content: '';
    width: 2px;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    background-color: #eee;
    transform: rotate(45deg);
  }
`;
