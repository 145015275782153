App.Views.Schedule = App.Views.BaseCollection.extend({

    viewTemplate: '#template-schedule',

    childView: function(m) {
        return (m instanceof App.Models.Memo)
            ? App.Views.Memo : App.Views.Journey;
    },
    childModel: function(m) {
        return (m instanceof App.Models.Memo)
            ? App.Models.Memo : App.Models.Journey;
    },
    childViewContainer: '.js-journeys',

    // Array of { name: '...' , count: ... }
    tabs: null,
    currentTab: null,

    childViewOptions: function() {
        return {
            airlines: this.airlines,
            currentTab: this.getCurrentTabObject()
        };
    },

    airlines: null,

    events: {
        'click .js-travel-tab': 'onClickTravelTab'
    },

    initialize: function () {
        this.buildEvents();
        var that = this;
        this.listenTo(this.collection, 'save-triggered', function() {
            setTimeout($.proxy(that.renderTabs, that), 0);
        });

        this.determineTabs();

        this.airlines = new App.Collections.Airlines();
        this.airlines.fetch({
            success : function(collection, response, options) {

            },
            error : function(collection, response, options) {
            },
            timeout : 5000
        });
    },

    isChildVisible: function(childModel) {
        if (this.currentTab) {
            var gig = App.Helpers.getGig();
            var partyUser = gig.get('party_users').get(this.currentTab);
            return childModel.get('trips').findWhere({ party_user: partyUser });
        } else {
            return childModel.get('trips').length == 0;
        }
    },

    determineTabs: function() {
        var tabs = [];
        var gig = App.Helpers.getGig();

        // Tab names
        gig.get('party_users').each(function(partyUser) {
            var accountUser = partyUser.get('account_user');
            tabs.push({ id: partyUser.get('id'), name: accountUser.getFullName(), partyUser: partyUser, count: 0 })
        });
        tabs.push({ id: null, name: null, partyUser: null, count: 0 });

        // Tab counts
        this.collection.each(function(scheduleItem) {
            scheduleItem.get('trips').each(function(trip) {
                var accountUser = trip.get('party_user').get('account_user');
                var tab = _.findWhere(tabs, { name: accountUser.getFullName() });
                tab.count++;
            });

            if (scheduleItem.get('trips').length == 0) {
                _.findWhere(tabs, { name: null }).count++;
            }
        });

        return this.tabs = tabs;
    },

    onRender: function() {
        this.renderTabs();
    },

    renderTabs: function() {
        var $travelTabs = this.$('.js-travel-tabs').html('');

        this.determineTabs();
        var hasTabs = this.tabs.length > 1 || (this.tabs.length && this.tabs[0].name != null);
        $travelTabs.toggle(hasTabs);
        if (!hasTabs) { return; }

        _.each(this.tabs, function(tab) {
            var tabClass = 'js-travel-tab h-tabs__tab';
            if (tab.partyUser && App.config.myId === tab.partyUser.get('account_user').get('id')) {
                tabClass += ' h-tabs__tab--me';
            }

            $travelTabs.append('<a class="' + tabClass + '" data-tab="' + tab.id + '">' +
            (tab.name ? tab.name : 'Unsorted') +
            '<span class="h-tabs__number">' + tab.count + '</span></a>');
        });

        $travelTabs.find('.js-travel-tab[data-tab="' + this.currentTab + '"]').trigger('click');

        // Does a render...

        this.$('.js-travel-tabs')
            .find('.js-travel-tab[data-tab="' + this.currentTab + '"]')
            .addClass('h-tabs__tab--active');
    },

    onClickTravelTab: function(e) {
        var lastTab = this.currentTab;
        var $target = $(e.currentTarget);
        this.currentTab = $target.data('tab');
        // console.log(this.currentTab);
        if (lastTab != this.currentTab) {
            this.render();
        }
    },

    getCurrentTabObject: function() {
        return _.findWhere(this.tabs, {id: this.currentTab});
    },

    onAddChildClicked: function(e) {
        // App.Views.BaseCollection.prototype.onAddChildClicked.apply(this, arguments);

        var $target = $(e.currentTarget);
        var childModel = new App.Models.Journey();
        if ($target.data('type') === 'memo') {
            childModel = new App.Models.Memo();
        }
        this.createNewChild(childModel);
    }
});