App.GigController = function(options) {
    this.model = options.model;
    this.initialize.apply(this, arguments);
};

// Set up all properties and methods
_.extend(App.GigController.prototype, {

    // Gig model
    model: null,

    // Collection of all the main tabs
    views: {},

    initialize: function () {
        this.model.fetch({
            success: $.proxy(function(model, respose, options) {
                model.startTracking();
                this.buildTabs();
            }, this)
        });
    },

    constructView: function(name) {
        switch (name) {
            case 'summary': return new App.Views.Summary({ model: this.model });
            case 'party': return new App.Views.Party({ model: this.model });
            case 'venue': return new App.Views.Venue({ model: this.model });
            case 'hotel': return new App.Views.Hotel({ model: this.model });
            case 'rooming': return new App.Views.Rooming({ collection: this.model.get('party_users') });
            case 'schedule': return new App.Views.Schedule({ collection: this.model.get('schedule_items') });
            case 'contacts': return new App.Views.Contacts({ collection: this.model.get('contacts') });
            case 'runningorder': return new App.Views.ArtistSets({ collection: this.model.get('artist_sets') });
            case 'documents': return new App.Views.Documents({ collection: this.model.get('documents') });
            case 'guestlist': return new App.Views.GuestList({ model: this.model });
            default: throw "No view: " + name;
        }
    },

    // Only called once the model has been fetched over ajax
    buildTabs: function() {
        // Set up the fixed views
        new App.Views.Tabs({el: '.gig-tabs', model: this.model}).render();
        new App.Views.Banner({el: '.gig-banner', model: this.model}).render();

        this._tabsBuilt = true;

        var gr = App.gigRouter,
            hash = gr.lastHash.replace('#', '');
        gr.navigate('#', { trigger: true, replace: true });
        gr.navigate(gr.lastHash || '#summary', { trigger: true, replace: true });

        // Re-render the banner if included props may have changed
        var model = this.model;
        App.vent.on('refresh-banner', function() {
            new App.Views.Banner({el: '.gig-banner', model: model}).render();
        });
    },

    showTab: function(name) {
        if (!this._tabsBuilt) {
            return; // Ajax request not ready yet
        }

        var view = this.constructView(name);
        $('.js-app-main').html( view.render().el );
    }

});