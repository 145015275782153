App.Views.Banner = Backbone.View.extend({
    //el: $('.gig-banner'),
    template: _.template($("#template-banner").html()),

    initialize: function () {
        // todo: Should work, but they don't get reset with trackit resetAttributes
        // this.listenTo(this.model, 'change:name change:date invalid', this.render);

        $(window).on('scroll', this.onScroll);
    },

    render: function () {
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var m = moment(this.model.get('date'));

        this.$el.html(this.template({
            gig: this.model.toJSON(),
            dateDayOfWeek: m.format('ddd'),
            dateDate: m.date(),
            dateMonthName: months[m.month()]
        }));
        return this;
    },

    onScroll: function (e) {
        var top = parseInt($(window).scrollTop() / 3);
        this.$('.js-banner-bg')
            .css('transform', 'translateY(' + top + 'px)');
    }
});