// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Dimen, ZIndex } from '../constants';

type Props = $ReadOnly<{|
  open: boolean,
  children: React.Node,
  gravity?: 'down' | 'up',
  attachedSide?: 'left' | 'right' | 'center',
|}>;

export default function Dropdown({
  open,
  children,
  gravity = 'down',
  attachedSide = 'left',
}: Props) {
  return (
    <StyledContainer open={open} gravity={gravity} attachedSide={attachedSide}>
      <StyledScrollWrap>{children}</StyledScrollWrap>
    </StyledContainer>
  );
}

// How far horizontally the attachment arrow is from the edge
const ATTACHMENT_OFFSET = 30;

const sideOffset = (p, adjust = 0, neg = false) => {
  const side = p.attachedSide === 'center' ? 'left' : p.attachedSide;
  const offset =
    (p.attachedSide === 'center' ? 105 : ATTACHMENT_OFFSET + adjust) *
    (neg ? -1 : 1);
  return `${side}: ${offset}px`;
};

const StyledContainer = styled.div`
  position: absolute;
  width: 280px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  pointer-events: none;
  z-index: ${ZIndex.dropdown};
  ${(p) => sideOffset(p, 7, true)};
  max-height: 440px;
  display: flex;

  ${(p) =>
    p.gravity === 'down'
      ? `
    top: 7px;
    border-top: 2px #4d3a78 solid;
    transform: translateY(-10px);
  `
      : `
    bottom: 7px;
    border-bottom: 2px #4d3a78 solid;
    transform: translateY(10px);
  `}

  ${(p) =>
    p.open &&
    `
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  `};

  transition: opacity 0.25s, transform 0.25s;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    position: absolute;
    pointer-events: none;
    ${(p) => sideOffset(p)};

    ${(p) =>
      p.gravity === 'down'
        ? `
      border-bottom: 7px solid #4d3a78;
      top: -9px;
    `
        : `
      border-top: 7px solid #4d3a78;
      bottom: -9px;
    `}
  }
`;

const StyledScrollWrap = styled.div`
  overflow: auto;
  flex: 1;
  padding: ${Dimen.spacing}px;
`;
