App.Models.Trip = App.Models.Base.extend({

    urlRoot: () => App.config.baseUrl + 'api-platform/trips',

    defaults: {
        seat_number: null,
        confirmation_number: null,
        eticket_number: null
    },

    iriFields: ['party_user', 'schedule_item'],

    relations: [
        {
            type: Backbone.HasOne,
            key: 'party_user',
            relatedModel: 'App.Models.PartyUser',
            includeInJSON: 'id'
        }
    ]

});