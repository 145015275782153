'use strict';

App.Models.Memo = App.Models.ScheduleItem.extend({

    urlRoot: () => App.config.baseUrl + 'api-platform/memos',

    iriFields: ['loc_address', 'gig', 'trips'],

    validate: function (attrs, options) {
        var errors = {};

        if (!attrs.title) {
            errors.title = 'Please supply a title';
        }

        if (!attrs.start_at) {
            errors.start_at = 'Please supply a start date and time';
        }

        if (!_.isEmpty(errors)) {
            return errors;
        }
    }

});