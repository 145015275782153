// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  header?: React.Node,
  noPadding?: boolean,
  hideOverflow?: boolean,
  children: React.Node,
|}>;

export default function ModalStandardWrap({
  header,
  noPadding,
  hideOverflow,
  children,
}: Props) {
  return (
    <StyledContainer $hideOverflow={hideOverflow}>
      {header}
      <StyledChildren $noPadding={noPadding}>{children}</StyledChildren>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 7px 30px #000a;
  ${(p) => p.$hideOverflow && `overflow: hidden`};
`;

const StyledChildren = styled.div`
  box-sizing: border-box;
  ${(p) => !p.$noPadding && `padding: 30px 40px`};
  min-height: 100px;
  max-height: calc(100vh - 140px);
  overflow: auto;
  position: relative;
  color: #585858;
`;
