App.Views.Hotel = App.Views.BaseItem.extend(
    _.extend({}, App.Mixins.Typeahead, {

    editTemplate: '#template-hotel',

    events: {
        "click .js-typeahead-change": 'onChangeTypeahead',
        "click .js-typeahead-add": 'onSaveNewTypeahead'
    },

    initialize: function () {
        this.buildEvents();
    },

    serializeData: function() {
        return {
            gig: this.model.toJSON(),
            errors: this.model.validationError
        };
    },

    onRender: function () {
        this.initTypeahead(
            'hotels',
            App.Models.Hotel,
            { allowAdd: true }
        );
    },

    onBeforeSave: function() {
        // Update the hotel for the gig
        this.model.set('hotel', this.$('.js-typeahead-id').val());
    },

    onSaveSynced: function() {
        this.render();
    }
}));