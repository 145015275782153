// @flow

import type { RootState } from '../types';

import { linearGradient } from 'polished';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SERVER_ROOT } from '../config';
import { ZIndex } from '../constants';
import { useMixedUser } from '../hooks/auth';

type Props = $ReadOnly<{|
  subHeader?: ?React.Node,
|}>;

export default function Header({ subHeader }: Props) {
  const dispatch = useDispatch();
  const mixedUser = useMixedUser();
  const { lastGigsPath } = useSelector<RootState, _>(
    (state) => state.tempOptions
  );

  return (
    <StyledContainer>
      <StyledRowMain>
        {mixedUser.type === 'Account' && (
          <>
            <StyledNavLink
              to={lastGigsPath || '/gigs'}
              activeClassName="active"
            >
              Gigs
            </StyledNavLink>
            <StyledNavLink
              href={`${SERVER_ROOT}/admin`}
              target="_blank"
              rel="nofollow noreferrer"
            >
              Admin
            </StyledNavLink>
          </>
        )}
        {/* <StyledNavLink to="/help" activeClassName="active">
          Help
        </StyledNavLink> */}
        <StyledSpacer />
        {mixedUser.type === 'Account' && (
          <StyledEmail>
            <Link to="/profile">{mixedUser.email}</Link>
          </StyledEmail>
        )}
        <StyledLogout onClick={() => dispatch({ type: 'LOGOUT' })}>
          Sign out
        </StyledLogout>
      </StyledRowMain>
      {subHeader}
    </StyledContainer>
  );
}

const StyledContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${ZIndex.header};
`;

const StyledRowMain = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 30px;

  ${linearGradient({
    colorStops: ['#554270', '#48397a'],
    toDirection: 'to bottom',
  })};
  border-bottom: 2px #241c45 solid;
`;

// eslint-disable-next-line no-unused-vars
const StyledNavLink = styled(({ activeClassName, ...rest }) =>
  rest.to ? <Link {...rest} /> : <a {...rest} />
)`
  color: #fff;
  font-size: 18px;
  padding: 5px 0;
  margin: 0 10px;
  transition: color 0.25s;

  &:first-child {
    margin-left: 0;
  }

  &.active {
    font-weight: 600;
  }

  &:hover {
    color: #fffc;
  }
`;

const StyledSpacer = styled.div`
  flex: 1;
`;

const StyledEmail = styled.span`
  font-weight: 300;
  font-size: 15px;

  a {
    color: #fffc;
    transition: color 0.25s;
    &:hover {
      color: #fff;
    }
  }
`;

const StyledLogout = styled.a`
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: #fffa;
  padding: 4px 10px;
  border-radius: 3px;
  border: 1px #fff4 solid;
  transition: all 0.25s;

  &:hover {
    border-color: #fff;
    color: #fff;
  }
`;
