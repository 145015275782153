// @flow

import type { GigTableEntities } from '../../../../hooks/entities-store';
import type { V_Gig_Table } from '../../../../models';
import type { TableRowBackgroundColor } from '../../../../types';
import type { ItemModalState } from '../../types';

import * as React from 'react';
import styled from 'styled-components';

import { Dropdown, TableCell, TableCellButton } from '../../../../components';
import { values } from '../../../../util';
import { AddPrompt } from '../../components';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  entities: GigTableEntities,
  setItemModal: (ItemModalState) => mixed,
  backgroundColor: TableRowBackgroundColor,
|}>;

function TourManagerCell({
  gig,
  entities,
  setItemModal,
  backgroundColor,
}: Props) {
  const [hover, setHover] = React.useState(false);

  const partyUsers = values(entities.PartyUser)
    .filter((pu) => pu && pu?.gig === gig['@id'])
    .filter(Boolean);
  const tourManagers = partyUsers.filter((pu) =>
    ['tour manager', 'tm'].includes(pu.duty?.toLowerCase().trim())
  );
  const numOtherTravellers =
    partyUsers.filter(Boolean).length - tourManagers.length;

  let inner = (
    <StyledSpacedAddPrompt
      onClick={() => setItemModal(['Gig', 'travellingParty', gig.id])}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    />
  );
  if (tourManagers.length > 0) {
    inner = (
      <TableCellButton
        onClick={() => setItemModal(['Gig', 'travellingParty', gig.id])}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        {tourManagers.map((tm) => tm.accountUser.firstName).join(', ')}
        {numOtherTravellers > 0 && (
          <div className="detail">{numOtherTravellers} others travelling</div>
        )}
      </TableCellButton>
    );
  } else if (numOtherTravellers > 0) {
    inner = (
      <TableCellButton
        onClick={() => setItemModal(['Gig', 'travellingParty', gig.id])}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <div className="detail">{numOtherTravellers} travelling</div>
      </TableCellButton>
    );
  }

  return (
    <TableCell
      gap="left"
      noPadding
      width={150}
      backgroundColor={backgroundColor}
    >
      {inner}

      {partyUsers.length > 0 && (
        <StyledAnchor>
          <Dropdown open={hover} attachedSide="left">
            <StyledDropdownContent>
              {partyUsers.map((pu) => (
                <div key={`${pu.id}`}>
                  {pu.accountUser.firstName} {pu.accountUser.lastName}
                  <span> – {pu.duty}</span>
                </div>
              ))}
            </StyledDropdownContent>
          </Dropdown>
        </StyledAnchor>
      )}
    </TableCell>
  );
}

export default React.memo<Props>(TourManagerCell);

const StyledSpacedAddPrompt = styled(AddPrompt)`
  margin-left: 15px;
`;

const StyledAnchor = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0px;
  left: 30px;
`;

const StyledDropdownContent = styled.div`
  font-size: 14px;
  text-align: left;

  > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    span {
      color: #999;
    }
  }
`;
