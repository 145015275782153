// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  label: string,
  selected?: boolean,
  to?: string,
  onClick?: () => mixed,
|}>;

export default function DropdownSelectItem({
  label,
  selected,
  to,
  onClick,
}: Props) {
  return (
    <StyledContainer
      as={to ? Link : 'a'}
      to={to}
      onClick={onClick}
      selected={selected}
    >
      {label}
    </StyledContainer>
  );
}

const StyledContainer = styled.a`
  display: block;
  color: #999;
  border-radius: 6px;
  padding: 12px 20px;
  transition: all 0.4s;

  &:not(:last-child) {
    border-bottom: 1px #ddd solid;
  }

  &:hover {
    background-color: #edebf1;
    color: #4c3a78;
  }

  ${(p) =>
    p.selected &&
    `
    font-weight: 600;
    color: #4c3a78;
  `};
`;
