App.Models.PartyUser = App.Models.Base.extend({

    urlRoot: () => App.config.baseUrl + 'api-platform/party_users',

    defaults: {
        hotel_reservation_number: null,
        hotel_room_type: null,
        hotel_check_in_at: null,
        hotel_check_out_at: null
    },

    iriFields: ['gig', 'account_user'],

    relations: [
        {
            type: Backbone.HasOne,
            key: 'account_user',
            includeInJSON: 'id',
            relatedModel: 'App.Models.AccountUser',
            reverseRelation: {
                key: 'party_user',
                includeInJSON: 'id'
            }
        }
    ],

});