// @flow

import type { TypedFieldProp } from 'react-typed-form';

import { format, parse } from 'date-fns';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import { ErrorList } from '../components';

type Props = $ReadOnly<{|
  field: TypedFieldProp<string | null>,
|}>;

export default function FieldDate({ field }: Props) {
  return (
    <StyledContanier>
      <StyledLabel>{field.label}</StyledLabel>
      <StyledDatePicker
        selected={field.value && parse(field.value)}
        onChange={(date) => field.handleValueChange(format(date, 'YYYY-MM-DD'))}
        // customInput={<CustomInput field={field} />}
        // https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
        dateFormat="yyyy-MM-dd"
        disabled={field.isLoading}
      />
      <ErrorList errors={field.lastErrorList} />
    </StyledContanier>
  );
}

const StyledContanier = styled.div`
  margin-bottom: 15px;
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 3px;
  color: #585858;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 4px 8px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) inset;
  border: 1px #aaa solid;
`;
