// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Icon } from '../../../../components';
import DealIcon from './DealIcon';

type Props = $ReadOnly<{|
  part: 'travel' | 'hotel' | 'ground',
  value: boolean | null,
  selected?: boolean,
  onClick?: (SyntheticInputEvent<EventTarget>) => mixed,
|}>;

export default function CellPart({ part, value, selected, onClick }: Props) {
  return (
    <StyledWrapper
      selected={selected}
      onClick={onClick}
      as={onClick ? 'a' : 'div'}
    >
      <DealIcon type={part} enabled={value} size={22} />
      {value === null && (
        <StyledQuestion>
          <Icon name="question" color="rgb(255, 127, 0)" size={22} />
        </StyledQuestion>
      )}
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 2px transparent solid;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${(p) => p.selected && `border-color: rgb(103, 179, 255)`};
  transition: background-color 0.25s;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const StyledQuestion = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    text-shadow: 0 0 4px #f5f5f5, 0 0 4px #f5f5f5;
  }
`;
