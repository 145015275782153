// @flow

import type { DayNote } from '../../../../models';
import type { ItemModalState } from '../../types';

import * as React from 'react';
import styled from 'styled-components';

import { useApiCallable } from '../../../../api';
import { TableCell } from '../../../../components';
import { useHardEntities } from '../../../../hooks';
import { RowStandardLeft } from '../../components';

type Props = $ReadOnly<{|
  dayNote: DayNote,
  setItemModal: (ItemModalState) => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
  deleteEntity: (string) => void,
|}>;

function DayNoteRow({ dayNote, setItemModal, deleteEntity }: Props) {
  const act = useHardEntities().Act[dayNote.act];
  if (!act) throw new Error(`Missing hard act with IRI: ${dayNote.act}`);

  const { callApi } = useApiCallable();

  return (
    <tr>
      <RowStandardLeft act={act} date={dayNote.date} backgroundColor="grey" />
      <TableCell colSpan={10} backgroundColor="grey" last>
        {dayNote.content}
        <StyledActions>
          <a onClick={() => setItemModal(['DayNote', 'edit', dayNote.id])}>
            Edit
          </a>{' '}
          -{' '}
          <a
            onClick={async () => {
              if (confirm('Are you sure you want to delete this day note?')) {
                await callApi({
                  method: 'DELETE',
                  path: `/day_notes/${dayNote.id}`,
                });
                deleteEntity(dayNote['@id']);
              }
            }}
          >
            Delete
          </a>
        </StyledActions>
      </TableCell>
    </tr>
  );
}

export default React.memo<Props>(DayNoteRow);

const StyledActions = styled.div`
  font-size: 13px;
  color: #999;

  a {
    color: inherit;
    padding: 3px 0;
  }
`;
