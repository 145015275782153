// @flow

import * as React from 'react';

function getScrollTop() {
  return window.document.documentElement.scrollTop;
}

export default function useScrollTop() {
  const [scrollTop, setScrollTop] = React.useState(getScrollTop());

  React.useEffect(() => {
    function handleScroll() {
      setScrollTop(getScrollTop());
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return { scrollTop };
}
