// @flow

import type { GigTableEntities } from '../../../../hooks/entities-store';
import type { V_Gig_Deal } from '../../../../models';
import type { V_Gig_Table } from '../../../../models';

import * as React from 'react';

import { useApiRead } from '../../../../api';
import { ModalStandardWrap, Spinner } from '../../../../components';
import { GigModalWrap } from '../../components';
import ModalContent from './ModalContent';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  entities: GigTableEntities,
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
|}>;

export default React.memo<Props>(function DealModal({
  gig,
  entities,
  onDismiss,
  ...rest
}: Props) {
  // Fetch a fresh copy of the gig here with some extra data
  const { data, error } = useApiRead<V_Gig_Deal>({
    path: `/gigs/${gig.id}/deal`,
  });

  let inner;
  if (error) inner = <ModalStandardWrap>Error</ModalStandardWrap>;
  else if (!data)
    inner = (
      <ModalStandardWrap>
        <Spinner />
      </ModalStandardWrap>
    );
  else inner = <ModalContent gig={data} onDismiss={onDismiss} {...rest} />;

  return (
    <GigModalWrap
      title="Deal"
      gig={gig}
      wide
      entities={entities}
      onDismiss={onDismiss}
    >
      {inner}
    </GigModalWrap>
  );
});
