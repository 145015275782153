// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Icon from './Icon';

type Props = $ReadOnly<{|
  label: string,
  iconName?: string,
  href?: string,
  to?: string,
|}>;

export default function SubHeaderButton({ label, iconName, href, to }: Props) {
  return (
    <StyledContainer href={href} to={to} target={href && '_blank'}>
      {iconName && <Icon name={iconName} color="#999" size={20} />}
      {label}
    </StyledContainer>
  );
}

const StyledContainer = styled((props) =>
  props.to ? <Link {...props} /> : <a {...props} />
)`
  display: flex;
  align-items: center;
  padding: 0 30px 0 25px;
  color: #585858;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px #d3d3d3 solid;
  user-select: none;
  position: relative;
  ${(p) => p.selected && `background-color: #f5f5f5`};
  ${(p) => p.selected && `border-bottom-color: #f5f5f5`};
  ${(p) => p.selected && `color: #303030`};

  transition: background-color 0.25s;

  &:hover {
    color: #333;
    background-color: rgba(0, 0, 0, 0.05);
  }

  i {
    margin-right: 4px;
  }
`;
