// @flow

import type { V_Gig_Table } from '../../../../models';

import * as React from 'react';

import { Color } from '../../../../constants';
import { AddPrompt, CircleIcon, HoverPrompt } from '../../components';
import DropdownPreview from './DropdownPreview';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  onClick: () => mixed,
|}>;

export default React.memo<Props>(function HotelCell({ gig, onClick }: Props) {
  let color = '#ddd';
  let iconName;
  if (gig.hotelNotes) {
    color = '#999';
    iconName = 'info';
  }
  if (gig.hotelNtrpBooking) {
    if (!gig.hotelOptionsSent) color = Color.red;
    else if (!gig.hotelBooked) color = Color.orange;
    else color = Color.green;

    iconName = 'bed';
  }

  return iconName ? (
    <HoverPrompt
      onClick={onClick}
      promptContent={<CircleIcon color={color} iconName={iconName} />}
      dropdownContent={<DropdownPreview gigId={gig.id} />}
      dropdownLazy
    />
  ) : (
    <AddPrompt onClick={onClick} />
  );
});
