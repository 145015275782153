// @flow

import type { V_Gig_Travel } from '../../../../models';

import * as React from 'react';
import { useForm } from 'react-typed-form';
import styled from 'styled-components';

import { useApiFormSubmit } from '../../../../api';
import {
  ModalMinimalSubmitButton,
  ModalStandardWrap,
  Spacer,
} from '../../../../components';
import { Color, Dimen } from '../../../../constants';
import { FieldSwitch, FormWrap } from '../../../../form';
import schemas from '../../../../schemas';
import { FieldModalTextarea, WorkflowCheckboxes } from '../../components';
import WorkflowTitles from '../../components/WorkflowTitles';

type Props = $ReadOnly<{|
  gig: V_Gig_Travel,
  upsertEntities: (entity: {}, schema: mixed) => void,
  onDismiss: () => mixed,
|}>;

export default function ModalContent({
  gig,
  upsertEntities,
  onDismiss,
}: Props) {
  const formSubmit = useApiFormSubmit('PUT', `/gigs/${gig.id}/staff`, {
    onSuccess: (responseData) => {
      upsertEntities(responseData, schemas.Gig);
      onDismiss();
    },
  });

  const form = useForm<V_Gig_Travel>({
    pristineValues: gig,
    onSubmit: formSubmit,
  });

  return (
    <FormWrap {...form}>
      <ModalStandardWrap noPadding hideOverflow>
        <StyledTop>
          <WorkflowTitles
            rowTitleSpace
            titles={['Options Sent', 'Travel Issued', 'Added to App']}
          />
          <Spacer />
          <WorkflowCheckboxes
            rowTitle="In"
            steps={[
              form.getField('travelOptionsSentIn'),
              form.getField('travelIssuedIn'),
              form.getField('travelAddedToAppIn'),
            ]}
          />
          <Spacer />
          <WorkflowCheckboxes
            rowTitle="Out"
            steps={[
              form.getField('travelOptionsSentOut'),
              form.getField('travelIssuedOut'),
              form.getField('travelAddedToAppOut'),
            ]}
          />
        </StyledTop>
        <FieldModalTextarea field={form.getField('travelNotes')} />
        <StyledBottom>
          <StyledBottomCol>
            <FieldSwitch
              field={form.getField('travelPromoterCost')}
              colorOn={Color.green}
              label="Promoter Cost"
            />
          </StyledBottomCol>
          <StyledBottomCol>
            <FieldSwitch
              field={form.getField('inboundTravelNotRequired')}
              label="In Not Required"
              colorOn={Color.green}
            />
          </StyledBottomCol>
          <StyledBottomCol>
            <FieldSwitch
              field={form.getField('outboundTravelNotRequired')}
              label="Out Not Required"
              colorOn={Color.green}
            />
          </StyledBottomCol>
        </StyledBottom>
      </ModalStandardWrap>
      <ModalMinimalSubmitButton {...form} />
    </FormWrap>
  );
}

const StyledTop = styled.div`
  padding: ${Dimen.spacing * 2}px;
  background-color: #eee;
`;

const StyledBottom = styled.div`
  background-color: #eee;
  display: flex;
`;

const StyledBottomCol = styled.div`
  flex: 1;
  padding: ${Dimen.spacing / 2}px ${Dimen.spacing}px;

  &:not(:last-child) {
    border-right: 1px #ccc solid;
  }
`;
