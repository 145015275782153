// @flow

import type { GigTableEntities } from '../../../../hooks/entities-store';
import type { V_Gig_Table } from '../../../../models';

import { compareAsc, format } from 'date-fns';
import * as React from 'react';
import { useForm } from 'react-typed-form';
import styled from 'styled-components';

import { useApiCallable } from '../../../../api';
import {
  ModalMinimalSubmitButton,
  ModalStandardWrap,
  Spinner,
} from '../../../../components';
import { FieldSelect, FieldSwitch, FormWrap } from '../../../../form';
import { useHardEntities } from '../../../../hooks';
import { deserializeLinkList } from '../../../../models/Act';
import schemas from '../../../../schemas';
import { values as valuesFn } from '../../../../util';
import { GigModalWrap } from '../../components';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  entities: GigTableEntities,
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
|}>;

function readableTemplateName(name: string): string {
  return (
    {
      advance: 'Advance',
      chase: 'Chase',
      rider: 'Rider',
      final_itin: 'Final Itinerary',
    }[name] || 'Unknown'
  );
}

export default React.memo<Props>(function EmailModal({
  gig,
  entities,
  onDismiss,
  upsertEntities,
}: Props) {
  const act = useHardEntities().Act[gig.act];
  if (!act) throw new Error(`Missing hard act with IRI: ${gig.act}`);

  const [mode, setMode] = React.useState<'params' | 'preview' | 'send'>(
    'params'
  );
  const [previewResult, setPreviewResult] = React.useState(null);
  const [sendResult, setSendResult] = React.useState(null);

  const advancingEmails = valuesFn(entities.AdvancingEmail)
    .filter((ae) => ae?.gig === gig['@id'])
    .filter(Boolean);

  const { callApi } = useApiCallable();

  const techRiderLinkList = deserializeLinkList(act.techRiderLinkList);
  const hospitalityRiderLinkList = deserializeLinkList(
    act.hospitalityRiderLinkList
  );
  const visualsLinkList = deserializeLinkList(act.visualsLinkList);

  // eslint-disable-next-line flowtype/no-weak-types
  const { formErrorList, getField, handleSubmit, isLoading } = useForm<any>({
    defaultValues: {
      templateName: 'advance',
      techRiderLink:
        techRiderLinkList.length > 0 ? techRiderLinkList[0].link : null,
      hospitalityRiderLink:
        hospitalityRiderLinkList.length > 0
          ? hospitalityRiderLinkList[0].link
          : null,
      visualsLink: visualsLinkList.length > 0 ? visualsLinkList[0].link : null,
      includeAdditionalInfoSection: false,
    },
    onSubmit: async (values, { setLoading }) => {
      setLoading(true);

      const { templateName, ...params } = values;

      // Where did they press submit from?
      if (mode === 'params') {
        setMode('preview');

        const response = await callApi({
          method: 'POST',
          path: `/advancing-mailer/preview/${templateName}`,
          jsonBody: {
            gigId: gig.id,
            ...params,
          },
        });

        // eslint-disable-next-line flowtype/no-weak-types
        setPreviewResult((response.data: any));
        setLoading(false);
      } else if (mode === 'preview') {
        setMode('send');

        await callApi({
          method: 'POST',
          path: `/advancing-mailer/send/${templateName}`,
          jsonBody: {
            gigId: gig.id,
            ...params,
          },
        });

        // Fetch the latest version of the gig, in the right format, and insert
        // it back into the table
        const response = await callApi({ path: `/gigs/${gig.id}/table` });
        upsertEntities(response.data, schemas.Gig);

        setSendResult(response.data);
        setLoading(false);
      }
    },
  });

  return (
    <GigModalWrap
      title="Advancing"
      gig={gig}
      entities={entities}
      onDismiss={onDismiss}
      wide={mode === 'preview'}
    >
      <FormWrap formErrorList={formErrorList} handleSubmit={handleSubmit}>
        {mode === 'params' && (
          <>
            <ModalStandardWrap>
              <StyledHistory>
                <h2>History</h2>

                {advancingEmails.length === 0 ? (
                  <div className="empty">No emails sent yet</div>
                ) : (
                  advancingEmails
                    .sort((a, b) => compareAsc(a.sentAt, b.sentAt))
                    .map((ae) => (
                      <div key={ae['@id']} className="row">
                        {readableTemplateName(ae.templateName)} &ndash;{' '}
                        {format(ae.sentAt, 'ddd D MMM')}
                      </div>
                    ))
                )}
              </StyledHistory>

              <FieldSelect
                field={getField('templateName')}
                choices={['advance', 'chase', 'rider', 'final_itin'].map(
                  (n) => ({
                    value: n,
                    label: readableTemplateName(n),
                  })
                )}
              />

              {['advance', 'rider', 'final_itin'].includes(
                getField('templateName').value
              ) && (
                <>
                  {techRiderLinkList.length > 1 && (
                    <FieldSelect
                      field={getField('techRiderLink')}
                      choices={techRiderLinkList.map((d) => ({
                        value: d.link,
                        label: d.name || d.link,
                      }))}
                      disabled={techRiderLinkList.length < 2}
                    />
                  )}

                  {hospitalityRiderLinkList.length > 1 && (
                    <FieldSelect
                      field={getField('hospitalityRiderLink')}
                      choices={hospitalityRiderLinkList.map((d) => ({
                        value: d.link,
                        label: d.name || d.link,
                      }))}
                      disabled={hospitalityRiderLinkList.length < 2}
                    />
                  )}

                  {visualsLinkList.length > 1 && (
                    <FieldSelect
                      field={getField('visualsLink')}
                      choices={visualsLinkList.map((d) => ({
                        value: d.link,
                        label: d.name || d.link,
                      }))}
                      disabled={visualsLinkList.length < 2}
                    />
                  )}
                </>
              )}

              {getField('templateName').value === 'advance' && (
                <FieldSwitch field={getField('includeAdditionalInfoSection')} />
              )}
            </ModalStandardWrap>
            <ModalMinimalSubmitButton label="Preview" isLoading={isLoading} />
          </>
        )}

        {mode === 'preview' && (
          <>
            <ModalStandardWrap>
              {!previewResult ? (
                <Spinner />
              ) : (
                <>
                  <StyledEmailHeaders>
                    <div>
                      To:{' '}
                      <span>
                        {previewResult.to
                          .map((to) =>
                            to.name ? `${to.name} <${to.email}>` : to.email
                          )
                          .join(', ')}
                      </span>
                    </div>
                    <div>
                      Subject: <span>{previewResult.subject}</span>
                    </div>
                  </StyledEmailHeaders>
                  <div
                    dangerouslySetInnerHTML={{ __html: previewResult.body }}
                  />
                </>
              )}
            </ModalStandardWrap>
            <StyledButtons>
              <ModalMinimalSubmitButton
                inRow
                label="Back"
                isLoading={!previewResult}
                as="a"
                onClick={() => {
                  setPreviewResult(null);
                  setMode('params');
                }}
              />
              <ModalMinimalSubmitButton
                inRow
                label="Send"
                isLoading={!previewResult}
              />
            </StyledButtons>
          </>
        )}

        {mode === 'send' && (
          <>
            <ModalStandardWrap>
              {!sendResult ? <Spinner /> : <p>Email sent successfully</p>}
            </ModalStandardWrap>
            <StyledButtons>
              <ModalMinimalSubmitButton
                label="Done"
                isLoading={!sendResult}
                as="a"
                onClick={() => {
                  onDismiss();
                }}
              />
            </StyledButtons>
          </>
        )}
      </FormWrap>
    </GigModalWrap>
  );
});

const StyledHistory = styled.div`
  border-bottom: 1px #ddd solid;
  padding-bottom: 15px;
  margin-bottom: 30px;

  h2 {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #999;
    margin: 0 0 10px;
  }

  .empty {
    font-size: 14px;
  }

  .row {
    margin: 3px 0;
    font-size: 14px;
  }
`;

const StyledEmailHeaders = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px #ddd solid;

  > div {
    margin: 8px 0;

    span {
      font-weight: 500;
      color: #444;
    }
  }
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
