'use strict';

// Base class for a standard collection of items, that contains common functionality
App.Views.BaseCollection = Backbone.View.extend({

    events: {},
    _events: {
        "click .js-add-child": "onAddChildClicked"
    },

    childViewOptions: {},

    buildEvents: function() {
        this.delegateEvents(_.extend({}, this._events, this.events));

        this.listenTo(this.collection, 'add remove', this.render);
        this.listenTo(this.collection, 'sort-order-changed', this.resort);
        // this.listenTo(this.collection, 'duplicate', this.duplicate);
    },

    resort: function() {
        this.collection.sort();
        this.render();
    },

    serializeData: function() {
        if (this.model) {
            return {
                gig: this.model.toJSON()
            };
        } else {
            return {};
        }
    },

    selectTemplate: function() {
        if(this.viewTemplate) {
            this.template = App.Helpers.getTemplate( this.viewTemplate );
        }
    },

    getChildView: function(child) {
        return this.childView.prototype.initialize ? this.childView : this.childView(child);
    },

    getChildModel: function(child) {
        return this.childModel.prototype.initialize ? this.childModel : this.childModel(child);
    },

    isChildVisible: function(childModel) {
        return true;
    },

    render: function () {
        this.onBeforeRender();

        this.selectTemplate();
        this.$el.html(this.template(this.serializeData()));

        _.each(this.collection.models, function (child) {
            if (this.isChildVisible(child)) {
                this.renderChild(child);
            }
        }, this);

        this.onRender();
        return this;
    },

    renderChild: function (child) {
        var childViewClass = this.getChildView(child);
        var view = new childViewClass($.extend({}, {
            model: child
        }, _.result(this, 'childViewOptions')));
        this.attachHtml(view);
    },

    // Implement to provide custom code after rendering
    onBeforeRender: function() {},

    // Implement to provide custom code after rendering
    onRender: function() {},

    onAddChildClicked: function() {
        this.createNewChild(new this.childModel());
    },

    createNewChild: function(childModel) {
        var childView = this.getChildView(childModel);
        var view = new childView($.extend({}, {
            model: childModel,
            disposeOnCancel: true
        }, _.result(this, 'childViewOptions')));
        view.editing = true;
        this.attachHtml(view);

        if (view.options.scrollToItem) {
            $('html, body').animate({
                scrollTop: view.$el.offset().top
            }, 250);
        }
    },

    // Append html to the collection's el
    // Override to do something other than '.append'
    attachHtml: function(childView) {
        this.$(this.childViewContainer).append( childView.render().el );
    }

    /* duplicate: function(model) {
        console.log('duplicate', arguments);
        var attrs = model.toJSON();
        delete attrs.id;
        delete attrs.gig;
        delete attrs.created_at;
        delete attrs.updated_at;
        console.log(attrs);
        this.createNewChild(attrs);
    } */

});