// @flow

import * as React from 'react';
import styled from 'styled-components';

import { PlusGlyph } from '../../../components';

type Props = $ReadOnly<{|
  onClick: () => mixed,
  className?: string,
|}>;

function AddPrompt({ onClick, className }: Props, ref) {
  return (
    <StyledEmpty onClick={onClick} className={className} ref={ref}>
      <PlusGlyph width={2} length={18} color="#ccc" />
    </StyledEmpty>
  );
}

export default React.forwardRef<Props, _>(AddPrompt);

const StyledEmpty = styled.a`
  box-sizing: border-box;
  width: 40px;
  height: 40px;

  border-radius: 50%;
  border: 1px #ccc solid;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 300;
  user-select: none;

  transition: all 0.25s;

  &:hover {
    border-color: #aaa;
    background-color: #efecf3;
  }
`;
