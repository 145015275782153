// @flow

import type { GigsMinimalEntities } from '../../hooks/entities-store';

import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  isBefore,
  isSameMonth,
  isToday,
  parse,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { GigsSubHeader, PageLoading } from '../../components';
import PageWrap from '../../components/PageWrap';
import { useEntitiesStore, useLastGigsPath } from '../../hooks';
import { gigsFilterUrlParams } from '../../models/Gig';
import schemas from '../../schemas';
import { compareGigsOrDayNotes, values } from '../../util';
import GigCell from './GigCell';

type Props = $ReadOnly<{|
  match: {|
    params: {| leadFilter: string, actFilter: string, periodFilter: string |},
  |},
|}>;

export default function GigsCalendarPage({ match }: Props) {
  const { leadFilter, actFilter, periodFilter } = match.params;
  useLastGigsPath();
  const pageDate = parse(periodFilter);

  const { entities, isLoading } = useEntitiesStore<GigsMinimalEntities>(
    `/gigs/minimal?${gigsFilterUrlParams(leadFilter, actFilter, periodFilter)}`,
    [schemas.Gig]
  );

  const gigs = entities ? values(entities.Gig).filter(Boolean) : null;

  function pageUrl(date: Date): string {
    return `/gigs/calendar/lead-${leadFilter}/act-${actFilter}/${format(
      date,
      'YYYY-MM'
    )}`;
  }

  const weekOptions = { weekStartsOn: 1 }; // Monday

  const currentStart = startOfMonth(pageDate);
  const currentEnd = endOfMonth(currentStart);
  let current = parse(currentStart);

  const weeks = [];
  current = startOfWeek(currentStart, weekOptions);
  while (isBefore(startOfWeek(current, weekOptions), currentEnd)) {
    const weekEnd = endOfWeek(current, weekOptions);
    const week = [];
    while (isBefore(current, weekEnd)) {
      week.push(current);
      current = addDays(current, 1);
    }
    weeks.push(week);
  }

  return (
    <PageWrap
      subHeader={
        <GigsSubHeader
          viewMode="calendar"
          leadFilter={leadFilter}
          actFilter={actFilter}
        />
      }
    >
      <Helmet>
        <title>Gigs</title>
      </Helmet>
      {!gigs || !entities ? (
        <PageLoading />
      ) : (
        <StyledWrap>
          {isLoading && <PageLoading />}

          <StyledHeading>
            <Link to={pageUrl(subMonths(pageDate, 1))}>&lt;</Link>
            <Link to={pageUrl(addMonths(pageDate, 1))}>&gt;</Link>
            <h2>{format(pageDate, 'MMMM YYYY')}</h2>
          </StyledHeading>

          <StyledTable>
            <StyledDaysHeadings>
              {'Mon|Tue|Wed|Thu|Fri|Sat|Sun'.split('|').map((day) => (
                <span key={day}>{day}</span>
              ))}
            </StyledDaysHeadings>
            <StyledDaysGroup>
              {weeks.map((week, wi) => (
                <div key={wi}>
                  {week.map((day, di) => (
                    <StyledDay key={di} today={isToday(day)}>
                      <StyledDayNumber
                        sameMonth={isSameMonth(day, pageDate)}
                        today={isToday(day)}
                      >
                        {format(day, 'D')}
                      </StyledDayNumber>

                      {gigs
                        .filter(
                          (gig) =>
                            gig.date.substr(0, 10) === format(day, 'YYYY-MM-DD')
                        )
                        .sort(compareGigsOrDayNotes)
                        .map((gig) => (
                          <GigCell key={gig.id} gig={gig} entities={entities} />
                        ))}
                    </StyledDay>
                  ))}
                </div>
              ))}
            </StyledDaysGroup>
          </StyledTable>
        </StyledWrap>
      )}
    </PageWrap>
  );
}

const StyledWrap = styled.div`
  padding: 30px 30px;
  color: #585858;
`;

const StyledHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  a {
    border: 1px #ddd solid;
    border-radius: 5px;
    background-color: #fff;
    width: 42px;
    height: 42px;
    margin-right: 8px;
    font-size: 20px;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h2 {
    flex: 1;
    margin: 0 15px;
    font-weight: 500;
  }
`;

const StyledTable = styled.div`
  display: table;
  width: 100%;
`;

const StyledDaysHeadings = styled.div`
  display: table-row;

  > span {
    display: table-cell;
    text-align: center;
    font-weight: 500;
    padding: 5px;
  }
`;

const StyledDaysGroup = styled.div`
  display: table-row-group;

  > div {
    display: table-row;
  }
`;

const StyledDay = styled.div`
  display: table-cell;
  box-sizing: border-box;
  height: 80px;
  width: 14.2%;
  padding: 5px;
  background-color: #fff;
  border: 1px #f5f5f5 solid;
  ${(p) => p.today && `border-color: #A48EC2`};
`;

const StyledDayNumber = styled.div`
  text-align: right;
  margin-bottom: 4px;
  ${(p) => !p.sameMonth && `color: #bbb`};
  ${(p) => p.today && `font-weight: 600`};
`;
