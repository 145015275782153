App.Views.Documents = App.Views.BaseCollection.extend({

    viewTemplate: '#template-documents',

    childView: App.Views.Document,
    childModel: App.Models.Document,
    childViewContainer: '.js-docs',

    currentFolder: null,

    events: {
        'click .js-folder': 'onFolderClicked',
        'click .js-breadcrumb': 'onBreadcrumbClicked',
        'click .js-delete-folder': 'onDeleteFolderClicked',
        'click .js-create-folder': 'onCreateFolderClicked'
    },

    initialize: function () {
        this.buildEvents();

        this.gig = App.Helpers.getGig();

        this.listenTo(this.gig.get('folders'), 'add remove', this.render);
        this.listenTo(this.collection, 'relational:change:folder', this.render);

        this.listenTo(this.gig.get('folders'), 'change:id', this.render);
    },

    serializeData: function() {
        var folders = [];
        if (!this.currentFolder) {
            folders = this.gig.get('folders')
                .filter(function(f) { return !f.get('financial') || App.config.financials });
            folders = _.map(folders, function(f) { return f.toJSON() });
        }

        return {
            gig: this.gig,
            folders: folders,
            currentFolder: this.currentFolder ? this.currentFolder.toJSON() : null
        }
    },

    isChildVisible: function(childModel) {
        return childModel.get('folder') == this.currentFolder;
    },

    onRender: function() {
        var that = this;
        var dz = this.$('#js-dropzone').dropzone({
            //            acceptedFiles todo add this?
            dictDefaultMessage: "Drag here or click here to upload files",
            headers: {
                'Authorization': 'Basic ' + btoa(App.config.apiKey + ':' + ''),
                "Gig-Id": this.gig.get('id'),
            },

            //todo MAY WANT TO CHANGE THIS TO QUEUECOMPLETE, OR REMOVE CHECKING THE QUEUE (AND ADD EACH ITEM TO THE DISPLAYED LIST AS THEY COME)
            init: function () {
                this.on("complete", function (file) {
                    const avoidReRender = this.getUploadingFiles().length > 0
                        || this.getQueuedFiles().length > 0;

                    // if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
                    try {
                        var response = file.xhr.responseText;
                        console.log('file response: ' + response);
                        var jResponse = JSON.parse(response);
                        if (response.length <= 2 || jResponse.error) {
                            throw new Error('Bad upload response: ' + response);
                        }

                        var jDocument = JSON.parse(decodeURI(jResponse.document));

                        // delete jDocument.financial;
                        jDocument.folder = that.currentFolder ?
                            that.currentFolder.get('id') : null;

                        var doc = new App.Models.Document(jDocument);
                        that.collection.add(doc, { silent: avoidReRender });
                        doc.save(null, { silent: avoidReRender });
                    } catch (err) {
                        App.Helpers.Notice.show('There was a problem uploading the file.', 'danger', 2500);
                        throw err;
                    }
                    // }
                });
            }
        });

        this.$('.js-draggable-doc').draggable({
            distance: 3,
            helper: 'clone'
        });

        this.$('.js-breadcrumb').droppable({
            accept: '.js-draggable-doc',
            over: function(event, ui) {
                $(this).addClass('doc-breadcrumbs__item--droppable-hover');
            },
            out: function(event, ui) {
                $(this).removeClass('doc-breadcrumbs__item--droppable-hover');
            },
            drop: function(event, ui) {
                var $draggable = $(ui.draggable);
                var $droppable = $(this);
                $droppable.removeClass('doc-breadcrumbs__item--droppable-hover');
                that.moveDoc($draggable.data('id'), $droppable.data('id'))
            }
        });

        this.$('.js-folder').droppable({
            accept: '.js-draggable-doc',
            over: function(event, ui) {
                $(this).addClass('doc__main--droppable-hover');
            },
            out: function(event, ui) {
                $(this).removeClass('doc__main--droppable-hover');
            },
            drop: function(event, ui) {
                var $draggable = $(ui.draggable);
                var $droppable = $(this);
                $droppable.removeClass('doc__main--droppable-hover');
                that.moveDoc($draggable.data('id'), $droppable.parent().data('id'))
            }
        });
    },

    moveDoc: function(docId, toId) {
        if (!toId) { toId = null; }
        var doc = this.gig.get('documents').get(docId);
        doc.save({ folder: toId }, { patch: true });
    },

    onFolderClicked: function(e) {
        this.loadFolder($(e.currentTarget).parent().data('id'));
    },

    onBreadcrumbClicked: function(e) {
        this.loadFolder($(e.currentTarget).data('id'));
    },

    loadFolder: function(id) {
        this.currentFolder = this.gig.get('folders').get(id);
        this.render();
    },

    onDeleteFolderClicked: function(e) {
        var id = $(e.currentTarget).parent().data('id');
        var folder = this.gig.get('folders').get(id);
        var fileCount = this.gig.get('documents').where({ folder: folder }).length;
        if (fileCount === 0 || confirm('Are you sure you want to delete the folder "' + folder.get('name') + '"?  The ' + fileCount + ' items it contains will be placed in the Home directory.')) {
            var docs = this.collection.where({ folder: folder });
            _.each(docs, function(d) {
                d.set('folder', null);
            });

            folder.destroy();
            App.Helpers.Notice.show('Folder deleted', 'info', 2000);
        }
    },

    onCreateFolderClicked: function(e) {
        var name = prompt('Name for folder?');
        if (name) {
            var type = $(e.currentTarget).data('type');
            this.gig.get('folders').create({
                name: name,
                financial: type === 'financial'
            });
            App.Helpers.Notice.show('Folder created', 'info', 2000);
        }
    }
});