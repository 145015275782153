// @flow

import * as React from 'react';
import { useDispatch } from 'react-redux';

import PageWrap from '../../components/PageWrap';
import { SERVER_ROOT } from '../../config';
import { useAccountUser } from '../../hooks';

export default function ProfilePage() {
  const dispatch = useDispatch();
  const user = useAccountUser();

  return (
    <PageWrap>
      <div className="hcont">
        <div className="page-plain">
          <p>
            <span className="page-plain__section">Logged in as</span>
            {user.email} (
            <a onClick={() => dispatch({ type: 'LOGOUT' })}>sign out</a>)
          </p>

          <p>
            <span className="page-plain__section">Export gigs</span>
            <a
              href={`${SERVER_ROOT}/gigs/csv?key=${user.apiKey}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download CSV
            </a>
          </p>

          {/* <p style={{ overflow: 'hidden' }}>
            <span className="page-plain__section">iCal feed link</span>
            <a href="{{ feed_link }}" target="_blank">
              feed_link
            </a>
            </p> */}
        </div>
      </div>
    </PageWrap>
  );
}
