// @flow

import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Header from './Header';

type Props = $ReadOnly<{|
  subHeader?: ?React.Node,
  children: React.Node,
|}>;

export default function PageWrap({ subHeader, children }: Props) {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/css/legacy-rooted.css" />
        <link rel="stylesheet" href="/css/fontello.css" />
        <link rel="stylesheet" href="/css/react-datepicker.css" />
      </Helmet>
      <Header subHeader={subHeader} />

      <div className="notice js-notice">
        <div className="notice__inner js-notice-text">Changes saved</div>
      </div>
      <StyledContent hasSubHeader={!!subHeader}>{children}</StyledContent>
    </>
  );
}

const StyledContent = styled.div`
  margin-top: ${(p) => 70 + (p.hasSubHeader ? 50 : 0)}px;
`;
