App.Views.Summary = App.Views.BaseItem.extend({

    template: _.template($("#template-summary").html()),

    events: {
        'change select[data-attr=show_type]': 'checkShowTypeCustomVisible'
    },

    initialize: function () {
        this.buildEvents();
    },

    serializeData: function() {
        return {
            gig: this.model.toJSON(),
            errors: this.model.validationError
        };
    },

    onBeforeValidate: function() {
        var date = App.Helpers.normalizeDate(this.$('.js-date').val(), 'DD MMM YYYY');
        this.model.set('date', date);
    },

    onRender: function () {
        this.$('select[data-attr=show_type]').val(this.model.get('show_type'));
        this.checkShowTypeCustomVisible();

        this.$('.date-component').datepicker(App.Helpers.DATEPICKER_CONFIG);
    },

    onSave: function() {
        App.vent.trigger('refresh-banner');
    },

    checkShowTypeCustomVisible: function() {
        this.$('input[data-attr=show_type_custom]')
            .toggle(this.$('select[data-attr=show_type]').val() === 'other');
    }

});