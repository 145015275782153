// @flow

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Spinner } from '../components';
import { SERVER_ROOT } from '../config';
import { useTokenUser } from '../hooks/auth';
import * as GigLegacy from '../pods/gig-legacy';
import * as Static from '../pods/static';

export default function AppAuthedToken() {
  const tokenUser = useTokenUser();
  const [tokenStillValid, setTokenStillValid] = React.useState(null);
  const dispatch = useDispatch();

  GigLegacy.useLegacyConfig({ apiKey: tokenUser.token, staff: false });

  /**
   * Check the token hasn't expired since they last used the site
   */
  React.useEffect(() => {
    async function checkToken() {
      const Authorization = `Basic ${btoa(`${tokenUser.token}:`)}`;
      const response = await fetch(`${SERVER_ROOT}/auth/token-lookup`, {
        headers: { Authorization },
      });

      if (response.status === 403) {
        dispatch({ type: 'LOGOUT' });
        return;
      }

      setTokenStillValid(true);
    }
    checkToken();
  }, [tokenUser.token, dispatch]);

  if (tokenStillValid === null) {
    return <Spinner message="Checking your account" />;
  }

  return (
    <Switch>
      <Route path="/apps" exact component={Static.AppsPage} />
      <Route path="/gigs/:id" exact component={GigLegacy.GigPage} />
      <Redirect to={`/gigs/${tokenUser.gig}`} />
    </Switch>
  );
}
