App.Views.Room = App.Views.BaseItem.extend({

    tagName: 'tbody',
    editTemplate: '#template-room',

    initialize: function() {
        this.buildEvents();
        this.model.startTracking();
    },

    serializeData: function() {
        var data = this.model.toJSON();
        data.account_user = this.model.get('account_user').toJSON();
        return data;
    },

    injectSubviews: function() {
        var opts = {
            parentView: this,
            model: this.model,
            inputWrapperClass: '',
            dateInputClass: '',
            timeInputClass: '',
            calendarClass: 'form__calendar add-on form__calendar--table',
            dateInputStyle: 'max-width: 100px;',
            timeInputStyle: 'max-width: 75px;'
        };
        var checkInView = new App.Views.Widgets.DateTime($.extend({}, opts, {
            dirName: 'hotel_check_in'
        }));
        this.$('.js-check_in-view').html(checkInView.render().el);
        this.$('.js-check_out-view').html(new App.Views.Widgets.DateTime($.extend({}, opts, {
            dirName: 'hotel_check_out'
        })).render().el);
    }

});