'use strict';

// Base class for a standard item view, that contains common functionality
App.Views.BaseItem = Backbone.View.extend({

    editing: true,

    events: {},

    _events: {
        "click .js-save-changes": "onSaveClicked",
        'click .js-edit': 'onEditClicked',
        'click .js-delete': 'onDeleteClicked',
        'click .js-cancel': 'onCancelClicked',
        'click .js-duplicate': 'onDuplicateClicked',

        "keyup input, textarea": "onFieldChanged",
        "change input, textarea, select": "onFieldChanged",
        "paste input, textarea": "onFieldChanged"
    },

    _options: {
        disposeOnCancel: false,
        scrollToItem: false // Scroll to when switching between view/edit?
    },

    buildEvents: function() {
        this.delegateEvents(_.extend({}, this._events, this.events));
        // this.model.on("invalid", this.render, this); // Not sure if needed
    },

    serializeData: function() {
        return this.model.toJSON();
    },

    selectTemplate: function() {
        if(this.editTemplate && this.editing === true) {
            this.template = App.Helpers.getTemplate( this.editTemplate );
        } else if (this.viewTemplate) {
            this.template = App.Helpers.getTemplate( this.viewTemplate );
        }
    },

    render: function () {
        this.onBeforeRender();

        this.selectTemplate();
        this.$el.html(this.template(this.serializeData()));

        // Let the subclass inject any subviews
        this.injectSubviews();

        var that = this;
        var checkRadioStates = function() {
            // Update visual state of all radio containers in form to reflect input state
            that.$('input[type=radio]').each(function () {
                $(this).parent().toggleClass('form__radio--selected', $(this).is(':checked'));
            });
        };
        this.$('input[type=radio]').change($.proxy(checkRadioStates, this));
        checkRadioStates();

        // Add error class to any relevant fields
        this.$('input[data-attr], input[data-error-attr], div[data-error-attr]').each(function() {
            var attr = $(this).data('attr') || $(this).data('error-attr');
            var errors = that.model.validationError;
            if (errors && errors[attr]) {
                $(this).addClass('form__input--error');
                var $error = $(this).siblings('.form__error');
                if ($error.length == 0) {
                    $error = $(this).closest('.form__col-input').children('.form__error');
                }
                if ($error.length == 0) {
                    var $after = $(this);
                    /* if ($after.siblings('.form__hint').length > 0) {
                        $after = $after.siblings('.form__hint:first');
                    } */
                    $error = $('<div class="form__error"></div>');
                    $after.after($error);
                }
                var errorText = errors[attr];
                if ($error.text() != errorText) {
                    $error.append(errors[attr] + '<br />');
                }
            }
        });

        this.onRender();

        return this;
    },

    // Implement to provide custom code after rendering
    onBeforeRender: function() {},

    // Implement if needed
    injectSubviews: function() {},

    // Implement to provide custom code after rendering
    onRender: function() {},

    enableSave: function() {
        this.$('.js-save-changes:last').removeAttr('disabled');
    },

    disableSave: function() {
        this.$('.js-save-changes:last').attr('disabled', 'disabled');
    },

    // 2020: Help work around pre-existing schedule bugs
    // https://manage.m10c.com/company/ntrp/tickets/f6dc0fe4-03d1-4e4a-9c3c-ff5660965abe
    disableCancel: function() {
        this.$('.js-cancel:last').attr('disabled', 'disabled');
    },

    // When the user interacts with a field
    onFieldChanged: function(e) {
        e.stopPropagation();
        var $clicked = $(e.currentTarget);

        // The field should have the data-attr property set if it wants
        // to control a field of the model
        if (!$clicked.attr('data-attr')) {
            if ($clicked.attr('data-enable-save') || $clicked.attr('data-error-attr')) {
                this.enableSave();
            }
            return;
        }

        // Get the key and value from the element itself
        var key = $clicked.data('attr'),
            val = this.getElementValue($clicked);

        // Immediately make the changes to the model
        this.model.set(key, val);
        console.log('Views.BaseItem#onFieldChange', key, val);

        // Notify the app that there are now unsaved changes,
        // and make save button clickable
        App.setUnsaved(this.model);
        this.enableSave();
    },

    // Read the value of a form element on the DOM into the appropriate format
    // for storing in a model attribute
    getElementValue: function($el) {
        var val;
        if ($el.is(':checkbox')) {
            val = $el.is(':checked');
        } else {
            val = ($el.val() || '').trim();
        }

        var parser = $el.attr('data-parser');
        if (parser === 'money') {
            _.each(App.Helpers.CURRENCIES, function(c) {
                val = val.replace(c, '');
            });
            val = val.trim().replace(',', '').replace(' ', '');
            if (!isNaN(val)) { val = parseFloat(val); }
            // 2020 API Platform requires decimals as strings
            val = val.toString();
        } else if (parser === 'thousands') {
            val = val.trim().replace(',', '').replace(' ', '');
            if (!isNaN(val)) { val = parseInt(val); }
        } else if (parser === 'time') {
            val = val.trim();
            if (val.match(/^\d:\d\d$/)) { val = '0' + val; }
        } else if (parser === '?int') {
            val = val.trim();
            if (!isNaN(val)) { val = parseInt(val); }
        } else if (parser === '?float') {
            val = val.trim();
            if (!isNaN(val)) { val = parseFloat(val); }
        } else if (parser === 'boolean') {
            if (val == 'true') { val = true; }
            else if (val == 'false') { val = false; }
            else { val = null; }
        } else if (parser === 'duration') {
            var matches = /^((\d+)(h|hr|\:))? ?(\d+)m?$/i.exec(val.trim());
            if (matches) {
                var mins = matches[4],
                    hours = matches[2] ? matches[2] : 0;
                val = parseInt(mins) + parseInt(hours) * 60;
            } else {
                val = null
            }
        }

        if (val === '') {
            val = null;
        }

        return val;
    },

    // For collections which show an edit button
    onEditClicked: function() {
        this.editing = true;
        this.render();
    },

    // When the user clicks Save Changes
    onSaveClicked: function(e) {
        console.log('save');
        e.preventDefault();
        this.triggerSave();
    },

    onDeleteClicked: function(e) {
        // For documents where two links are overlayed
        e.stopPropagation();

        if (confirm('Are you sure you want to delete this item?')) {
            this.model.destroy();
            App.Helpers.Notice.show('Item deleted', 'info', 2000);
        }

        return false;
    },

    onCancelClicked: function(e) {
        console.log('cancel');
        e.preventDefault();
        this.editing = false;
        this.model.resetAttributes();
        App.resetUnsaved(); // todo - can cause no message trigger when one should if
                            // multiple things open
        if (this.options.disposeOnCancel) {
            this.remove();
        } else {
            this.render();
        }
    },

    onDuplicateClicked: function() {
        this.model.trigger('duplicate', this.model);
    },

    // You can call this to programatically trigger a save
    triggerSave: function() {

        this.onBeforeValidate();

        if (!this.model.isValid()) {
            App.Helpers.Notice.show('There is an error with your changes', 'danger', 2000);
            console.log('Errors', this.model.validationError);

            this.render();
            return;
        }

        this.onBeforeSave();

        // if (this.model.isNew() || this.model.unsavedAttributes()) {
            var attrs = {},
                that = this,
                opts = {
                    success: function() {
                        App.Helpers.Notice.show('Changes saved', 'success', 2000);
                        $.proxy(that.onSaveSynced, that)();
                    },
                    error: function(err) {
                        App.Helpers.Notice.show('Error! Your changes may not have saved. Please refresh to check', 'danger', 10000);
                        throw new Error('Failed saving model from triggerSave');
                    },
                };
            if (!this.model.isNew()) {
                attrs = this.model.unsavedAttributes() || {};
                opts['patch'] = true;
            }

            this.model.save(attrs, opts);
            App.resetUnsaved();
            this.model.restartTracking();

            this.disableSave();
            if (this.viewTemplate) {
                this.editing = false;
            }

            this.onSave();
        // }

        // Event that can be read by the collection
        this.model.trigger('save-triggered');

        this.render();
    },

    onBeforeValidate: function() {},

    onBeforeSave: function() {},

    onSave: function() {},

    onSaveSynced: function() {},

    // Set the gig id if they're creating for the first time
    attachGig: function() {
        if (this.model.get('gig') == null) {
            this.model.set('gig', App.Helpers.getGigId());
        }
    }
});