// @flow

import type { AccountUserLead, ActHard, VenueTypeahead } from '../models';
import type { Action, Entities } from '../types';

export type HardEntitiesPopulatedState = $ReadOnly<{|
  AccountUser: Entities<AccountUserLead>,
  Act: Entities<ActHard>,
  // Fetch venues later as the payload is so massive
  Venue?: Entities<VenueTypeahead>,
|}>;

export type HardEntitiesState = null | HardEntitiesPopulatedState;

const initialState: HardEntitiesState = null;

export default function hardEntities(
  state: HardEntitiesState = initialState,
  action: Action
): HardEntitiesState {
  switch (action.type) {
    case 'HARD_ENTITIES_INITIAL_SUCCESS':
      return {
        AccountUser: {},
        Act: {},
        ...action.payload,
      };
    case 'HARD_ENTITIES_VENUES_SUCCESS':
      if (!state) return state;
      return {
        ...state,
        Venue: action.payload,
      };
    default:
      return state;
  }
}
