// @flow

import * as React from 'react';

import { Dimen } from '../constants';

type Props = $ReadOnly<{|
  size?: number,
|}>;

export default React.memo<Props>(function Spacer({ size = 1 }: Props) {
  const sizePx = size * Dimen.spacing;
  return <div style={{ width: sizePx, height: sizePx }} />;
});
