'use strict';

App.Collections.Documents = Backbone.Collection.extend({

    model: App.Models.Document,
    url: () => App.config.baseUrl + 'api-platform/documents',

    comparator: function(d) {
        return d.get('filename').toLowerCase();
    }

});