'use strict';

App.Collections.ArtistSets = Backbone.Collection.extend({

    model: App.Models.ArtistSet,

    comparator: function(set) {
        return set.getOrdinal();
    }

});