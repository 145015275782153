// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Icon from './Icon';

type Props = $ReadOnly<{|
  label: string,
  iconName: string,
  selected: boolean,
  to: string,
|}>;

export default function SubHeaderTab({ label, iconName, selected, to }: Props) {
  return (
    <StyledContainer selected={selected} to={to}>
      <Icon name={iconName} color="#bbb" size={20} />
      {label}
    </StyledContainer>
  );
}

const StyledContainer = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 30px 0 25px;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px #d3d3d3 solid;
  border-left: 1px #d3d3d3 solid;
  user-select: none;
  position: relative;
  ${(p) => p.selected && `background-color: #f5f5f5`};
  ${(p) => p.selected && `border-bottom-color: #f5f5f5`};
  ${(p) => p.selected && `color: #303030`};

  transition: background-color 0.25s;

  &:hover {
    color: #333;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    background-color: #f5f5f5;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    /* Doesn't work as React re-renders the header */
    transition: all 0.25s;
  }

  ${(p) =>
    p.selected &&
    `
    &::before {
      height: 7px;
      transform: translateY(-7px);
    }

    &:hover {
      background-color: #f5f5f5;
    }
  `}

  i {
    margin-right: 4px;
  }
`;
