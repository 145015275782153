// @flow

import type { HardEntitiesPopulatedState } from '../reducers/hard-entities';
import type { RootState } from '../types';

import { useSelector } from 'react-redux';

export function useHardEntities(): HardEntitiesPopulatedState {
  const hardEntities = useSelector<RootState, _>((state) => state.hardEntities);
  if (!hardEntities)
    throw new Error('Called useHardEntities before it was fetched');
  return hardEntities;
}
