// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  value: boolean,
  onToggle: () => mixed,
  label?: ?string,
  colorOn?: string,
  colorOff?: string,
  disabled?: boolean,
|}>;

export default function Switch({
  value,
  onToggle,
  label,
  colorOn = '#846CA4',
  colorOff = '#888',
  disabled,
}: Props) {
  return (
    <StyledContainer>
      <StyledTarget
        value={value}
        disabled={disabled}
        onClick={disabled ? undefined : onToggle}
      >
        <div
          style={{
            backgroundColor: value ? colorOn : colorOff,
          }}
        >
          <i />
        </div>
      </StyledTarget>
      {label && (
        <StyledLabel style={{ color: value ? colorOn : '#888' }}>
          {label}
        </StyledLabel>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTarget = styled.a`
  padding: 7px 0;
  ${(p) =>
    p.disabled &&
    `
    cursor: default;
    opacity: 0.4;
  `};

  div {
    width: 35px;
    height: 18px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    transition: background-color 0.25s, opacity 0.25s;
  }

  i {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.25);
    margin: 0 3px;
    transform: ${(p) => (p.value ? 'translateX(17px)' : 'translateX(0)')};
    transition: transform 0.25s;
  }

  &:hover {
    ${(p) => !p.disabled && 'opacity: 0.8'};
  }
`;

const StyledLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 8px;
  transition: color 0.25s;
  user-select: none;
`;
