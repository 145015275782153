App.Views.Filters = Backbone.View.extend({

    template: _.template($("#template-filters").html()),

    events: {
        'click .js-dropdown-trigger': 'onDropdownTriggerClicked'
    },

    initialize: function () {
        this.listenTo(this.model, 'change', this.fetchAndFilterGigs);
        this.listenTo(this.model, 'change', this.render);

        this.listenTo(App.vent, 'body:click', this.onBodyClicked);
    },

    fetchAndFilterGigs: function() {
        App.gigs.filtered.loaded = false;
        App.gigs.filtered.reset();

        var type = this.model.get('type'),
            collection = App.gigs[type];
        if (!collection.fetched) {
            collection.fetched = true;
            collection.fetch()
                .then($.proxy(function() {
                    this.filterGigs(type, collection);
                }, this));
        } else {
            this.filterGigs(type, collection);
        }
    },

    filterGigs: function(type, collection) {
        var actFilter = this.model.get('act');
        if (actFilter) {
            actFilter = _.findWhere(App.acts, {slug: actFilter}).id;
        }

        var filtered = collection.filter(function(gig) {
            return !actFilter || actFilter === gig.get('act').get('id');
        });

        App.gigs.filtered.loaded = true;
        App.gigs.filtered.reset(filtered);
    },

    render: function () {
        var data = {
            filters: App.filters.toJSON(),
            filtersHash: function(changes) {
                var currentAct = App.filters.get('act'),
                    hash =
                    '#' + (changes.layout || App.filters.get('layout')) +
                    '/' + (changes.type || App.filters.get('type'))
                ;
                if (changes.act != null || (typeof changes.act == 'undefined' && currentAct)) {
                    hash += '/' + (changes.act || currentAct);
                }
                return hash;
            }
        };

        data.currentActLabel = 'All Artists';
        if (App.filters.get('act')) {
            var act = _.findWhere(App.acts, { slug: App.filters.get('act') });
            data.currentActLabel = act.name;
        }

        this.$el.html(this.template(data));
        return this;
    },

    onDropdownTriggerClicked: function(e) {
        e.stopPropagation();
        var $dropdown = $(e.currentTarget).next('.app-header-dropdown');
        this.$('.app-header-dropdown').not($dropdown).hide();
        $dropdown.toggle();
    },

    // This will get triggered even when clicking an item in the dropdown,
    // that's the idea.  Solves popup staying open if they click what
    // they're already on.
    onBodyClicked: function() {
        this.closeAllDropdowns();
    },

    closeAllDropdowns: function() {
        this.$('.app-header-dropdown').hide();
    }
});