// Only works when placed inside a BaseItem... tightly coupled
App.Views.Widgets.DateTime = Backbone.View.extend({

    template: '#template-widget-date-time',

    events: {
        'change .js-datetime-field': 'onDateTimesChanged',
        'click .js-datetime-field': 'onDateTimesChanged',

        'change .js-date-field': function() { this.trigger('date-changed'); }
    },

    initialize: function(options) {
        this.options = _.extend({}, {
            parentView: null,
            dirName: null,
            dirLabel: null,
            syncWith: null,
            showAsterisk: false,

            labelWrapperClass: 'form__col-label',
            inputWrapperClass: 'form__col-input',

            dateInputClass: 'form__input form__input--date',
            timeInputClass: 'form__input form__input--time',
            calendarClass: 'form__calendar add-on',

            dateInputStyle: '',
            timeInputStyle: 'max-width: 110px;'
        }, options);

        if (this.options.syncWith) {
            this.listenTo( this.options.syncWith, 'date-changed', this.onSyncChanged );
        }
    },

    serializeData: function() {
        return {
            model: this.model.toJSON(),
            options: this.options,
            dir: this.options.dirName,
            loc: this.options.locName
        }
    },

    render: function() {
        var template = App.Helpers.getTemplate( this.template );
        this.$el.html(template(this.serializeData()));

        this.$('.date-component').datepicker(App.Helpers.DATEPICKER_CONFIG);

        return this;
    },

    // Departure/arrival date/time fields have been changed
    onDateTimesChanged: function() {
        // Update the model fields
        var format = 'DD MMM YYYY HH:mm';

        var inputDate = this.getDateVal();
        var inputTime = this.getTimeVal();
        var result = (inputDate && inputTime) ?
            App.Helpers.normalizeDateTime(inputDate + ' ' + inputTime, format) :
            null;
        this.model.set(this.options.dirName + '_at', result);
        // console.log('DateTimeWidget - setting', this.options.dirName + '_at', result);

        var parentView = this.options.parentView;
        if (parentView && parentView.onDateTimesChanged) {
            parentView.onDateTimesChanged();
        }
    },

    getDateVal: function() {
        return this.$('.js-' + this.options.dirName + '_date').val().trim();
    },

    getTimeVal: function() {
        var val = this.$('.js-' + this.options.dirName + '_time').val().trim();
        if (val.length === 4) { val = '0' + val; }
        return val;
    },

    // Update arrival date to match departure if appropriate.
    // Saves the user having to set both to the future.
    onSyncChanged: function() {
        var format = 'DD MMM YYYY';
        var sourceVal = this.options.syncWith.getDateVal();
        var sourceMoment = moment(sourceVal, format);
        if (!sourceMoment.isValid()) { return; }

        var targetVal = this.getDateVal();
        var targetMoment = moment(targetVal, format);

        if (!targetMoment.isValid()
            || sourceMoment.format('YYYY-MM-DD') > targetMoment.format('YYYY-MM-DD')
        ) {
            var sourceString = sourceMoment.format('DD MMM YYYY');
            this.$('.js-' + this.options.dirName + '_date')
                .val(sourceString).trigger('change')
                .parent().datepicker('update', sourceString);
        }
    }

});