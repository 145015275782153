// @flow

import type { V_Act_TableDetail } from '../../../../models';

import * as React from 'react';
import styled from 'styled-components';

import { Spacer } from '../../../../components';
import { Color, Dimen } from '../../../../constants';
import AgencyDiaryForm from './AgencyDiaryForm';
import AgencyDiaryRow from './AgencyDiaryRow';
import Section from './Section';

type Props = $ReadOnly<{|
  act: V_Act_TableDetail,
  invalidate: () => void,
|}>;

export default function SectionDiaries({ act, invalidate }: Props) {
  const [newFormShown, setNewFormShown] = React.useState<boolean>(false);

  return (
    <Section title="Agency Diaries" minWidth={360}>
      {act.agencyDiaries.length === 0 && (
        <StyledNotSet>No diaries added</StyledNotSet>
      )}
      {act.agencyDiaries.map((diary, i) => {
        return (
          <React.Fragment key={i}>
            <AgencyDiaryRow
              diary={diary}
              actId={act.id}
              invalidate={invalidate}
            />
            <Spacer size={0.25} />
          </React.Fragment>
        );
      })}

      {newFormShown ? (
        <StyledNewFormWrap>
          <AgencyDiaryForm
            existing={null}
            actId={act.id}
            onSuccess={invalidate}
            onDismiss={() => setNewFormShown(false)}
          />
        </StyledNewFormWrap>
      ) : (
        <div>
          <a onClick={() => setNewFormShown(true)}>+ Add new diary</a>
        </div>
      )}
    </Section>
  );
}

const StyledNotSet = styled.div`
  color: #777;
  margin-bottom: ${Dimen.spacing * 0.5}px;
`;

const StyledNewFormWrap = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  padding: 5px 12px;
  margin: 5px 0;
  border-left: 2px ${Color.purple} solid;
`;
