'use strict';

// Reference implementation for a collection - keep this clean!
App.Views.Contacts = App.Views.BaseCollection.extend({

    viewTemplate: '#template-contacts',

    childView: App.Views.Contact,
    childModel: App.Models.Contact,
    childViewContainer: '.js-contacts-list',

    initialize: function () {
        this.buildEvents();
    }
});