'use strict';

App.Collections.Trips = Backbone.Collection.extend({

    model: App.Models.Trip,

    comparator: function(t) {
        if (t.get('party_user')) {
            return t.get('party_user').get('account_user').get('first_name').toLowerCase();
        }
    }

});