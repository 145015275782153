// @flow

import type { AccountUserLead, PartyUser } from '../../../../models';

import * as React from 'react';
import styled from 'styled-components';

import { Switch, TextInput } from '../../../../components';

type Props = $ReadOnly<{|
  accessUser: AccountUserLead,
  partyUser: ?PartyUser,
  gigIri: string,
  value: $ReadOnlyArray<PartyUser>,
  // eslint-disable-next-line flowtype/no-weak-types
  handleValueChange: ($ReadOnlyArray<any>) => mixed,
|}>;

export default function TravellingPartyRow({
  accessUser,
  partyUser,
  gigIri,
  value,
  handleValueChange,
}: Props) {
  return (
    <StyledRow>
      <StyledName>
        {accessUser.firstName} {accessUser.lastName}
        {accessUser.phone ? (
          <div className="phone">{accessUser.phone}</div>
        ) : null}
      </StyledName>
      <Switch
        value={!!partyUser}
        onToggle={() => {
          if (partyUser) {
            handleValueChange(
              value.filter((pu) => pu.accountUser.id !== accessUser.id)
            );
          } else {
            handleValueChange([
              ...value,
              { accountUser: accessUser, gig: gigIri, duty: null },
            ]);
          }
        }}
      />
      <StyledDuty>
        {partyUser && (
          <TextInput
            onChange={(ev) => {
              handleValueChange([
                ...value.filter((pu) => pu.accountUser.id !== accessUser.id),
                { ...partyUser, duty: ev.target.value },
              ]);
            }}
            value={partyUser.duty || ''}
          />
        )}
      </StyledDuty>
    </StyledRow>
  );
}

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
  height: 37px;
`;

const StyledName = styled.div`
  flex: 3;
  color: #444;

  .phone {
    font-size: 13px;
    color: #777;
  }
`;

const StyledDuty = styled.div`
  flex: 5;
  margin-left: 8px;

  input {
    box-sizing: border-box;
    width: 100%;
  }
`;
