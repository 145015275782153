// @flow

import type { V_Gig_Table } from '../../../../models';

import { addMonths, getDate, isAfter, setDate } from 'date-fns';
import * as React from 'react';

import { Color } from '../../../../constants';
import { CircleIcon, HoverPrompt } from '../../components';
import DropdownPreview from './DropdownPreview';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  onClick: () => mixed,
|}>;

export default React.memo<Props>(function TravelCell({ gig, onClick }: Props) {
  let color = '#999';
  let strikethrough = false;

  // Calculate if it should be red (1 month lookahead from 1st and 15th).
  let startDate;
  if (getDate(new Date()) < 15) {
    startDate = setDate(new Date(), 1);
  } else {
    startDate = setDate(new Date(), 15);
  }
  const cutoffDate = addMonths(startDate, 1);
  if (
    ((!gig.inboundTravelNotRequired && !gig.travelOptionsSentIn) ||
      (!gig.outboundTravelNotRequired && !gig.travelOptionsSentOut)) &&
    isAfter(cutoffDate, gig.date)
  ) {
    color = Color.red;
  }

  if (gig.travelOptionsSentIn || gig.travelOptionsSentOut) color = Color.orange;
  if (
    (gig.inboundTravelNotRequired || gig.travelIssuedIn) &&
    (gig.outboundTravelNotRequired || gig.travelIssuedOut)
  )
    color = Color.green;

  if (gig.inboundTravelNotRequired && gig.outboundTravelNotRequired) {
    color = '#999';
    strikethrough = true;
  }

  return (
    <HoverPrompt
      onClick={onClick}
      promptContent={
        <CircleIcon
          color={color}
          iconName="flight"
          strikethrough={strikethrough}
        />
      }
      dropdownContent={<DropdownPreview gigId={gig.id} />}
      dropdownLazy
    />
  );
});
