App.Views.Calender = Backbone.View.extend({

    initialize: function() {
        this.render();
        this.listenTo(App.gigs.filtered, 'reset', this.renderFullCalendar);
        this.listenTo(App.filters, 'change', this.checkPageVisibility);
    },

    checkPageVisibility: function() {
        var isVisible = App.filters.get('layout') === 'calendar';
        this.$el.toggle(isVisible);
        if (isVisible) {
            this.$el.fullCalendar('render');
        }
    },

    render: function() {
        this.$el.fullCalendar({
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,basicWeek',
                ignoreTimezone: false
            },
            selectable: false,
            selectHelper: true,
            editable: false,
            eventRender: function(event, element) {
                element.attr('href', App.config.baseUrl + 'gigs/' + event.id);
                element.addClass('fc-event__act-' + (event.act.id % 59));
                element.find('.fc-content').html('<b>' + event.act.name + '</b><br />' + event.venue.name); 
            }
        });
    },

    renderFullCalendar: function() {
        this.$el.fullCalendar('removeEvents');
        this.$el.fullCalendar('addEventSource', App.gigs.filtered.toJSON());
    }
});