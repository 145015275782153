// @flow

import { linearGradient } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { ZIndex } from '../constants';
import { useDropdown } from '../hooks';
import Dropdown from './Dropdown';
import PlusGlyph from './PlusGlyph';

type Props = $ReadOnly<{|
  dropdownChildren?: React.Node,
|}>;

/**
 * Floating Action Button
 */
export default function Fab({ dropdownChildren }: Props) {
  const { open, triggerEl, handleTriggerClick } = useDropdown();

  return (
    <StyledWrap>
      <StyledButton ref={triggerEl} onClick={handleTriggerClick} open={open}>
        <StyledInner>
          <PlusGlyph width={4} length={22} color="#fff" />
        </StyledInner>
      </StyledButton>

      {dropdownChildren && (
        <StyledAnchor>
          <Dropdown open={open} gravity="up" attachedSide="right">
            {dropdownChildren}
          </Dropdown>
        </StyledAnchor>
      )}
    </StyledWrap>
  );
}

const StyledWrap = styled.div`
  position: relative;
  z-index: ${ZIndex.fab};
`;

const StyledButton = styled.a`
  position: fixed;
  bottom: 30px;
  right: 30px;

  width: 70px;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 2px #4e3a7888 solid;
  user-select: none;
  ${(p) => p.open && `transform: rotate(135deg);`};

  transition: transform 0.4s, opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }

  * {
    pointer-events: none;
  }
`;

const StyledInner = styled.span`
  display: block;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  ${linearGradient({ colorStops: ['#563D77', '#473878'] })};
  color: #fff;
  font-size: 26px;
  font-weight: 400;
`;

const StyledAnchor = styled.div`
  position: fixed;
  bottom: 105px;
  right: 65px;
`;
