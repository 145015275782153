// @flow

import type { TypedFieldProp } from 'react-typed-form';

import * as React from 'react';
import styled from 'styled-components';

import { TextInput } from '../components';

type Props = $ReadOnly<{|
  field: TypedFieldProp<string | null>,
  multiline?: boolean,
  hint?: React.Node,
|}>;

export default function FieldText({ field, ...rest }: Props) {
  return (
    <StyledTextInput
      label={field.label}
      value={field.value || ''}
      onChange={(e) => {
        const initial = e.target.value;
        let value: string | null = initial;
        if (value === '') value = null;
        field.handleValueChange(value);
      }}
      disabled={field.isLoading}
      errors={field.lastErrorList}
      {...rest}
    />
  );
}

const StyledTextInput = styled(TextInput)`
  margin-bottom: 15px;
`;
