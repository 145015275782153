// @flow

import type { AccountUserLead, PartyUser } from '../../../../models';

import * as React from 'react';
import { useForm } from 'react-typed-form';

import { useApiCallable } from '../../../../api';
import {
  ModalMinimalSubmitButton,
  ModalStandardWrap,
} from '../../../../components';
import { FormWrap } from '../../../../form';
import schemas from '../../../../schemas';
import TravellingPartyRow from './TravellingPartyRow';

type FormShape = $ReadOnly<{|
  '@id': string,
  id: number,
  accessUsers: $ReadOnlyArray<AccountUserLead>,
  partyUsers: $ReadOnlyArray<PartyUser>,
|}>;

type Props = $ReadOnly<{|
  data: FormShape,
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
  deleteEntity: (iri: string) => void,
|}>;

export default function TravellingPartyForm({
  data,
  onDismiss,
  upsertEntities,
  deleteEntity,
}: Props) {
  const { callApi } = useApiCallable();

  const { formErrorList, getField, handleSubmit, isLoading } =
    useForm<FormShape>({
      defaultValues: data,
      onSubmit: async (values, form) => {
        form.setLoading(true);

        // Anything to delete?
        const latestIrirs = values.partyUsers
          .map((pu) => pu['@id'])
          .filter(Boolean);
        for (const pu of data.partyUsers) {
          if (!latestIrirs.includes(pu['@id'])) {
            await callApi({
              method: 'DELETE',
              path: `/party_users/${pu['id']}`,
            });
            deleteEntity(pu['@id']);
          }
        }

        // Anything to create/update?
        for (let i = 0; i < values.partyUsers.length; i++) {
          const pu = values.partyUsers[i];

          const old = data.partyUsers.find(
            (oldPu) => oldPu['@id'] === pu['@id']
          );
          if (old?.duty === pu.duty) continue;

          const response = await callApi({
            method: pu['@id'] ? 'PUT' : 'POST',
            path: `/party_users${pu['@id'] ? `/${pu['id']}` : ''}`,
            jsonBody: { ...pu, accountUser: pu.accountUser['@id'] },
          });
          upsertEntities(response.data, schemas.PartyUser);
        }

        // Fetch the latest version of the gig, in the right format, and insert
        // it back into the table
        const response = await callApi({ path: `/gigs/${data.id}/table` });
        upsertEntities(response.data, schemas.Gig);
        onDismiss();
      },
    });

  return (
    <FormWrap formErrorList={formErrorList} handleSubmit={handleSubmit}>
      <ModalStandardWrap>
        {getField('accessUsers')
          .value // $FlowFixMe
          .map((accessUser) => {
            // $FlowFixMe
            const partyUser = getField('partyUsers').value.find(
              (pu) => pu.accountUser.id === accessUser.id
            );
            return (
              <TravellingPartyRow
                key={accessUser.id}
                accessUser={accessUser}
                partyUser={partyUser}
                gigIri={data['@id']}
                // $FlowFixMe
                value={getField('partyUsers').value}
                handleValueChange={getField('partyUsers').handleValueChange}
              />
            );
          })}
      </ModalStandardWrap>
      <ModalMinimalSubmitButton isLoading={isLoading} />
    </FormWrap>
  );
}
