// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Spacer } from '../../../../components';

type Props = $ReadOnly<{|
  value: number | null,
  label: string,
|}>;

export default function SpineTotal({ value, label }: Props) {
  return (
    <StyledMainRow>
      <StyledValue>{value}</StyledValue>
      <Spacer size={0.5} />
      <span>{label}</span>
    </StyledMainRow>
  );
}

const StyledMainRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledValue = styled.div`
  width: 24px;
  padding: 6px 10px;
  border: 0;
  border-radius: 3px;
  background-color: #0001;
  /* box-shadow: 0 1px 3px #0002; */
`;
