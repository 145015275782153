// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = {
  height?: number,
  bullet?: boolean,
  ending?: boolean,
};

export default function SpinePiece({ height, bullet, ending }: Props) {
  return (
    <StyledContainer style={{ height }}>
      <StyledSpine $ending={ending} />
      {bullet && <StyledBullet />}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  width: 16px;
  position: relative;
`;

const StyledSpine = styled<{ $ending: boolean }>('div')`
  background-color: #aaa;
  width: 2px;
  transform: translateX(-1px);
  position: absolute;
  left: 50%;
  top: 0;
  bottom: ${(p) => (p.$ending ? '50%' : 0)};
`;

const StyledBullet = styled.div`
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  transform: translate(-4px, -4px);
  top: 50%;
  left: 50%;
  border: 1px #eee solid;
  border-radius: 50%;
  background-color: #aaa;
  position: absolute;
`;
