// @flow

import type { V_Gig_Hotel } from '../../../../models';

import * as React from 'react';

import { useApiRead } from '../../../../api';
import { WorkflowDropdownPreview } from '../../components';

type Props = $ReadOnly<{|
  gigId: number,
|}>;

export default function DropdownPreview({ gigId }: Props) {
  const api = useApiRead<V_Gig_Hotel>({ path: `/gigs/${gigId}/hotel` });

  const gig = api.data;
  if (api.error) return 'Error!';
  if (!gig) return 'Loading...';

  return (
    <WorkflowDropdownPreview
      rows={
        gig.hotelNtrpBooking
          ? [
              {
                values: [gig.hotelOptionsSent],
                title: 'Options Sent',
              },
              {
                values: [gig.hotelBooked],
                title: 'Hotel Booked',
              },
              {
                values: [gig.hotelAddedToApp],
                title: 'Added To App',
              },
            ]
          : undefined
      }
      notes={gig.hotelNotes}
    />
  );
}
