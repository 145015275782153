// @flow

import type { V_Act_TableDetail } from '../../../../models';

import * as React from 'react';
import styled from 'styled-components';

import { useApiRead } from '../../../../api';
import { Spacer, Spinner } from '../../../../components';
import { actAvatarUrl } from '../../../../image-urls';
import SectionDiaries from './SectionDiaries';
import SectionGeneral from './SectionGeneral';

type Props = $ReadOnly<{|
  actId: number | string,
|}>;

/**
 * If the user has filtered the Table view down to a single Act, then we want
 * to show a detailed section about that artist, with some form controls, above
 * the table.
 */
export default function ActDetail({ actId }: Props) {
  const api = useApiRead<V_Act_TableDetail>({
    path: `/acts/${actId}/table-detail`,
  });

  const act = api.data;
  if (api.error)
    return (
      <StyledContainer>
        <StyledError>Error fetching artist details</StyledError>
      </StyledContainer>
    );
  if (!act)
    return (
      <StyledContainer>
        <Spinner />
      </StyledContainer>
    );

  return (
    <StyledContainer>
      <StyledAvatar
        src={
          act.avatarFilename
            ? actAvatarUrl(act.avatarFilename, 140 * 2)
            : undefined
        }
      />
      <Spacer size={2} />

      <div>
        <StyledName>
          {act.name}
          <span>
            {' '}
            &ndash; {act.leads.map((lead) => lead.firstName).join(', ')}
          </span>
        </StyledName>
        <Spacer />

        <StyledSections>
          <SectionGeneral act={act} invalidate={api.invalidate} />
          <Spacer size={2} />
          <SectionDiaries act={act} invalidate={api.invalidate} />
        </StyledSections>
      </div>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  color: #444;
  font-size: 16px;
  min-height: 120px;
  position: relative;
`;

const StyledError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left; 0;
  width: 100%;
  height: 100%;
  font-size: 18px;
  color: #777;
`;

const StyledAvatar = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #555;
`;

const StyledName = styled.div`
  font-size: 24px;
  font-weight: 300;

  span {
    font-size: 16px;
    font-weight: 400;
    color: #999;
  }
`;

const StyledSections = styled.div`
  display: flex;
  align-items: flex-start;
`;
