// @flow

import type { GigsMinimalEntities } from '../../hooks/entities-store';
import type { Gig } from '../../models';
import type { RootState } from '../../types';

import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { actAvatarUrl } from '../../image-urls';

type Props = $ReadOnly<{|
  gig: Gig,
  entities: GigsMinimalEntities,
|}>;

export default function GigCell({ gig, entities }: Props) {
  const act = useSelector<RootState, _>(
    (state) => state.hardEntities?.Act[gig.act]
  );

  const venue = entities.Venue[gig.venue];
  if (!venue) throw new Error(`Missing venue with IRI: ${gig.venue}`);

  if (!act || !venue) throw new Error('Missing entity');

  let locationPrefix = null;
  if (gig.offDay) locationPrefix = 'Off-Day';
  else if (gig.cancelled)
    locationPrefix = `Cancelled (${gig.cancelledChargeAdvance ? 'C' : 'DC'})`;

  return (
    <StyledContainer to={`/gigs/${gig.id}`}>
      <img
        src={
          act.avatarFilename
            ? actAvatarUrl(act.avatarFilename, 26 * 2)
            : undefined
        }
      />
      <div>
        {act.name}
        <span>
          {locationPrefix && <strong>{locationPrefix}: </strong>}
          {gig.name || venue.name}
        </span>
      </div>
    </StyledContainer>
  );
}

const StyledContainer = styled(Link)`
  display: flex;
  align-items: center;
  border-top: 1px #eee solid;
  padding: 8px 0;
  color: inherit;

  > img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin-right: 6px;
  }

  > div {
    flex: 1;
    line-height: 1.2;

    > span {
      display: block;
      font-size: 12px;
    }
  }
`;
