App.Views.Gigs = Backbone.View.extend({

    initialize: function () {
        this.listenTo(App.filters, 'change', this.checkPageVisibility);
        this.listenTo(App.gigs.filtered, 'reset', this.render);
    },

    checkPageVisibility: function() {
        this.$el.toggle(App.filters.get('layout') === 'grid');
    },

    render: function () {
        var collection = App.gigs.filtered;

        this.$('.js-loading').hide();
        this.$('.js-no-results').hide();
        this.$('.js-gigs').html('');

        if (!collection.loaded) {
            this.$('.js-loading').show();
        } else if (!collection.models.length) {
            this.$('.js-no-results').show();
        } else {
            _.each(collection.models, function (gig) {
                this.renderGig(gig);
            }, this);
        }
    },

    renderGig: function (gig) {
        var gigView = new App.Views.GigBox({
            model: gig
        });
        this.$('.js-gigs').append(gigView.render().el);
    }
});