// @flow

import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { useAccountUser, useHardEntities } from '../hooks';
import { values } from '../util';

type Props = $ReadOnly<{|
  match: {|
    params: {| viewMode?: string |},
  |},
|}>;

export default function GigsRedirectPage({ match }: Props) {
  const { viewMode = 'table' } = match.params;
  const user = useAccountUser();
  const leadValues = values(useHardEntities().AccountUser).filter(Boolean);

  const fallbackLead = leadValues.length ? leadValues[0].id : 'all';

  const id = leadValues.find((lead) => lead.id === user.id)
    ? user.id
    : fallbackLead;

  return <Redirect to={`/gigs/${viewMode}/lead-${id}/act-all/upcoming`} />;
}
