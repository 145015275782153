// @flow

import type { Iri } from '../types';

export type ActHard = $ReadOnly<{|
  '@id': string,
  id: number,
  name: string,
  avatarFilename: string | null,
  leads: $ReadOnlyArray<Iri>,
  techRiderLinkList: string | null,
  hospitalityRiderLinkList: string | null,
  visualsLinkList: string | null,
  sfxLinkList: string | null,
|}>;

export type V_Act_TableDetail = $ReadOnly<{|
  '@id': string,
  '@type': 'Act',
  id: number,
  name: string,
  avatarFilename: string | null,
  accountNumber: string | null,
  agencyDiaryLinkList: string | null,
  agencyDiaries: $ReadOnlyArray<{|
    id: number,
    name: string | null,
    link: string | null,
    crossCheckedAt: string | null,
    crossCheckedBy: {|
      '@id': string,
      '@type': 'AccountUser',
      firstName: string,
    |} | null,
  |}>,
  leads: $ReadOnlyArray<{|
    '@id': string,
    '@type': 'AccountUser',
    firstName: string,
  |}>,
|}>;

export function deserializeLinkList(
  input: string | null
): $ReadOnlyArray<$ReadOnly<{| name: string | null, link: string |}>> {
  if (!input) return [];
  return input
    .split('\n')
    .filter(Boolean)
    .map((s) => {
      const matches = s.trim().match(/(.+) <(.+)>/);
      if (matches) return { name: matches[1], link: matches[2] };
      return { name: null, link: s };
    });
}
