// @flow

import * as React from 'react';

import SplashWrap from './SplashWrap';

export default function AppsPage() {
  return (
    <SplashWrap>
      <a
        className="apps__button"
        href="https://itunes.apple.com/us/app/rooted-touring/id1230397986?ls=1&amp;mt=8"
      >
        <img src="/img/apps/ios.png" />
      </a>

      <a
        className="apps__button"
        href="https://play.google.com/store/apps/details?id=com.rootedtouring"
      >
        <img src="/img/apps/android.png" />
      </a>
    </SplashWrap>
  );
}
