// @flow

import type {
  AccountUserLead,
  ActHard,
  AdvancingEmailVGigTable,
  ArtistSetVGigTable,
  DayNote,
  Gig,
  PartyUser,
  V_Gig_Table,
  VenueVGigMinimal,
  VenueVGigTable,
} from '../models';
import type { Entities } from '../types';

import { normalize } from 'normalizr';
import * as React from 'react';

import { useApiCallable } from '../api';
import { SERVER_ROOT } from '../config';
import schemas, { iriPartToType } from '../schemas';
import { snakeToCamel, transformKeysRecursive } from '../util';

export type GigEntities = $ReadOnly<{
  Gig: Entities<Gig>,
  ...
}>;

export type GigsMinimalEntities = $ReadOnly<{
  Gig: Entities<Gig>,
  Venue: Entities<VenueVGigMinimal>,
  ...
}>;

export type GigTableEntities = $ReadOnly<{
  AccountUser: Entities<AccountUserLead>,
  Act: Entities<ActHard>, // use hard entities instead, this just catches leftovers
  AdvancingEmail: Entities<AdvancingEmailVGigTable>,
  ArtistSet: Entities<ArtistSetVGigTable>,
  Gig: Entities<V_Gig_Table>,
  PartyUser: Entities<PartyUser>,
  Venue: Entities<VenueVGigTable>,
  ...
}>;

export type GigTableDayNotesEntities = $ReadOnly<{
  DayNote: Entities<DayNote>,
  ...
}>;

// Union of all possible types allowed, to help the generics
type AllEntityTypes =
  | GigEntities
  | GigsMinimalEntities
  | GigTableEntities
  | GigTableDayNotesEntities;

type ApiAction<T> =
  /**
   * Payload is just the IRI of the entity to delete
   */
  | {| type: 'DELETE', payload: string |}
  | {| type: 'MERGE', payload: T |}
  | {| type: 'SYNC_REQUEST' |}
  | {| type: 'SYNC_SUCCESS', payload: T |};

type ApiState<T> = $ReadOnly<{|
  isLoading: boolean,
  entities: T | null,
  error: string | null,
|}>;

// eslint-disable-next-line flowtype/no-weak-types
const initialState: ApiState<any> = {
  isLoading: false,
  entities: null,
  error: null,
};

const initialEntitiesState = {
  AccountUser: {},
  Act: {},
  AdvancingEmail: {},
  ArtistSet: {},
  DayNote: {},
  Gig: {},
  PartyUser: {},
  Venue: {},
};

function reducer<T: AllEntityTypes>(
  state: ApiState<T> = initialState,
  action: ApiAction<T>
): ApiState<T> {
  switch (action.type) {
    case 'SYNC_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'SYNC_SUCCESS':
      return {
        ...state,
        isLoading: false,
        // $FlowFixMe
        entities: {
          ...initialEntitiesState,
          ...action.payload,
        },
      };
    case 'DELETE': {
      // Determine which entityType we want to be looking in
      const iriParts = action.payload.match(/\/([a-z_-]+)\/\d+$/i);
      if (!iriParts)
        throw new Error(`Unknown IRI structure: ${action.payload}`);
      const entityType = iriPartToType(iriParts[1]);

      const { entities } = state;
      if (!entities) return state;

      const result = {
        ...state,
        entities: {
          ...entities,
          [entityType]: {
            ...Object.fromEntries(
              Object.entries(entities[entityType]).filter(
                ([key]) => key !== action.payload
              )
            ),
          },
        },
      };
      // $FlowFixMe
      return result;
    }
    case 'MERGE': {
      const { entities } = state;
      if (!entities) return state;

      // Perform a deep immutable edit of the entities state.
      return {
        ...state,
        // $FlowFixMe
        entities: {
          ...entities,
          ...Object.fromEntries(
            // For each group of entities in the normalized response
            // (ArtistSet, Gig, PartyUser, etc)
            Object.entries(action.payload)
              // Filter out empty keys which get inserted into the
              // normalization context
              // $FlowFixMe
              .filter(([, groupValue]) => Object.keys(groupValue).length > 0)
              .map(([groupKey, groupValue]) => [
                groupKey,
                {
                  ...entities[groupKey],
                  ...Object.fromEntries(
                    // For each entity in the group
                    // $FlowFixMe
                    Object.entries(groupValue).map(
                      ([entityKey, entityValue]) => [
                        entityKey,
                        // Do a merge of the CONTENTS of every entity, as the
                        // serialization context from this response might be
                        // different, so some fields might have been omitted.
                        {
                          ...(entities[groupKey][entityKey] || {}),
                          ...entityValue,
                        },
                      ]
                    )
                  ),
                },
              ])
          ),
        },
      };
    }
    default:
      return state;
  }
}

export default function useEntitiesStore<T: AllEntityTypes>(
  path: string,
  schema: mixed,
  options: $ReadOnly<{|
    /**
     * Limit what new entities can be added to the store by filtering every
     * item before it gets added (usually replicates filters that would appear
     * on the server, against unfiltered data appearing in Mercure)
     *
     * NB: This should definitely be memoized for performance reasons!
     */
    filterEntry?: $ReadOnly<{ [type: string]: (entity: T) => boolean }>,
    /**
     * Which Mercure topics should this store listen to entity events from?
     *
     * NB: This should definitely be memoized for performance reasons!
     */
    topicSubscriptions?: $ReadOnlyArray<string>,
  |}> = { ...null }
): $ReadOnly<{|
  entities: T | null,
  isLoading: boolean,
  refresh: () => void,
  upsertEntities: (responseData: {}, schema: mixed) => void,
  deleteEntity: (iri: string) => void,
|}> {
  const { filterEntry, topicSubscriptions } = options;
  const [state, dispatch] = React.useReducer<ApiState<T>, ApiAction<T>>(
    reducer,
    initialState
  );

  const { callApi, abortIfInFlight } = useApiCallable();

  // Fetch the initial data
  React.useEffect(() => {
    async function fetchEntities() {
      abortIfInFlight();
      dispatch({ type: 'SYNC_REQUEST' });
      try {
        const { data } = await callApi({ path });
        dispatch({
          type: 'SYNC_SUCCESS',
          payload: normalize(transformKeysRecursive(data, snakeToCamel), schema)
            .entities,
        });
      } catch (err) {
        if (err.name === 'AbortError') {
          // Successfully aborted
          return;
        }
        throw err;
      }
    }
    fetchEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callApi, path]);

  // Subscribe to updates with Mercure
  const [eventSource, setEventSource] = React.useState<EventSource | null>(
    null
  );
  React.useEffect(() => {
    const url = new URL(
      'https://rooted-mercure.herokuapp.com/.well-known/mercure'
    );
    // For some reason API Platform publishes events without the https,
    // probably to do with Heroku load balancing.
    const mercureDomain = SERVER_ROOT.replace('https://', 'http://');
    topicSubscriptions?.forEach((sub) => {
      url.searchParams.append('topic', `${mercureDomain}/api-platform${sub}`);
    });
    setEventSource(
      topicSubscriptions?.length ? new EventSource(url.toString()) : null
    );

    return () => {
      eventSource?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicSubscriptions]);
  React.useEffect(() => {
    eventSource &&
      (eventSource.onmessage = (ev) => {
        // $FlowFixMe
        const eventData = JSON.parse(ev.data);

        function normalizedEntities() {
          return normalize(
            transformKeysRecursive(eventData, snakeToCamel),
            schemas[eventData['@type']]
          ).entities;
        }

        if (!eventData['@id'])
          // $FlowFixMe
          throw new Error(`Unexpected event data: ${ev.data}`);
        // Wait until initial data has been fetched
        if (!state.entities) return;

        if (!eventData['@type']) {
          // DELETE
          dispatch({ type: 'DELETE', payload: eventData['@id'] });
        } else if (
          !Object.keys(state.entities[eventData['@type']]).includes(
            eventData['@id']
          )
        ) {
          // CREATE (or ignore if outside of filter)
          const payload = normalizedEntities();
          const type = eventData['@type'];
          const entity = payload[type][eventData['@id']];
          if (
            !(filterEntry && filterEntry[type] && !filterEntry[type](entity))
          ) {
            dispatch({ type: 'MERGE', payload });
          }
        } else {
          // UPDATE
          dispatch({ type: 'MERGE', payload: normalizedEntities() });
        }
      });
  }, [filterEntry, state.entities, eventSource]);

  const upsertEntities = React.useCallback(
    (responseData, replaceSchema) => {
      const payload = normalize(
        transformKeysRecursive(responseData, snakeToCamel),
        replaceSchema
      ).entities;

      // If this is a single entity, and the user has applied an entry filter,
      // see if we should skip putting it into the state.
      // $FlowFixMe
      const type = responseData['@type'];
      if (type) {
        // $FlowFixMe
        const entity = payload[type][responseData['@id']];
        if (filterEntry && filterEntry[type] && !filterEntry[type](entity)) {
          return;
        }
      }

      dispatch({ type: 'MERGE', payload });
    },
    [filterEntry]
  );

  const deleteEntity = React.useCallback((iri) => {
    dispatch({
      type: 'DELETE',
      payload: iri,
    });
  }, []);

  return {
    entities: state.entities,
    isLoading: state.isLoading,
    refresh: () => {}, // TODO?
    upsertEntities,
    deleteEntity,
  };
}
