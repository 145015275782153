App.Views.Party = Backbone.View.extend({

    template: '#template-party',

    events: {
        'change .js-travelling': 'onTravellingToggled',
        'blur .js-field': 'onDutyChanged'
    },

    initialize: function() {
        this.listenTo( this.model.get('party_users'), 'add remove', this.render );
    },

    serializeData: function() {
        var users = [];

        this.model.get('access_users').each($.proxy(function(user) {
            var data = { accessUser: user.toJSON() };
            var partyUser = this.model.get('party_users')
                .findWhere({ account_user: user });
            if (partyUser) {
                data.partyUser = partyUser.toJSON();
            }
            users.push(data);
        }, this));

        return {
            users: users
        }
    },

    render: function() {
        var template = App.Helpers.getTemplate(this.template);
        this.$el.html( template(this.serializeData()) );
        return this;
    },

    onTravellingToggled: function(e) {
        var $target = $(e.currentTarget);
        var isChecked = $target.is(':checked');
        var userId = parseInt($target.parent().parent().data('user-id'));

        var accessUser = this.model.get('access_users').get(userId);
        var partyUser = this.model.get('party_users')
            .findWhere({ account_user: accessUser });

        if (isChecked && !partyUser) {
            // Create partyUser
            this.model.get('party_users').create({
                gig: this.model,
                account_user: accessUser
            });
        } else if (!isChecked && partyUser) {
            // Delete partyUser
            this.model.get('party_users').remove(partyUser);
            partyUser.destroy();
        }
    },

    onDutyChanged: function(e) {
        var $target = $(e.currentTarget);
        var field = $target.data('field');
        var value = $target.val();
        var userId = parseInt($target.parent().parent().data('user-id'));

        var accessUser = this.model.get('access_users').get(userId);
        var partyUser = this.model.get('party_users')
            .findWhere({ account_user: accessUser });

        if (partyUser) {
            var data = {};
            data[field] = value;
            partyUser.save(data, { patch: true });
        }
    }

});