// @flow

import * as React from 'react';

import SimpleWrap from './SimpleWrap';

export default function HelpPage() {
  return (
    <SimpleWrap>
      <p>Any questions or problems can be reported to:</p>

      <p>
        <strong>James Isaac</strong>
        <br />
        <a href="mailto:james.isaac@gmail.com">james.isaac@gmail.com</a>
      </p>

      <hr />

      <h3>Artist app</h3>
      <ul>
        <li>
          <a href="#app-download">Downloading the artist app</a>
        </li>
        <li>
          <a href="#app-sync">The app is showing out-of-date information</a>
        </li>
        <li>
          <a href="#app-crash">The app is crashing</a>
        </li>
      </ul>

      <h3>Back-office system</h3>
      <ul>
        <li>
          <a href="#web-admin">
            Difference between the main website and the admin area
          </a>
        </li>
        <li>
          <a href="#web-address">Creating venues and hotels with addresses</a>
        </li>
        <li>
          <a href="#web-autopopulate">Auto-populating flights</a>
        </li>
      </ul>

      <hr />

      <a name="app-download"></a>
      <h2>Downloading the artist app</h2>
      <p>
        Instructions for downloading the new artist app, while we complete the
        App Store approval process, can be found{' '}
        <a href="{{ path('apps_expo') }}">here</a>.
      </p>

      <a name="app-sync"></a>
      <h2>The app is showing out-of-date information</h2>
      <p>
        The app will automatically sync the latest data in the background as
        you&apos;re using it, but there are additional ways you can force a sync
        if you need the latest data right away.
      </p>

      <p>
        From any screen, you can swipe downwards to initiate a sync. You should
        see a spinning wheel appear as you swipe down from the top of the
        screen.
      </p>

      <p>
        If this doesn&apos;t help, or you are having some other problem, the
        Settings tab (bottom right of the screen) contains information about the
        last sync, and a button to force a sync. If the &quot;last synced&quot;
        time is not updating, there will be some issue (e.g. network) preventing
        syncing.
      </p>

      <a name="app-crash"></a>
      <h2>The app is crashing</h2>
      <p>
        Try removing the Expo Client app from your phone, and following the{' '}
        <a href="{{ path('apps_expo') }}">installation instructions</a> again to
        start from a clean install.
      </p>

      <p>If the problem persists, please contact us.</p>

      <a name="web-admin"></a>
      <h2>Difference between the main website and the admin area</h2>
      <p>
        Any user, and promoters with a token link, can access the main website
        and edit gigs. Only users who have been set as Admins will see a special{' '}
        <strong>ADMIN</strong> link at the top of the page, which lets them
        access a new section where they can control additional things like
        users, gigs, and venues.
      </p>

      <a name="web-address"></a>
      <h2>Creating venues and hotels with addresses</h2>
      <p>
        For maps and other features to work throughout the app, all hotels and
        venues should have a proper address, which Google Maps is able to
        pin-point to an exact location.
      </p>

      <p>
        In the admin area, you will see that the venue and hotel forms have an
        attached Address section, which requires values for Latitude and
        Longitude (and sometimes Timezone). You can let Google auto-calculate
        these values for you based on the address information you have provided
        (Street, City, Region, Post Code, Country) by clicking the blue link:
      </p>

      <img src="/img/help/latlng.png" />

      <p>
        If the values don&apos;t get filled in automatically, it means Google
        wasn&apos;t able to determine the address. Try looking it up in Google
        Maps, and see if it suggests an alternative.
      </p>

      <a name="web-autopopulate"></a>
      <h2>Auto-populating flights</h2>
      <p>
        When adding a flight to the schedule, you can click the{' '}
        <strong>Populate</strong> button to have it fill in as many details as
        it can from a flight lookup.
      </p>

      <p>
        This is based on three fields, each marked with a{' '}
        <span
          style={{
            'font-weight': 'bold',
            background: 'rgba(125,190,100,0.75)',
            color: '#fff',
            padding: '0 1px',
          }}
        >
          *
        </span>
        : <strong>Airline</strong>, <strong>Flight number</strong> and{' '}
        <strong>Departure date</strong>.
      </p>

      <p>
        This will overwrite any values you manually entered into those fields.
        But after populating, you can go back over and edit anything you like.
      </p>

      <p>
        This system may occasionally get caught out, e.g. when 2 flights share
        the same flight code on the same day.
      </p>
    </SimpleWrap>
  );
}
