// @flow

import type { GigTableEntities } from '../../../../hooks/entities-store';
import type { V_Gig_Table } from '../../../../models';

import * as React from 'react';
import styled from 'styled-components';

import { Dropdown, TableCell } from '../../../../components';
import { useDropdown } from '../../../../hooks';
import { values } from '../../../../util';
import { AddPrompt } from '../../components';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  entities: GigTableEntities,
|}>;

export default function SetTimesCell({ gig, entities }: Props) {
  const focusSet = values(entities.ArtistSet).find(
    (as) => as?.gig === gig['@id'] && as?.focus
  );
  let setTimesMatch = null;
  if (focusSet && (gig.contractSetStartAt || gig.contractSetFinishAt)) {
    if (gig.contractSetStartAt && gig.contractSetStartAt !== focusSet.startAt) {
      setTimesMatch = false;
    } else if (
      gig.contractSetFinishAt &&
      gig.contractSetFinishAt !== focusSet.finishAt
    ) {
      setTimesMatch = false;
    } else {
      setTimesMatch = true;
    }
  }

  const { open, triggerEl, handleTriggerClick } = useDropdown();

  return (
    <TableCell>
      {gig.contractSetStartAt || gig.contractSetFinishAt ? (
        <>
          {gig.contractSetStartAt} - {gig.contractSetFinishAt}
          {setTimesMatch === true && 'YES'}
          {setTimesMatch === false && 'NO'}
        </>
      ) : (
        <AddPrompt ref={triggerEl} onClick={handleTriggerClick} />
      )}
      <StyledAnchor>
        <Dropdown open={open}>Test</Dropdown>
      </StyledAnchor>
    </TableCell>
  );
}

const StyledAnchor = styled.div`
  position: absolute;
  bottom: 8px;
  left: 34px;
`;
