App.Collections.GigsFiltered = Backbone.Collection.extend({

    model: App.Models.Gig,

    filters: null,

    // Is it loaded yet, or still waiting on data?
    loaded: false,

    initialize: function(options) {
        this.filters = options.filters;
    },

    // Past gigs should be in descending date order, upcoming in ascending
    comparator: function(gig) {
        var stamp = parseInt(moment(gig.get('date')).format('YYYYMMDD00000')),
            focusArtistSet = gig.findFocusArtistSet();
        if (focusArtistSet) {
            stamp += focusArtistSet.getOrdinal();
        }
        return this.past ? (stamp * (-1)) : stamp;
    }

});