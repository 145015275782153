'use strict';

App.Models.Base = Backbone.RelationalModel.extend({

    // Get pointless stuff out of API responses from server
    parse: function(resp, options) {
        // 2019 mapping of IRIs back to IDs
        if (this.iriFields) {
            App.Helpers.stripIris(resp, this.iriFields);
        }

        // Exclude updatedAt / createdAt when syncing, as they aren't used by the
        // web app, yet cause a changed event to fire and usually a re-render.
        delete resp.created_at;
        delete resp.updated_at;

        // Nested gig is never used and seems to cause trackit to go insane.
        delete resp.gig;

        // todo: when gig is updated, all the nested objects go crazy, but need
        // these for the original reset.  Think of a way to factor this out.

        return resp;
    },

    toJSON: function(options = {}) {
        const json = Backbone.RelationalModel.prototype.toJSON.call(this, options);
        if (this.iriFields && options.fromSync) {
            return App.Helpers.mapIris(json, this.iriFields);
        }
        return json;
    },

});