// @flow

import type { V_Gig_Table } from '../../../../models';

import * as React from 'react';
import styled from 'styled-components';

import { Dropdown } from '../../../../components';
import CellPart from './CellPart';
import DropdownPreview from './DropdownPreview';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  onClick: () => mixed,
|}>;

export default function DealCell({ gig, onClick }: Props) {
  const [hover, setHover] = React.useState(false);

  return (
    <StyledContainer>
      <StyledTrigger
        onClick={onClick}
        onMouseOut={() => setHover(false)}
        onMouseOver={() => setHover(true)}
      >
        <CellPart part="travel" value={gig.dealTravel} />
        <CellPart part="hotel" value={gig.dealHotel} />
        <CellPart part="ground" value={gig.dealGround} />
      </StyledTrigger>
      {hover &&
        !(
          gig.dealTravel === null &&
          gig.dealHotel === null &&
          gig.dealGround === null
        ) && (
          <StyledAnchor>
            <Dropdown open={hover} attachedSide="left">
              <DropdownPreview gigId={gig.id} />
            </Dropdown>
          </StyledAnchor>
        )}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  position: relative;
  margin-left: -5px;
`;

const StyledTrigger = styled.a`
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: background-color 0.25s;

  &:hover {
    background-color: #efecf3;
  }

  * {
    pointer-events: none;
  }
`;

const StyledAnchor = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0px;
  left: 30px;
`;
