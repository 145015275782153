// @flow

import type { ActHard } from '../../../models';
import type { TableRowBackgroundColor } from '../../../types';
import type { ItinStatus } from '../itin-statuses';

import { format } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';

import { TableCell } from '../../../components';
import { actAvatarUrl } from '../../../image-urls';

type Props = $ReadOnly<{|
  act: ActHard,
  date: string,
  gigLink?: string,
  statusText?: string | null,
  itinStatus?: ItinStatus,
  backgroundColor: TableRowBackgroundColor,
|}>;

export default React.memo<Props>(function RowStandardLeft({
  act,
  date,
  gigLink,
  statusText,
  itinStatus,
  backgroundColor,
}: Props) {
  // const allLeads = useHardEntities().AccountUser;

  return (
    <>
      <StyledAvatarCell
        to={gigLink}
        first
        noPadding
        backgroundColor={backgroundColor}
        itinStatus={itinStatus}
      >
        <div>
          <img
            src={
              act.avatarFilename
                ? actAvatarUrl(act.avatarFilename, 38 * 2)
                : undefined
            }
          />
        </div>
      </StyledAvatarCell>
      <TableCell to={gigLink} backgroundColor={backgroundColor} width={160}>
        {act.name}
      </TableCell>
      <TableCell
        to={gigLink}
        gap="right"
        backgroundColor={backgroundColor}
        width={150}
      >
        <div>{format(date, 'ddd D MMM YYYY')}</div>
        {statusText && <div className="detail">{statusText}</div>}
      </TableCell>
      {/* Leads now shown in ActDetailTop */}
      {/* <TableCell
        to={gigLink}
        gap="right"
        backgroundColor={backgroundColor}
        width={170}
      >
        {act.leads
          .map(leadIri => {
            const lead = allLeads[leadIri];
            if (!lead) throw new Error(`Missing lead: ${leadIri}`);
            return lead.firstName;
          })
          .join(', ')}
        </TableCell> */}
    </>
  );
});

const StyledAvatarCell = styled(TableCell)`
  align-items: center;

  div {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: 2px #bbb solid;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(p) => p.itinStatus === 'itin_sent' && `border-color: #01d9a3`};

    img {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      background-color: #555;
    }
  }
`;
