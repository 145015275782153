// @flow strict

import type { Action } from '../actions';

export type TempOptionsState = $ReadOnly<{|
  /**
   * Remember the last path (mode+filters) the user was on when viewing gigs,
   * so they can be taken back to the same path later.
   */
  lastGigsPath: string | null,
|}>;

const initialState = {
  lastGigsPath: null,
};

export default function tempOptions(
  state: TempOptionsState = initialState,
  action: Action
): TempOptionsState {
  switch (action.type) {
    case 'SET_LAST_GIGS_PATH':
      return { ...state, lastGigsPath: action.payload };
    default:
      return state;
  }
}
