// @flow

import * as config from './config';

export function actAvatarUrl(avatarFilename: string, size: number) {
  return `https://rootedtouring-${config.APP_ENV}.imgix.net/ntrp/act_avatars/${avatarFilename}?fit=crop&w=${size}&h=${size}&fm=jpg`;
}

export function venueImageUrl(
  imageFilename: string,
  options: $ReadOnly<{| w: number, h: number |}>
) {
  return `https://rootedtouring-${config.APP_ENV}.imgix.net/ntrp/venue_images/${imageFilename}?fit=crop&w=${options.w}&h=${options.h}&fm=jpg`;
}
