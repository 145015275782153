// @flow

import { transparentize } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

import { Icon } from '../../../components';

type Props = $ReadOnly<{|
  color: string,
  iconName?: string,
  letter?: string,
  strikethrough?: boolean,
|}>;

export default React.memo<Props>(function CircleIcon({
  color,
  iconName,
  letter,
  strikethrough,
}: Props) {
  return (
    <StyledOuter
      $color={color}
      $strikethrough={strikethrough}
      style={{ borderColor: transparentize(0.4, color) }}
    >
      <StyledInner style={{ backgroundColor: color }}>
        {letter && <StyledLetter $color="#fff">{letter}</StyledLetter>}
        {iconName && <Icon name={iconName} size={18} color="#fff" />}
      </StyledInner>
    </StyledOuter>
  );
});

const StyledOuter = styled.div`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 2px #000 solid;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.25s;

  ${(p) =>
    p.$strikethrough &&
    `
      &::after {
        content: '';
        width: 3px;
        position: absolute;
        top: -4px;
        left: 50%;
        bottom: -4px;
        background-color: ${p.$color};
        transform: rotate(45deg);
      }
    `}
`;

const StyledInner = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px #fff solid;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLetter = styled.span`
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: ${(p) => p.$color};
`;
