App.Views.Trip = App.Views.BaseItem.extend({

    tagName: 'tbody',
    editTemplate: '#template-trip',

    initialize: function(options) {
        this.options = options;
        this.buildEvents();
        this.model.startTracking();
    },

    serializeData: function() {
        var data = this.model.toJSON();
        data.account_user = this.model.get('party_user').get('account_user').toJSON();
        return data;
    },

    onFieldChanged: function() {
        App.Views.BaseItem.prototype.onFieldChanged.apply(this, arguments);
        this.options.scheduleItemView.enableSave();
    }

});