// @flow

import type { TypedFieldProp } from 'react-typed-form';

import * as React from 'react';
import styled from 'styled-components';

type Choice = $ReadOnly<{|
  value: mixed,
  label: string,
|}>;

type Props = $ReadOnly<{|
  field: TypedFieldProp<mixed>,
  choices: $ReadOnlyArray<Choice>,
  disabled?: boolean,
|}>;

export default function FieldSelect({ field, choices, disabled }: Props) {
  const currentChoice = choices.find((c) => c.value === field.value);

  return (
    <StyledContainer>
      <StyledLabel>{field.label}</StyledLabel>
      <StyledSelect
        value={currentChoice && currentChoice.label}
        onChange={(e) => {
          const label = e.target.value;
          const choice = choices.find((c) => c.label === label);
          choice && field.handleValueChange(choice.value);
        }}
        disabled={disabled}
      >
        {choices.map((choice) => (
          <option key={choice.label} value={choice.label}>
            {choice.label}
          </option>
        ))}
      </StyledSelect>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  margin-bottom: 15px;
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 3px;
`;

const StyledSelect = styled.select`
  padding: 4px 8px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) inset;
  border: 1px #aaa solid;
`;
