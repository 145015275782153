// @flow

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

type Props = $ReadOnly<{|
  children: React.Node,
|}>;

export default function SplashWrap({ children }: Props) {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/css/legacy-splash.css" />
      </Helmet>
      <div className="splash__top">
        <div className="splash__top-item splash__top-item--left">
          <Link to="/">Home</Link>
        </div>
        <div className="splash__top-item splash__top-item--left">
          <Link to="/apps">Apps</Link>
        </div>

        <div className="splash__top-item splash__top-item--desktop-only">
          <span>Tel:</span>
          +44 (0)20 8068 0315
        </div>
        <div className="splash__top-item splash__top-item--desktop-only">
          <span>Address:</span>
          54 Holywell Lane, Village Underground, London
        </div>
        <div className="splash__top-item splash__top-item--desktop-only">
          <span>Email:</span>
          <a
            href="mailto:info@rootedtouring.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@rootedtouring.com
          </a>
        </div>
      </div>
      <div className="splash__wrapper">
        <div className="splash__inner">{children}</div>
      </div>
    </>
  );
}
