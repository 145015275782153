// @flow

import type { TypedFieldProp } from 'react-typed-form';

import * as React from 'react';
import styled from 'styled-components';

import { ErrorList } from '../../../../components';

type Props = $ReadOnly<{|
  field: TypedFieldProp<string | null>,
|}>;

/**
 * Not using FieldModalTextarea because this is split into 3 cols so just
 * generally has different constraints.
 */
export default function FieldNotes({ field }: Props) {
  return (
    <div>
      <StyledTextarea
        placeholder="Add notes…"
        value={field.value || ''}
        onChange={(e) => field.handleValueChange(e.target.value || null)}
        disabled={field.isLoading}
      />
      {field.lastErrorList && (
        <StyledAreaErrors>
          <ErrorList errors={field.lastErrorList} />
        </StyledAreaErrors>
      )}
    </div>
  );
}

const StyledTextarea = styled.textarea`
  resize: none;
  box-sizing: border-box;
  width: 100%;
  border: 0;
  padding: 12px;
  height: 120px;
  font-size: 16px;
  display: block;
  ::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const StyledAreaErrors = styled.div`
  background-color: #f2f2f2;
  padding: 8px 12px 0;
`;
