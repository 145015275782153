App.Routers.Tabs = Backbone.Router.extend({
    routes: {
        ":tab": "selectTab"
    },

    // For guarding unsaved changes
    lastHash: '',

    selectTab: function (name) {
        App.gigController.showTab(name);
        App.vent.trigger('tab:changed', name);
    },

    execute: function(callback, args) {
        // If the user wants to guard their unsaved changes, abort
        if (this.guardUnsaved()) { return; }

        // Carry out normal execution of the route
        if (callback) callback.apply(this, args);

        // The page has changed, so update the last hash
        this.lastHash = window.location.hash;
    },

    guardUnsaved: function() {
        // If there are no queued unsaved changes, guarding is not needed
        if (App.getUnsaved() === null) { return false; }

        // There are unsaved changes, check if they don't mind losing them
        if (confirm('You have unsaved changes on this page.  Are you sure you want to navigate away?')) {
            App.getUnsaved().resetAttributes();
            App.resetUnsaved();
            return false;
        }

        // They want to keep their unsaved changes
        // Flick the URL back to the previous page
        this.navigate(this.lastHash, { trigger:false, replace: true });
        return true;
    }
});