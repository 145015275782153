// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  name: string,
  color?: string,
  size?: number,
  xOffset?: number,
  yOffset?: number,
|}>;

function Icon({ name, color, size, xOffset, yOffset }: Props) {
  return (
    <StyledI
      className={`icon-${name}`}
      style={{ color, fontSize: size, left: xOffset, top: yOffset }}
    />
  );
}

export default React.memo<Props>(Icon);

const StyledI = styled.i`
  position: relative;
  line-height: 1;
`;
