// @flow

import * as React from 'react';
import styled, { keyframes } from 'styled-components';

type Props = $ReadOnly<{|
  message?: string,
  noBars?: boolean, // Used on very brief first loading screen
|}>;

export default function Spinner({ message, noBars }: Props) {
  const bars = new Array(10).fill(1);
  return (
    <StyledWrap>
      {!noBars && (
        <StyledBars>
          {bars.map((bar, i) => (
            <StyledBar key={i} i={i} />
          ))}
        </StyledBars>
      )}
      {message && <StyledMessage>{message}</StyledMessage>}
    </StyledWrap>
  );
}

const StyledWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -25px;
  margin-left: -65px;
`;

const StyledBars = styled.div`
  height: 50px;
  width: 130px;
`;

const animate = keyframes`
  50% { transform: scaleY(0); }
`;

const StyledBar = styled.div`
  width: 8px;
  height: 50px;
  margin-right: 5px;
  background-color: #9d8db3; /* #871376; */
  animation: ${animate} 1s infinite;
  float: left;
  animation-delay: ${(p) => p.i / 10}s;
`;

const StyledMessage = styled.div`
  text-align: center;
  margin-top: 15px;
  color: #777;
`;
