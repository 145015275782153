// @flow

import type {
  GigTableDayNotesEntities,
  GigTableEntities,
} from '../../hooks/entities-store';
import type { DayNote, V_Gig_Table } from '../../models';
import type { ItemModalState } from './types';

import { differenceInCalendarDays } from 'date-fns';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import {
  Fab,
  GigsSubHeader,
  PageLoading,
  PageWrap,
  Spacer,
  TableGroup,
} from '../../components';
import DropdownSelectItem from '../../components/DropdownSelectItem';
import { ZIndex } from '../../constants';
import {
  useEntitiesStore,
  useHardEntities,
  useLastGigsPath,
  useVirtualizedTable,
} from '../../hooks';
import { gigsFilterEntry, gigsFilterUrlParams } from '../../models/Gig';
import schemas from '../../schemas';
import { compareGigsOrDayNotes, values } from '../../util';
import { GigRow, NotesModal } from './components';
import * as ActDetail from './pods/act-detail';
import * as Advancing from './pods/advancing';
import * as Create from './pods/create';
import * as DayNotes from './pods/day-notes';
import * as Deal from './pods/deal';
import * as Hotel from './pods/hotel';
import * as More from './pods/more';
import * as SetTimes from './pods/set-times';
import * as Travel from './pods/travel';
import * as TravellingParty from './pods/travelling-party';

type Props = $ReadOnly<{|
  match: {|
    params: {| leadFilter: string, actFilter: string, periodFilter: string |},
  |},
|}>;

type CreateModalState = null | 'Gig' | 'DayNote';

export default React.memo<Props>(function GigsTablePage({ match }: Props) {
  const { leadFilter, actFilter, periodFilter } = match.params;
  useLastGigsPath();

  const hardActs = useHardEntities().Act;
  const { shouldRenderRow } = useVirtualizedTable();

  const { entities, isLoading, upsertEntities, deleteEntity } =
    useEntitiesStore<GigTableEntities>(
      `/gigs/table?${gigsFilterUrlParams(leadFilter, actFilter, periodFilter)}`,
      [schemas.Gig],
      {
        filterEntry: React.useMemo(
          () => ({
            Gig: (entity) => {
              return gigsFilterEntry(
                // eslint-disable-next-line flowtype/no-weak-types
                ((entity: any): V_Gig_Table),
                hardActs,
                leadFilter,
                actFilter,
                periodFilter
              );
            },
          }),
          [hardActs, leadFilter, actFilter, periodFilter]
        ),
        topicSubscriptions: React.useMemo(
          () => ['/gigs/{id}', '/party_users/{id}', '/advancing_emails/{id}'],
          []
        ),
      }
    );

  const {
    entities: dayNotesEntities,
    isLoading: dayNotesIsLoading,
    upsertEntities: dayNotesUpsertEntities,
    deleteEntity: dayNotesDeleteEntity,
  } = useEntitiesStore<GigTableDayNotesEntities>(
    `/day_notes?${gigsFilterUrlParams(leadFilter, actFilter, periodFilter)}`,
    [schemas.DayNote],
    {
      filterEntry: React.useMemo(
        () => ({
          DayNote: (entity) => {
            return gigsFilterEntry(
              // eslint-disable-next-line flowtype/no-weak-types
              ((entity: any): V_Gig_Table),
              hardActs,
              leadFilter,
              actFilter,
              periodFilter
            );
          },
        }),
        [hardActs, leadFilter, actFilter, periodFilter]
      ),
      topicSubscriptions: React.useMemo(() => ['/day_notes/{id}'], []),
    }
  );

  const sortedEntities: null | $ReadOnlyArray<DayNote | V_Gig_Table> =
    React.useMemo(
      () =>
        entities && dayNotesEntities
          ? [...values(entities.Gig), ...values(dayNotesEntities.DayNote)]
              .filter(Boolean)
              .sort(compareGigsOrDayNotes)
          : null,
      [entities, dayNotesEntities]
    );

  // Group the gigs into nested arrays, so that "runs" of gigs on consecutive
  // days can appear physically close together
  const groupedEntities = React.useMemo(() => {
    if (!sortedEntities) return null;

    let lastDate = null;
    let currentGroup = null;
    const groups = [];

    sortedEntities.forEach((entity) => {
      if (
        !currentGroup ||
        !lastDate ||
        actFilter === 'all' ||
        differenceInCalendarDays(entity.date, lastDate) > 1
      ) {
        currentGroup = [];
        groups.push(currentGroup);
      }
      currentGroup.push(entity);
      lastDate = entity.date;
    });

    return groups;
  }, [sortedEntities, actFilter]);

  const [createModal, setCreateModal] = React.useState<CreateModalState>(null);
  // If a notes modal is on-screen, tracks a tuple of which field it's showing,
  // and what entity ID that belongs to
  const [itemModal, setItemModal] = React.useState<ItemModalState>(null);
  const clearModal = React.useCallback(() => setItemModal(null), []);

  let itemModalRendered = null;
  if (itemModal && sortedEntities) {
    if (itemModal[0] === 'DayNote' && dayNotesEntities) {
      // $FlowFixMe
      const itemModalDayNote: ?DayNote = sortedEntities
        .filter((e) => e['@type'] === 'DayNote')
        .find((e) => e.id === itemModal[2]);

      if (!itemModalDayNote) {
        throw new Error(
          `itemModal(DayNote) is ${itemModal[1]} but itemModalDayNote missing`
        );
      }

      if (itemModal[1] === 'edit') {
        itemModalRendered = (
          <DayNotes.EditDayNoteModal
            dayNote={itemModalDayNote}
            onDismiss={clearModal}
            upsertEntities={dayNotesUpsertEntities}
          />
        );
      } else {
        throw new Error(`Unknown itemModal(DayNote): ${itemModal[1]}`);
      }
    } else if (itemModal[0] === 'Gig' && entities) {
      // $FlowFixMe
      const itemModalGig: ?V_Gig_Table = sortedEntities
        .filter((e) => e['@type'] === 'Gig')
        .find((e) => e.id === itemModal[2]);

      if (!itemModalGig) {
        throw new Error(
          `itemModal(Gig) is ${itemModal[1]} but itemModalGig missing`
        );
      }

      if (itemModal[1] === 'setTimes') {
        itemModalRendered = (
          <SetTimes.SetTimesModal
            gig={itemModalGig}
            entities={entities}
            onDismiss={clearModal}
            upsertEntities={upsertEntities}
          />
        );
      } else if (itemModal[1] === 'deal') {
        itemModalRendered = (
          <Deal.DealModal
            gig={itemModalGig}
            entities={entities}
            onDismiss={clearModal}
            upsertEntities={upsertEntities}
          />
        );
      } else if (itemModal[1] === 'travel') {
        itemModalRendered = (
          <Travel.TravelModal
            gig={itemModalGig}
            entities={entities}
            onDismiss={clearModal}
            upsertEntities={upsertEntities}
          />
        );
      } else if (itemModal[1] === 'hotel') {
        itemModalRendered = (
          <Hotel.HotelModal
            gig={itemModalGig}
            entities={entities}
            onDismiss={clearModal}
            upsertEntities={upsertEntities}
          />
        );
      } else if (itemModal[1] === 'travellingParty') {
        itemModalRendered = (
          <TravellingParty.TravellingPartyModal
            gig={itemModalGig}
            entities={entities}
            onDismiss={clearModal}
            upsertEntities={upsertEntities}
            deleteEntity={deleteEntity}
          />
        );
      } else if (itemModal[1] === 'advancingContactList') {
        itemModalRendered = (
          <Advancing.ContactListModal
            gig={itemModalGig}
            entities={entities}
            onDismiss={clearModal}
            upsertEntities={upsertEntities}
          />
        );
      } else if (itemModal[1] === 'advancingEmail') {
        itemModalRendered = (
          <Advancing.EmailModal
            gig={itemModalGig}
            entities={entities}
            onDismiss={clearModal}
            upsertEntities={upsertEntities}
          />
        );
      } else if (itemModal[1] === 'more') {
        itemModalRendered = (
          <More.MoreModal
            gig={itemModalGig}
            entities={entities}
            onDismiss={clearModal}
            upsertEntities={upsertEntities}
            deleteEntity={deleteEntity}
          />
        );
      } else {
        itemModalRendered = (
          <NotesModal
            gig={itemModalGig}
            entities={entities}
            // $FlowFixMe no tuple refinement
            fieldName={itemModal[1]}
            onDismiss={clearModal}
            upsertEntities={upsertEntities}
          />
        );
      }
    }
  }

  let rowTop = 170 - 12 - 60;
  return (
    <PageWrap
      subHeader={
        <GigsSubHeader
          viewMode="table"
          leadFilter={leadFilter}
          actFilter={actFilter}
          periodFilter={periodFilter}
        />
      }
    >
      <Helmet>Gigs</Helmet>
      {!groupedEntities || !sortedEntities || !entities ? (
        <PageLoading />
      ) : (
        <>
          {(isLoading || dayNotesIsLoading) && <PageLoading />}
          <StyledPageWrap>
            {actFilter !== 'all' && (
              <>
                <ActDetail.ActDetail actId={actFilter} />
                <Spacer size={2} />
              </>
            )}

            <StyledTable cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <StyledTh></StyledTh>
                  <StyledTh>Artist</StyledTh>
                  <StyledTh>Date</StyledTh>
                  {/* <StyledTh>Lead</StyledTh> */}
                  <StyledTh>Location</StyledTh>
                  <StyledTh>Set Times</StyledTh>
                  <StyledTh>Deal</StyledTh>
                  <StyledTh textAlign="center">Travel</StyledTh>
                  <StyledTh textAlign="center">Hotel</StyledTh>
                  <StyledTh textAlign="center">Visa</StyledTh>
                  <StyledTh textAlign="center">Covid</StyledTh>
                  <StyledTh textAlign="center">SFX</StyledTh>
                  <StyledTh textAlign="center">Notes</StyledTh>
                  <StyledTh>Touring Party</StyledTh>
                  <StyledTh>Promoters</StyledTh>
                  <StyledTh>Payments</StyledTh>
                </tr>
              </thead>
              {groupedEntities.map((group, groupIndex) => {
                rowTop += 12;
                return (
                  <TableGroup key={groupIndex.toString()}>
                    {group.map((entity) => {
                      rowTop += 60;

                      // In tables with more than 50 rows, don't render rows
                      // which are too far off-screen
                      if (
                        sortedEntities.length > 50 &&
                        !shouldRenderRow(rowTop)
                      ) {
                        return (
                          <tr key={`${entity['@type']}-${entity.id}`}>
                            <td colSpan={99} style={{ height: 60 }} />
                          </tr>
                        );
                      }

                      return entity['@type'] === 'Gig' ? (
                        <GigRow
                          key={`Gig-${entity.id}`}
                          gig={entity}
                          entities={entities}
                          setItemModal={setItemModal}
                          upsertEntities={upsertEntities}
                        />
                      ) : (
                        <DayNotes.DayNoteRow
                          key={`DayNote-${entity.id}`}
                          dayNote={entity}
                          setItemModal={setItemModal}
                          upsertEntities={dayNotesUpsertEntities}
                          deleteEntity={dayNotesDeleteEntity}
                        />
                      );
                    })}
                  </TableGroup>
                );
              })}
            </StyledTable>
          </StyledPageWrap>

          <Fab
            dropdownChildren={
              <>
                <DropdownSelectItem
                  label="New Gig / Off-Day"
                  onClick={() => setCreateModal('Gig')}
                />
                <DropdownSelectItem
                  label="New Day Note"
                  onClick={() => setCreateModal('DayNote')}
                />
              </>
            }
          />

          {createModal === 'Gig' && (
            <Create.CreateGigModalLoader
              onDismiss={() => setCreateModal(null)}
              upsertEntities={upsertEntities}
            />
          )}
          {createModal === 'DayNote' && (
            <DayNotes.CreateDayNoteModal
              onDismiss={() => setCreateModal(null)}
              upsertEntities={dayNotesUpsertEntities}
            />
          )}

          {itemModalRendered}
        </>
      )}
    </PageWrap>
  );
});

const StyledPageWrap = styled.div`
  padding: 20px 30px;
`;

const StyledTable = styled.table`
  border-collapse: collapse;
  position: relative;
  /* Create a stacking context so hidden contents can go to -1 without
     overlaping lower rows. */
  z-index: ${ZIndex.table};
`;

const StyledTh = styled.th`
  color: #939393;
  text-transform: uppercase;
  text-align: ${(p) => p.textAlign || 'left'};
  font-weight: 600;
  padding: 10px 15px;
  font-size: 12px;
  white-space: nowrap;

  position: sticky;
  top: 122px;
  z-index: ${ZIndex.thead};
  background-color: #f5f5f5;
`;
