App.Models.Venue = App.Models.Base.extend({
    urlRoot: () => App.config.baseUrl + 'api-platform/venues',
    idAttribute: 'id',
    validate: function(attrs, options) {
		if(!attrs.name) {
			return "Please supply a venue name";
		}
	},

    relations: [
        {
            type: Backbone.HasOne,
            key: 'address',
            relatedModel: 'App.Models.Address',
            reverseRelation: {
                includeInJSON: false
            }
        }
    ]
});