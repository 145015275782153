// @flow

import type { RootState } from '../types';

import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useSentryUserManager } from '../hooks';
import AppAnon from './AppAnon';
import AppAuthedAccount from './AppAuthedAccount';
import AppAuthedToken from './AppAuthedToken';

export default function AppAuthCheck() {
  useSentryUserManager();
  const { user } = useSelector<RootState, _>((state) => state.auth);
  const dispatch = useDispatch();
  const { search } = useLocation();

  // See if there's a token in the URL.  If so, log them out right away, so
  // the token can log them in as whoever they were expecting.
  const [checkedForToken, setCheckedForToken] = React.useState(false);
  React.useEffect(() => {
    const matches = search?.match(/.+token=([A-Za-z0-9]+)/);
    if (matches) {
      dispatch({ type: 'LOGOUT' });
    }
    setCheckedForToken(true);
  }, [search, dispatch]);

  if (!checkedForToken) {
    return 'Cheking token...';
  }

  if (!user) return <AppAnon />;
  if (user.type === 'Account') return <AppAuthedAccount />;
  return <AppAuthedToken />;
}
