// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Icon, Spacer } from '../../../components';
import { Color } from '../../../constants';

type Props = $ReadOnly<{|
  columnTitles?: string[],
  rows?: $ReadOnlyArray<{|
    values: $ReadOnlyArray<?boolean>,
    title: string,
    onlyCompletedRowLabelColor?: string,
  |}>,
  notes: ?string,
|}>;

export default function WorkflowDropdownPreview({
  columnTitles,
  rows,
  notes,
}: Props) {
  const rowsCompleted = rows?.map((row) => row.values.every((v) => v));

  // // Mark the next step to be completed in a different color
  // let nextMarked = false;
  return (
    <StyledContainer>
      {rows && (
        <>
          {columnTitles && (
            <StyledRow>
              {columnTitles.map((title) => (
                <StyledColumnTitle key={title}>{title}</StyledColumnTitle>
              ))}
            </StyledRow>
          )}

          {rows?.map((row, index) => {
            const completed = rowsCompleted?.[index];

            /* const mark = false;
            if (!nextMarked && row.values.some((v) => !v)) {
              mark = true;
              nextMarked = true;
            } */

            let color;
            // if (mark) color = Color.orange; else
            if (completed) color = Color.green;
            if (
              completed &&
              row.onlyCompletedRowLabelColor &&
              rowsCompleted?.filter(Boolean).length === 1
            )
              color = row.onlyCompletedRowLabelColor;

            return (
              <StyledRow key={row.title}>
                {row.values.map((value, i) => (
                  <StyledCellTick key={i}>
                    <Icon name="ok" color={value ? Color.green : '#ccc'} />
                  </StyledCellTick>
                ))}
                <StyledCellLabel
                  style={{
                    color: color || '#999',
                    fontWeight: color ? '600' : undefined,
                  }}
                >
                  {row.title}
                </StyledCellLabel>
              </StyledRow>
            );
          })}
          <Spacer />
        </>
      )}

      {notes && (
        <>
          <StyledNotesHeading>Notes</StyledNotesHeading>
          <div>{notes}</div>
        </>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
  text-align: left;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const StyledCellTick = styled.div`
  width: 28px;
  text-align: center;
  font-weight: 600;
`;

const StyledCellLabel = styled.div`
  flex: 1;
  margin-left: 10px;
`;

const StyledColumnTitle = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  width: 28px;
  text-align: center;
`;

const StyledNotesHeading = styled.strong`
  display: block;
  margin: 0 0 3px;
  text-transform: uppercase;
`;
