// @flow

import * as React from 'react';
import styled from 'styled-components';

import Icon from './Icon';

type Props = $ReadOnly<{|
  value: boolean,
  onToggle: () => mixed,
  label?: ?string,
  colorOn?: string,
  colorOff?: string,
  disabled?: boolean,
|}>;

export default function Checkbox({
  value,
  onToggle,
  label,
  colorOn = 'rgb(0, 217, 163)',
  colorOff = '#888',
  disabled,
}: Props) {
  return (
    <StyledContainer>
      <StyledBox
        $disabled={disabled}
        $value={value}
        style={{
          backgroundColor: value ? colorOn : 'transparent',
          borderColor: value ? colorOn : colorOff,
        }}
        onClick={disabled ? undefined : onToggle}
      >
        {value && <Icon name="ok" color="#fff" />}
      </StyledBox>
      {label && (
        <StyledLabel onClick={disabled ? undefined : onToggle}>
          {label}
        </StyledLabel>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBox = styled.a`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.25s, opacity 0.25s;
  ${(p) => p.disabled && 'cursor: default'};
  border: 2px solid;
  border-radius: 4px;

  &:hover {
    ${(p) => !p.disabled && 'opacity: 0.8'};
  }
`;

const StyledLabel = styled.a`
  font-size: 15px;
  margin-left: 8px;
  cursor: pointer;
  color: #585858;
  transition: color 0.25s;

  &:hover {
    ${(p) => !p.disabled && 'opacity: 0.8'};
    color: #000;
  }
`;
