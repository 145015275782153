// @flow

import type { VenueTypeahead } from '../../../../models';
import type { Entities } from '../../../../types';

import * as React from 'react';
import { useForm } from 'react-typed-form';

import { useApiFormSubmit } from '../../../../api';
import {
  ButtonLarge,
  Modal,
  ModalHeader,
  ModalStandardWrap,
} from '../../../../components';
import {
  FieldDate,
  FieldSwitch,
  FieldTypeahead,
  FormWrap,
  createFormValidator,
} from '../../../../form';
import { useHardEntities } from '../../../../hooks';
import schemas from '../../../../schemas';
import { values } from '../../../../util';

type Props = $ReadOnly<{|
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
  hardVenues: Entities<VenueTypeahead>,
|}>;

export default function CreateGigModal({
  onDismiss,
  upsertEntities,
  hardVenues,
}: Props) {
  const acts = values(useHardEntities().Act).filter(Boolean);
  const venues = values(hardVenues).filter(Boolean);

  const formSubmit = useApiFormSubmit('POST', `/gigs`, {
    onSuccess: (responseData) => {
      upsertEntities(responseData, schemas.Gig);
      onDismiss();
    },
  });
  // eslint-disable-next-line flowtype/no-weak-types
  const { formErrorList, getField, handleSubmit, isLoading } = useForm<any>({
    validate: createFormValidator({
      date: { strictPresence: true },
      act: { strictPresence: true, typeahead: true },
      venue: { strictPresence: true, typeahead: true },
    }),
    onSubmit: formSubmit,
  });

  return (
    <Modal wide onDismiss={onDismiss}>
      <ModalStandardWrap
        header={
          <ModalHeader
            title={`New ${getField('offDay').value ? 'Off-Day' : 'Gig'}`}
          />
        }
      >
        <FormWrap formErrorList={formErrorList} handleSubmit={handleSubmit}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: 1, paddingRight: 15 }}>
              <FieldDate field={getField('date')} />
            </div>
            <div style={{ flex: 1 }}>
              <FieldSwitch
                field={{ ...getField('offDay'), label: 'Off-day' }}
              />
            </div>
          </div>
          <FieldTypeahead
            field={getField('act')}
            choices={acts.map((act) => ({
              value: act['@id'],
              title: act.name,
            }))}
          />
          <FieldTypeahead
            field={getField('venue')}
            choices={venues.map((venue) => ({
              value: venue['@id'],
              title: venue.name,
              description: `${venue.address.city}, ${venue.address.country}`,
            }))}
            hint={
              getField('offDay').value
                ? 'Off-Days still need a "venue" in the relevant city, so we can calculate timezones.'
                : undefined
            }
          />
          <ButtonLarge cta type="submit" label="Save" disabled={isLoading} />
          <ButtonLarge
            label="Cancel"
            disabled={isLoading}
            onClick={() => {
              onDismiss();
            }}
          />
        </FormWrap>
      </ModalStandardWrap>
    </Modal>
  );
}
