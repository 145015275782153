// @flow

import * as React from 'react';

import { ErrorList } from '../components';

type Props = $ReadOnly<{
  formErrorList: string[],
  handleSubmit: () => mixed,
  children: React.Node,
}>;

export default function FormWrap({
  formErrorList,
  handleSubmit,
  children,
}: Props) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <ErrorList errors={formErrorList} />
      {children}
    </form>
  );
}
