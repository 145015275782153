// @flow

import type { V_Gig_Table } from '../../../../models';
import type { TableRowBackgroundColor } from '../../../../types';
import type { ItemModalState, ItinStatus } from '../../types';

import * as React from 'react';
import styled from 'styled-components';

import {
  Dropdown,
  Icon,
  TableCell,
  TableCellButton,
} from '../../../../components';
import { deserializeAdvancingContactList } from '../../../../models/Gig';
import { AddPrompt } from '../../components';
import { itinStatusLabel } from '../../itin-statuses';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  itinStatus: ItinStatus,
  setItemModal: (ItemModalState) => mixed,
  backgroundColor: TableRowBackgroundColor,
|}>;

export default React.memo<Props>(function AdvancingCell({
  gig,
  itinStatus,
  setItemModal,
  backgroundColor,
}: Props) {
  const [hover, setHover] = React.useState(false);

  const advancingContactList = deserializeAdvancingContactList(
    gig.advancingContactList
  );

  return (
    <TableCell
      gap="left"
      width={180}
      noPadding
      backgroundColor={backgroundColor}
    >
      {advancingContactList.length ? (
        <StyledButtonsWrap>
          <TableCellButton
            onClick={() =>
              setItemModal(['Gig', 'advancingContactList', gig.id])
            }
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
          >
            {advancingContactList.map((ac) => ac.name).join(', ')}
            <StyledStatus status={itinStatus}>
              {itinStatusLabel(itinStatus)}
              {itinStatus === 'itin_sent' && (
                <Icon name="ok" color="rgb(0, 217, 163)" size={14} />
              )}
            </StyledStatus>
          </TableCellButton>
          <StyledEmailButton
            onClick={() => setItemModal(['Gig', 'advancingEmail', gig.id])}
          >
            <Icon name="mail" size={22} />
          </StyledEmailButton>

          <StyledAnchor>
            <Dropdown open={hover} attachedSide="left">
              <StyledDropdownContent>
                {advancingContactList.map((ac) => (
                  <div key={`${ac.name || ''}-${ac.email}`}>
                    {ac.name}
                    <span> – {ac.email}</span>
                  </div>
                ))}
              </StyledDropdownContent>
            </Dropdown>
          </StyledAnchor>
        </StyledButtonsWrap>
      ) : (
        <StyledSpacedAddPrompt
          onClick={() => setItemModal(['Gig', 'advancingContactList', gig.id])}
        />
      )}
    </TableCell>
  );
});

const StyledButtonsWrap = styled.div`
  flex: 1;
  display: flex;
`;

const StyledStatus = styled.span`
  display: block;
  font-size: 13px;
  ${(p) =>
    !['not_started', 'advance_sent'].includes(p.status) && `font-weight: 600`};

  color: #999;
  ${(p) => p.status === 'advance_sent' && `color: inherit`};
  ${(p) => p.status === 'in_progress' && `color: rgb(255, 127, 0)`};
  ${(p) =>
    ['itin_ready', 'itin_sent'].includes(p.status) &&
    `color: rgb(0, 217, 163)`};
`;

const StyledEmailButton = styled.a`
  align-self: center;
  width: 36px;
  height: 32px;
  border-radius: 5px;
  border: 1px #ddd solid;
  color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  transition: all 0.25s;

  &:hover {
    color: #aaa;
    background-color: #efecf3;
    border-color: #aaa;
  }
`;

const StyledAnchor = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0px;
  left: 30px;
`;

const StyledDropdownContent = styled.div`
  font-size: 14px;
  text-align: left;

  > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    span {
      color: #999;
    }
  }
`;

const StyledSpacedAddPrompt = styled(AddPrompt)`
  margin-left: 15px;
`;
