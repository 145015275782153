// @flow

import type { FormErrors } from 'react-typed-form/src/types';

import baseValidate from 'validate.js';

import { PARTIAL } from './FieldTypeahead';

/**
 * Equivalent of built in validator: `presence: { allowEmpty: false }` because
 * that's nearly always what this app wants.
 */
baseValidate.validators.strictPresence = function (value, options, ...rest) {
  return baseValidate.validators.presence(
    value,
    // options might be `true`
    { allowEmpty: false, ...(typeof options === 'object' ? options : {}) },
    ...rest
  );
};

/**
 * Allow undefined, i.e. when performing a PATCH of existing entity
 */
baseValidate.validators.optionalPresence = function (value) {
  if (
    value === null ||
    (value && typeof value === 'string' && value.trim() === '')
  ) {
    return "can't be blank";
  }
};

baseValidate.validators.optionalFormat = function (value, ...rest) {
  if (value == null) return undefined;
  return baseValidate.validators.format(value, ...rest);
};

baseValidate.validators.allLinesFormat = function (value, options) {
  if (value == null) return undefined;

  const { pattern, message } = options;
  const lines = value.split('\n');
  for (const line of lines) {
    const match = pattern.exec(line);
    if (!match || match[0].length != line.length) {
      return message;
    }
  }
};

baseValidate.validators.typeahead = function (value) {
  if (value === PARTIAL) {
    return 'must be an option from the suggestions';
  }
};

export default function createFormValidator<T: {}>(constraints: {}): (
  values: T
) => FormErrors<T> {
  return (values) => baseValidate(values, constraints) || {};
}
