App.Collections.Airlines = Backbone.Collection.extend({
    model: App.Models.Airline,
    url: () => App.config.baseUrl + 'flightstats/airlines/active',

    sync: function(method, collection, options) {
        return Backbone.sync(method, collection, options);
    },

    parse: function(response) {
        response = response.airlines;
        return _.filter(response, function(airline) {
            return typeof(airline['iata']) !== 'undefined';
        });
    },

    comparator: function(airline) {
        return airline.get('name').toLowerCase();
    }
});