// @flow

import * as React from 'react';

import { APP_ENV, SERVER_ROOT } from '../../config';

type Options = $ReadOnly<{|
  apiKey: string, // token or user apiKey
  staff: boolean,
|}>;

/**
 * Give the legacy code access to the API key etc
 */
export default function useLegacyConfig({ apiKey, staff }: Options) {
  React.useEffect(() => {
    window.App.config.apiKey = apiKey;
    window.App.config.appEnv = APP_ENV;
    window.App.config.baseUrl = SERVER_ROOT + '/';
    window.App.config.staff = staff;
  }, [apiKey, staff]);
}
