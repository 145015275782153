// @flow

import type { V_Gig_Deal } from '../../../../models';

import * as React from 'react';
import { useForm } from 'react-typed-form';
import styled from 'styled-components';

import { useApiFormSubmit } from '../../../../api';
import {
  ModalMinimalSubmitButton,
  ModalStandardWrap,
  Spacer,
} from '../../../../components';
import { Dimen } from '../../../../constants';
import { FieldCheckbox, FieldSwitch, FormWrap } from '../../../../form';
import schemas from '../../../../schemas';
import DealIcon from './DealIcon';
import FieldNotes from './FieldNotes';
import FieldSelectMini from './FieldSelectMini';
import FieldTextMini from './FieldTextMini';
import SpinePiece from './SpinePiece';
import SpineRow from './SpineRow';
import SpineTotal from './SpineTotal';

type Props = $ReadOnly<{|
  gig: V_Gig_Deal,
  upsertEntities: (entity: {}, schema: mixed) => void,
  onDismiss: () => mixed,
|}>;

export default function ModalContent({
  gig,
  upsertEntities,
  onDismiss,
}: Props) {
  const formSubmit = useApiFormSubmit('PUT', `/gigs/${gig.id}/staff`, {
    onSuccess: (responseData) => {
      upsertEntities(responseData, schemas.Gig);
      onDismiss();
    },
  });

  // eslint-disable-next-line flowtype/no-weak-types
  const { formErrorList, getField, handleSubmit, isLoading } = useForm<
    $Shape<V_Gig_Deal>
  >({
    pristineValues: gig,
    defaultValues: {
      dealTravel: gig.dealTravel || false,
      dealHotel: gig.dealHotel || false,
      dealGround: gig.dealGround || false,
    },
    onSubmit: formSubmit,
  });

  return (
    <FormWrap formErrorList={formErrorList} handleSubmit={handleSubmit}>
      <ModalStandardWrap noPadding hideOverflow>
        <StyledGrid>
          <StyledTop>
            <DealIcon
              type="travel"
              enabled={getField('dealTravel').value || false}
              size={34}
            />
            <FieldSwitch field={getField('dealTravel')} label={null} />
          </StyledTop>
          {getField('dealTravel').value ? (
            <>
              <StyledMid>
                <FieldSelectMini
                  field={getField('dealTravelType')}
                  choices={[
                    { value: null, label: '' },
                    { value: 'flight', label: 'Flight' },
                    { value: 'train', label: 'Train' },
                    { value: 'flight_train', label: 'Flight / Train' },
                  ]}
                />
                <Spacer />
                <SpineTotal
                  value={
                    parseInt(getField('dealTravelNumSeatsEco').value || 0) +
                    parseInt(getField('dealTravelNumSeatsPremium').value || 0) +
                    parseInt(getField('dealTravelNumSeatsFirst').value || 0) +
                    parseInt(
                      getField('dealTravelNumSeatsBusiness').value || 0
                    ) +
                    parseInt(getField('dealTravelNumSeatsOther').value || 0)
                  }
                  label="Seats"
                />
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow>
                  <FieldTextMini
                    field={getField('dealTravelNumSeatsEco')}
                    label="Eco"
                  />
                </SpineRow>
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow>
                  <FieldTextMini
                    field={getField('dealTravelNumSeatsPremium')}
                    label="Premium"
                  />
                </SpineRow>
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow>
                  <FieldTextMini
                    field={getField('dealTravelNumSeatsFirst')}
                    label="First"
                  />
                </SpineRow>
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow>
                  <FieldTextMini
                    field={getField('dealTravelNumSeatsBusiness')}
                    label="Business"
                  />
                </SpineRow>
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow ending>
                  <FieldTextMini
                    field={getField('dealTravelNumSeatsOther')}
                    label="Other"
                  />
                </SpineRow>
              </StyledMid>
              <StyledNotes>
                <FieldNotes field={getField('dealTravelNotes')} />
              </StyledNotes>
            </>
          ) : (
            <>
              <StyledEmpty />
              <StyledEmpty />
            </>
          )}

          <StyledTop>
            <DealIcon
              type="hotel"
              enabled={getField('dealHotel').value || false}
              size={34}
            />
            <FieldSwitch field={getField('dealHotel')} label={null} />
          </StyledTop>
          {getField('dealHotel').value ? (
            <>
              <StyledMid>
                <FieldSelectMini
                  field={getField('dealHotelStars')}
                  choices={[
                    { value: null, label: '' },
                    ...[1, 2, 3, 4, 5].map((n) => ({
                      value: n,
                      label: `${n} Stars`,
                    })),
                  ]}
                />
                <Spacer />
                <FieldTextMini
                  field={getField('dealHotelNumNights')}
                  label="Nights"
                />
                <Spacer />
                <SpineTotal
                  value={
                    parseInt(getField('dealHotelNumRoomsDouble').value || 0) +
                    parseInt(getField('dealHotelNumRoomsTwin').value || 0) +
                    parseInt(getField('dealHotelNumRoomsKing').value || 0) +
                    parseInt(getField('dealHotelNumRoomsSuperior').value || 0) +
                    parseInt(
                      getField('dealHotelNumRoomsExecutive').value || 0
                    ) +
                    parseInt(
                      getField('dealHotelNumRoomsJuniorSuite').value || 0
                    ) +
                    parseInt(getField('dealHotelNumRoomsSuite').value || 0) +
                    parseInt(getField('dealHotelNumRoomsOther').value || 0)
                  }
                  label="Rooms"
                />
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow>
                  <FieldTextMini
                    field={getField('dealHotelNumRoomsDouble')}
                    label="Double"
                  />
                </SpineRow>
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow>
                  <FieldTextMini
                    field={getField('dealHotelNumRoomsTwin')}
                    label="Twin"
                  />
                </SpineRow>
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow>
                  <FieldTextMini
                    field={getField('dealHotelNumRoomsKing')}
                    label="King"
                  />
                </SpineRow>
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow>
                  <FieldTextMini
                    field={getField('dealHotelNumRoomsSuperior')}
                    label="Superior"
                  />
                </SpineRow>
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow>
                  <FieldTextMini
                    field={getField('dealHotelNumRoomsExecutive')}
                    label="Executive"
                  />
                </SpineRow>
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow>
                  <FieldTextMini
                    field={getField('dealHotelNumRoomsJuniorSuite')}
                    label="Junior Suite"
                  />
                </SpineRow>
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow>
                  <FieldTextMini
                    field={getField('dealHotelNumRoomsSuite')}
                    label="Suite"
                  />
                </SpineRow>
                <SpinePiece height={Dimen.spacing / 2} />
                <SpineRow ending>
                  <FieldTextMini
                    field={getField('dealHotelNumRoomsOther')}
                    label="Other"
                  />
                </SpineRow>
              </StyledMid>
              <StyledNotes>
                <FieldNotes field={getField('dealHotelNotes')} />
              </StyledNotes>
            </>
          ) : (
            <>
              <StyledEmpty />
              <StyledEmpty />
            </>
          )}

          <StyledTop>
            <DealIcon
              type="ground"
              enabled={getField('dealGround').value || false}
              size={34}
            />
            <FieldSwitch field={getField('dealGround')} label={null} />
          </StyledTop>
          {getField('dealGround').value ? (
            <>
              <StyledMid>
                <FieldCheckbox
                  field={getField('dealGroundAirportToHotel')}
                  label="Airport to/from Hotel"
                  colorOn="#888"
                />
                <Spacer />
                <FieldCheckbox
                  field={getField('dealGroundVenueToHotel')}
                  label="Venue to/from Hotel"
                  colorOn="#888"
                />
              </StyledMid>
              <StyledNotes>
                <FieldNotes field={getField('dealGroundNotes')} />
              </StyledNotes>
            </>
          ) : (
            <>
              <StyledEmpty />
              <StyledEmpty />
            </>
          )}
        </StyledGrid>
      </ModalStandardWrap>
      <ModalMinimalSubmitButton isLoading={isLoading} />
    </FormWrap>
  );
}

const StyledCell = styled.div`
  &:not(:last-child) {
    border-right: 1px #ccc solid;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 100px 1fr auto;
  grid-template-columns: repeat(3, 1fr);
`;

const StyledTop = styled(StyledCell)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledMid = styled(StyledCell)`
  padding: 12px;
  background-color: #eee;
`;

const StyledNotes = styled(StyledCell)``;

const StyledEmpty = styled(StyledCell)`
  background-color: #eee;
`;
