// @flow

import type { GigTableEntities } from '../../../../hooks/entities-store';
import type { V_Gig_Table } from '../../../../models';

import * as React from 'react';
import { useForm } from 'react-typed-form';
import styled from 'styled-components';

import { useApiCallable, useApiFormSubmit } from '../../../../api';
import {
  ModalMinimalSubmitButton,
  ModalStandardWrap,
  Spacer,
} from '../../../../components';
import { FieldSwitch, FieldText, FormWrap } from '../../../../form';
import schemas from '../../../../schemas';
import { GigModalWrap } from '../../components';

export type ModalField = 'flights' | 'visa' | 'hotel' | 'misc';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  entities: GigTableEntities,
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
  deleteEntity: (string) => void,
|}>;

export default React.memo<Props>(function MoreModal({
  gig,
  entities,
  onDismiss,
  upsertEntities,
  deleteEntity,
}: Props) {
  const { callApi } = useApiCallable();

  const formSubmit = useApiFormSubmit('PUT', `/gigs/${gig.id}`, {
    onSuccess: (responseData) => {
      upsertEntities(responseData, schemas.Gig);
      onDismiss();
    },
  });

  // eslint-disable-next-line flowtype/no-weak-types
  const { formErrorList, getField, handleSubmit, isLoading } = useForm<any>({
    pristineValues: gig,
    onSubmit: formSubmit,
  });

  return (
    <GigModalWrap
      title="More"
      gig={gig}
      entities={entities}
      onDismiss={onDismiss}
    >
      <FormWrap formErrorList={formErrorList} handleSubmit={handleSubmit}>
        <ModalStandardWrap>
          <StyledSectionHeading>Status</StyledSectionHeading>

          <FieldText
            field={getField('statusText')}
            hint="Short extra info on Gig to show in Table view"
          />

          {!gig.offDay && (
            <>
              <FieldSwitch
                field={getField('cancelled')}
                disabled={getField('doNotAdvance').value}
              />
              {getField('cancelled').value && (
                <FieldSwitch
                  field={{
                    ...getField('cancelledChargeAdvance'),
                    label: 'Charge advance fee',
                  }}
                />
              )}
              <Spacer size={0.5} />
              <FieldSwitch
                field={getField('doNotAdvance')}
                disabled={getField('cancelled').value}
              />
            </>
          )}

          <StyledSectionHeading>Actions</StyledSectionHeading>
          <StyledAction
            onClick={async () => {
              if (confirm('Are you sure you want to delete this gig?')) {
                // Dismiss first, or modal will error that it has no Gig
                onDismiss();
                await callApi({
                  method: 'DELETE',
                  path: `/gigs/${gig.id}`,
                });
                deleteEntity(gig['@id']);
              }
            }}
          >
            Delete this {gig.offDay ? 'off-day' : 'gig'}
          </StyledAction>
        </ModalStandardWrap>
        <ModalMinimalSubmitButton isLoading={isLoading} />
      </FormWrap>
    </GigModalWrap>
  );
});

const StyledSectionHeading = styled.div`
  border-bottom: 1px #ddd solid;
  margin: 25px 0 12px;
  font-size: 18px;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledAction = styled.a`
  padding: 8px 20px;
  font-weight: 500;
  border-radius: 5px;
  color: #cb2431;
  border: 1px #bbb solid;
  box-shadow: 0 0 3px #0001;
  margin: 3px 0;
  display: inline-block;
  background-color: #fafbfc;
  background-image: linear-gradient(-180deg, #fcfcfc, #f8f8f8 90%);

  &:hover {
    color: #cb2431;
    border-color: #cb2431;
  }
`;
