// @flow

import { linearGradient } from 'polished';
import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  label: string,
  cta?: boolean,
  type?: string,
  onClick?: () => void,
  disabled?: boolean,
|}>;

export default function ButtonLarge({ label, ...rest }: Props) {
  return <StyledButton {...rest}>{label}</StyledButton>;
}

const StyledButton = styled.button`
  margin-top: 10px;
  height: 54px;
  line-height: 54px;
  box-sizing: border-box;
  width: 100%;

  font-weight: 600;
  font-size: 16px;
  border: 0;
  border-radius: 5px;
  text-transform: uppercase;
  color: ${(p) => (p.cta ? '#fff' : '#888')};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  ${(p) =>
    linearGradient({
      colorStops: p.cta ? ['#563D77', '#473978'] : ['#eee', '#ddd'],
      toDirection: 'to bottom',
    })};

  transition: opacity 0.25s;

  &:hover {
    ${(p) => !p.disabled && `opacity: 0.9`};
  }
`;
