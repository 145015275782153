// @flow

import type { V_Act_TableDetail } from '../../../../models';

import { darken } from 'polished';
import * as React from 'react';
import { useForm } from 'react-typed-form';
import styled from 'styled-components';

import { useApiCallable, useApiFormSubmit } from '../../../../api';
import { Spacer } from '../../../../components';
import { FieldText, FormWrap } from '../../../../form';

type Props = $ReadOnly<{|
  existing: null | $ElementType<
    $PropertyType<V_Act_TableDetail, 'agencyDiaries'>,
    number
  >,
  actId: number,
  onDismiss: () => void,
  onSuccess: () => void,
|}>;

export default function AgencyDiaryForm({
  existing,
  actId,
  onDismiss,
  onSuccess,
}: Props) {
  const { callApi } = useApiCallable();

  const onSubmit = useApiFormSubmit(
    existing ? 'PUT' : 'POST',
    existing ? `/agency_diaries/${existing.id}` : '/agency_diaries',
    { onSuccess }
  );

  // eslint-disable-next-line flowtype/no-weak-types
  const form = useForm<any>({
    pristineValues: existing ?? undefined,
    defaultValues: existing ? {} : { act: `/api-platform/acts/${actId}` },
    onSubmit,
  });

  return (
    <FormWrap {...form}>
      <FieldText field={form.getField('name')} />
      <FieldText field={form.getField('link')} />

      <Spacer />
      <div style={{ display: 'flex' }}>
        <StyledButton type="submit" disabled={form.isLoading} $cta>
          Save
        </StyledButton>
        <Spacer size={0.5} />
        {existing && (
          <>
            <StyledButton
              type="button"
              disabled={form.isLoading}
              onClick={async () => {
                if (confirm('Are you sure you want to delete this diary?')) {
                  await callApi({
                    method: 'DELETE',
                    path: `/agency_diaries/${existing.id}`,
                  });
                  onSuccess();
                }
              }}
            >
              Delete
            </StyledButton>
            <Spacer size={0.5} />
          </>
        )}
        <StyledButton
          type="button"
          disabled={form.isLoading}
          onClick={onDismiss}
        >
          Cancel
        </StyledButton>
      </div>
    </FormWrap>
  );
}

const COLOR_CTA = '#5a9dd7';
const COLOR_NORMAL = '#999';

const StyledButton = styled.button`
  border: 0;
  background: transparent;
  padding: 8px 15px;
  color: ${(p) => (p.$cta ? COLOR_CTA : COLOR_NORMAL)};
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 17px;
  border: 1px #5a9dd7 solid;
  border-color: ${(p) => (p.$cta ? COLOR_CTA : COLOR_NORMAL)};
  border-radius: 8px;

  &:hover:not([disabled]) {
    color: ${(p) => darken(0.2, p.$cta ? COLOR_CTA : COLOR_NORMAL)};
    border-color: ${(p) => darken(0.2, p.$cta ? COLOR_CTA : COLOR_NORMAL)};
  }

  &[disabled] {
    cursor: default;
  }
`;
