// @flow

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

type Props = $ReadOnly<{|
  children: React.Node,
|}>;

export default function SimpleWrap({ children }: Props) {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/css/legacy-splash.css" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
        ></link>
        <style type="text/css">
          {`        body, input, textarea, select, button {
            font-family: 'Open Sans', arial, sans-serif;
            font-size: 16px;
            line-height: 1.5;
            color: #333;
            background-color: #fdfdfd;
        }

        body, html, header {
            margin: 0;
            padding: 0;
        }

        header {
            background-color: #111;
        }

        a, a:link {
            text-decoration: none;
            color: #07C;
        }

        a:link:hover {
            color: #0b93d5;
        }

        li {
            margin-bottom: 5px;
        }

        header a:link, header a:visited {
            color: #ddd;
        }

        header span {
            display: block;
            color: #fff;
            font-size: 22px;
        }

        .hcont {
            margin: 0 auto;
            max-width: 700px;
            padding: 0 15px;
        }

        hr {
            display: block;
            margin: 50px 0;
            border: 0;
            outline: 0;
            height: 1px;
            background-color: #ddd;
        }

        h3 {
            margin: 30px 0 20px;
            font-weight: 400;
            font-size: 20px;
        }

        h2 {
            font-weight: 400;
            font-size: 24px;
            margin-top: 40px;
        }`}
        </style>
      </Helmet>
      <header>
        <div className="hcont" style={{ padding: '15px 15px' }}>
          <span>Rooted</span>
          <Link to="/">&laquo; Back to website</Link>
        </div>
      </header>
      <div className="hcont" style={{ padding: '15px 15px' }}>
        {children}
      </div>
    </>
  );
}
