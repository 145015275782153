// @flow

import type { V_Gig_Deal } from '../../../../models/Gig';

import * as React from 'react';
import styled from 'styled-components';

import { useApiRead } from '../../../../api';
import { capitalize } from '../../../../util';

type Props = $ReadOnly<{|
  gigId: number,
|}>;

export default function DropdownPreview({ gigId }: Props) {
  const api = useApiRead<V_Gig_Deal>({ path: `/gigs/${gigId}/deal` });

  const gig = api.data;
  if (api.error) return 'Error!';
  if (!gig) return 'Loading...';

  const seatsSelected = [
    'dealTravelNumSeatsEco',
    'dealTravelNumSeatsPremium',
    'dealTravelNumSeatsFirst',
    'dealTravelNumSeatsBusiness',
    'dealTravelNumSeatsOther',
  ].filter((x) => gig[x]);

  const roomsSelected = [
    'dealHotelNumRoomsDouble',
    'dealHotelNumRoomsTwin',
    'dealHotelNumRoomsKing',
    'dealHotelNumRoomsSuperior',
    'dealHotelNumRoomsExecutive',
    'dealHotelNumRoomsJuniorSuite',
    'dealHotelNumRoomsSuite',
    'dealHotelNumRoomsOther',
  ].filter((x) => gig[x]);

  return (
    <StyledContainer>
      {gig.dealTravel && (
        <>
          <StyledTopRow>
            <strong>Travel</strong>
            {gig.dealTravelType && (
              <span>{capitalize(gig.dealTravelType.replace('_', ' / '))}</span>
            )}
          </StyledTopRow>
          <p className={seatsSelected.length > 0 ? undefined : 'empty'}>
            {seatsSelected.length === 0 && 'No seats selected'}
            {seatsSelected.map((x) => (
              <StyledRow key={x}>
                <span>{gig[x]}</span> {x.replace('dealTravelNumSeats', '')}
              </StyledRow>
            ))}
          </p>
          {gig.dealTravelNotes && <p>{gig.dealTravelNotes}</p>}
        </>
      )}

      {gig.dealHotel && (
        <>
          <StyledTopRow>
            <strong>Hotel</strong>
            <span>
              {[
                gig.dealHotelStars && `${gig.dealHotelStars} stars`,
                gig.dealHotelNumNights && `${gig.dealHotelNumNights} nights`,
              ]
                .filter(Boolean)
                .join(', ')}
            </span>
          </StyledTopRow>
          <p className={roomsSelected.length > 0 ? undefined : 'empty'}>
            {roomsSelected.length === 0 && 'No rooms selected'}
            {roomsSelected.map((x) => (
              <StyledRow key={x}>
                <span>{gig[x]}</span> {x.replace('dealHotelNumRooms', '')}
              </StyledRow>
            ))}
          </p>
          {gig.dealHotelNotes && <p>{gig.dealHotelNotes}</p>}
        </>
      )}

      {gig.dealGround && (
        <>
          <StyledTopRow>
            <strong>Ground</strong>
          </StyledTopRow>

          <p
            className={
              gig.dealGroundAirportToHotel || gig.dealGroundVenueToHotel
                ? undefined
                : 'empty'
            }
          >
            {!gig.dealGroundAirportToHotel &&
              !gig.dealGroundVenueToHotel &&
              'No ground transport selected'}

            {gig.dealGroundAirportToHotel && (
              <StyledRow>Airport to/from Hotel</StyledRow>
            )}

            {gig.dealGroundVenueToHotel && (
              <StyledRow>Venue to/from Hotel</StyledRow>
            )}
          </p>

          {gig.dealGroundNotes && <p>{gig.dealGroundNotes}</p>}
        </>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  font-size: 14px;

  .empty {
    color: #999;
  }

  p {
    margin: 12px 0;
    white-space: pre-wrap;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    display: block;
    text-transform: uppercase;
  }

  &:not(:first-of-type) {
    margin-top: 20px;
  }
`;

const StyledRow = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  position: relative;
  padding-left: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #bbb;
  }

  span {
  }
`;
