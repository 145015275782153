// Only works when placed inside a BaseItem... tightly coupled
App.Views.Widgets.Location = Backbone.View.extend({

    template: '#template-widget-location',

    // Stuff that has a from/to hash
    map: null,
    otherTimezone: null,

    events: {

    },

    initialize: function(options) {
        this.options = _.extend({}, {
            parentView: null,
            dirName: null,
            dirLabel: null
        }, options);

        if (this.options.dirLabel == null) {
            this.options.dirLabel = s.capitalize(this.options.dirName);
        }

        var dir = this.options.dirName;

        // Set an initial from/to_type
        var gig = App.Helpers.getGig();
        if (this.model.get(dir + '_type') == null) {
            this.model.set(dir + '_type',
                this.model.determineAddressType(gig, dir), { silent: true });
        }

        this.listenTo(this.model, 'change:' + dir + '_type', this.onChangeType);
    },

    serializeData: function() {
        var gig = App.Helpers.getGig();
        return {
            model: this.model.toJSON(),
            options: this.options,
            dir: this.options.dirName,
            venue: gig.get('venue').toJSON(),
            hotel: gig.get('hotel') ? gig.get('hotel').toJSON() : null
        }
    },

    render: function() {
        var template = App.Helpers.getTemplate( this.template );
        this.$el.html(template(this.serializeData()));

        this.toggleEditAddressVisibility();

        return this;
    },

    onBeforeSave: function() {
        var gig = App.Helpers.getGig();
        var dir = this.options.dirName;
        var type = this.model.get(dir + '_type');
        var unsetOtherAddress = false;
        if (type === 'venue') {
            var venueAddress = gig.get('venue').get('address');
            if (venueAddress) {
                this.model.set(dir + '_address', venueAddress.id);
                unsetOtherAddress = true;
            }
        } else if (type === 'hotel') {
            var hotelAddress = gig.get('hotel') && gig.get('hotel').get('address');
            if (hotelAddress) {
                this.model.set(dir + '_address', hotelAddress.id);
                unsetOtherAddress = true;
            }
        } else {
            this.model.set(dir + '_address', null);
        }
        // this.model.unset(dir + '_type');

        // If there's a hotel/venue attached, remove the other address
        if (unsetOtherAddress) {
            this.model.set(dir + '_name', null);
            this.model.set(dir + '_address_short', null);
            this.model.set(dir + '_lat', null);
            this.model.set(dir + '_lng', null);
        }
    },

    // When the from_type or to_type changes
    onChangeType: function() {
        var dir = this.options.dirName;
        var newType = this.model.get(dir + '_type');
        if (_.contains(['venue', 'hotel'], newType)) {
            // Reset the from/to timezone to blank, so they can default to venue timezone
            this.$('.js-' + dir + '_timezone').val('').trigger('change');

        } else if (newType === 'other') {
            // Load the saved value if there is one
            var timezone = this.otherTimezone || '';
            this.$('.js-' + dir + '_timezone').val(timezone).trigger('change');
        }

        this.toggleEditAddressVisibility();
    },

    toggleEditAddressVisibility: function() {
        var dir = this.options.dirName;
        var visible = this.model.get(dir + '_type') === 'other';
        this.$('.js-' + dir + '-address-other').toggle(visible);
        if (visible) {
            _.defer($.proxy(this.renderEditMap, this));
        }

        // Always do timezone check when doing address vis check
        this.toggleTimezoneVisibility();
    },

    toggleTimezoneVisibility: function() {
        var dir = this.options.dirName;
        this.$('.js-' + dir + '-timezone-row').toggle(
            this.model.get(dir + '_timezone') != null);
    },

    // Render the Google Map for either the from/to field
    renderEditMap: function() {
        var dir = this.options.dirName;
        if (this.map) {
            return;
        }

        // Center map on existing address if exists, or venue if not?
        var center = null;
        var gig = App.Helpers.getGig();
        var lastAddress = gig.get(dir + '_address');
        var venueAddress = gig.get('venue').get('address');

        if (lastAddress && lastAddress.get('lat') && lastAddress.get('lng')) {
            center = { lat: lastAddress.get('lat'), lng: lastAddress.get('lng') };

        } else if (this.model.get(dir + '_lat') && this.model.get(dir + '_lng')) {
            center = { lat: this.model.get(dir + '_lat'), lng: this.model.get(dir + '_lng') };

        } else if (venueAddress && venueAddress.get('lat') && venueAddress.get('lng')) {
            center = { lat: venueAddress.get('lat'), lng: venueAddress.get('lng') };
        }

        console.log('Views.Journey#renderEditMap init GMap', center);
        var map = this.map = new google.maps.Map(this.$('.js-' + dir + '-map').get(0), {
            center: center || { lat: 51.508796, lng: -0.124497 }, // London default
            zoom: 13
        });

        var input = this.$('.js-' + dir + '_address-map-input').get(0);
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

        var autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.bindTo('bounds', map);

        var infowindow = new google.maps.InfoWindow();
        var marker = new google.maps.Marker({
            map: map,
            anchorPoint: new google.maps.Point(0, -29)
        });

        if (center) {
            marker.setPosition(center);
            marker.setVisible(true);
        }

        autocomplete.addListener('place_changed', $.proxy(function() {
            infowindow.close();
            marker.setVisible(false);
            var place = autocomplete.getPlace();
            console.log(place);
            if (!place.geometry) {
                console.log("Autocomplete's returned place contains no geometry");
                return;
            }

            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);  // Why 17? Because it looks good.
            }
            marker.setIcon(({
                url: place.icon,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 34),
                scaledSize: new google.maps.Size(35, 35)
            }));
            marker.setPosition(place.geometry.location);
            marker.setVisible(true);

            /* var address = '';
             if (place.address_components) {
             address = [
             (place.address_components[0] && place.address_components[0].short_name || ''),
             (place.address_components[1] && place.address_components[1].short_name || ''),
             (place.address_components[2] && place.address_components[2].short_name || '')
             ].join(' ');
             } */
            var address = place.formatted_address;

            infowindow.setContent('<div><strong>' + place.name + '</strong><br>' + address);
            infowindow.open(map, marker);

            this.$('input[data-attr=' + dir + '_address_short]')
                .val(address).trigger('change');
            this.$('input[data-attr=' + dir + '_lat]')
                .val(place.geometry.location.lat()).trigger('change');
            this.$('input[data-attr=' + dir + '_lng]')
                .val(place.geometry.location.lng()).trigger('change');

            // Now do a lookup on the lat,lng to determine the timezone
            var ll = place.geometry.location;
            var url = 'https://maps.googleapis.com/maps/api/timezone/json' +
                    '?location=' + ll.lat() + ',' + ll.lng() +
                    '&timestamp=' + Math.floor(new Date().getTime() / 1000) +
                    '&key=' + App.config.googleBrowserKey +
                    '&sensor=false'
                ;
            $.get(url, $.proxy(function(data) {
                this.otherTimezone = data.timeZoneId;
                this.$('.js-' + dir + '_timezone').val(data.timeZoneId).trigger('change');

                this.trigger('timezone-changed');
                this.toggleTimezoneVisibility();
            }, this));
        }, this));
    }

});