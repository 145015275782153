// @flow

import type { Gig } from '../../models';
import type { RootState } from '../../types';

import * as React from 'react';
import { useSelector } from 'react-redux';

import { SubHeader, SubHeaderButton, SubHeaderSpacer } from '../../components';
import { SERVER_ROOT } from '../../config';
import { useMixedUser } from '../../hooks';

type Props = $ReadOnly<{|
  gig: Gig | null,
|}>;

export default function GigSubHeader({ gig }: Props) {
  const mixedUser = useMixedUser();
  const { lastGigsPath } = useSelector<RootState, _>(
    (state) => state.tempOptions
  );

  return (
    <SubHeader>
      {mixedUser.type === 'Account' && (
        <SubHeaderButton
          to={lastGigsPath || '/gigs'}
          label="Back to Gigs"
          iconName="list"
        />
      )}
      <SubHeaderSpacer />
      {gig && (
        <SubHeaderButton
          label="Download As PDF"
          iconName="file-pdf"
          href={`${SERVER_ROOT}/download/gig-pdf/${gig.pdfSecret}`}
        />
      )}
    </SubHeader>
  );
}
