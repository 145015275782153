App.Models.Hotel = Backbone.RelationalModel.extend({
    urlRoot: () => App.config.baseUrl + 'api-platform/hotels',
    idAttribute: 'id',
    validate: function(attrs, options) {
        if(!attrs.name) {
            return "You must supply a hotel name";
        }
    },

    relations: [
        {
            type: Backbone.HasOne,
            key: 'address',
            relatedModel: 'App.Models.Address',
            reverseRelation: {
                includeInJSON: false
            }
        }
    ]
});