App.Collections.Gigs = Backbone.Collection.extend({

    model: App.Models.Gig,

    // Are we looking at past gigs, or upcoming?
    type: null,

    // Are we looking at an index of gigs, i.e. only need to fetch minimal fields?
    minimal: null,

    // Has a fetch been triggered/completed?
    fetched: false,

    initialize: function(options) {
        this.type = options ? options.type : null;
        this.minimal = options ? options.minimal : false;
    },

    url: function() {
        return App.config.baseUrl + 'api-platform/gigs' + (this.minimal ? '/minimal' : '')
            + '?type=' + this.type;
    }

});