// @flow

import * as React from 'react';
import styled from 'styled-components';

import SpinePiece from './SpinePiece';

type Props = $ReadOnly<{|
  ending?: boolean,
  children: React.Node,
|}>;

export default function SpineRow({ ending, children }: Props) {
  return (
    <StyledContainer>
      <SpinePiece ending={ending} bullet />
      {children}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  align-items: stretch;
`;
