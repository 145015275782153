// @flow
/* eslint-disable no-undef */

import type { GigEntities } from '../../hooks/entities-store';

// $FlowExpectedError
import './backbone/imports';

import * as React from 'react';

import PageWrap from '../../components/PageWrap';
import { useEntitiesStore } from '../../hooks';
import schemas from '../../schemas';
import { idToIri } from '../../util';
import GigSubHeader from './GigSubHeader';

type Props = $ReadOnly<{|
  match: { params: { id: string } },
|}>;

export default function GigPage({ match }: Props) {
  const { id } = match.params;

  /**
   * This isn't passed to Backbone, just used for rendering a couple of things
   * in the modern React components
   */
  const { entities } = useEntitiesStore<GigEntities>(
    `/gigs/${id}`,
    schemas.Gig
  );
  const gig = entities?.Gig[idToIri('gigs', id)];

  React.useEffect(() => {
    _.compile = function (templ) {
      const compiled = this.template(templ);
      compiled.render = function (ctx) {
        return this(ctx);
      };
      return compiled;
    };

    App.config.gig = {
      id,
      brandSlug: 'rooted',
    };

    const href = window.location.pathname,
      gigId = href.substr(href.lastIndexOf('/') + 1);

    App.timezones = [
      /* {% for tz in timezones %}
          '{{ tz }}'{% if not loop.last %},{% endif %}
      {% endfor %} */
    ];

    // App-wide event aggregator
    App.vent = _.extend({}, Backbone.Events);

    // Use this when changes are made
    App._unsaved = null;
    App.setUnsaved = function (model) {
      App._unsaved = model;
    };
    App.getUnsaved = function () {
      return App._unsaved;
    };
    App.resetUnsaved = function () {
      App._unsaved = null;
    };

    // Set up controller
    App.gigController = new App.GigController({
      model: App.Models.Gig.findOrCreate({ id: gigId }),
    });

    // Set up router
    App.gigRouter = new App.Routers.Tabs();
    Backbone.history.stop();
    Backbone.history.start();

    $('.js-all-gigs-link').attr(
      'href',
      '/gigs#grid/upcoming/' + App.Helpers.slugifyActName('{{ gig.act.name }}')
    );

    return () => {
      // Reset the Backbone Relational store after they leave the page, to stop
      // models lingering around for later visits and breaking things.
      Backbone.Relational.store.reset();
    };
  }, [id]);

  return (
    <PageWrap subHeader={<GigSubHeader gig={gig || null} />}>
      <div className="gig-single">
        <div className="gig-banner"></div>
        <div className="hcont">
          <div className="gig-tabs"></div>
          <div className="gig-main">
            <div className="gig-main__padded-content js-app-main"></div>
          </div>
        </div>
      </div>
    </PageWrap>
  );
}
