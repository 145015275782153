// @flow

import type { TypedFieldProp } from 'react-typed-form';

import * as React from 'react';
import styled from 'styled-components';

import { ErrorList } from '../../../components';

type Props = $ReadOnly<{|
  field: TypedFieldProp<string | null>,
  hasContentBelow?: boolean,
|}>;

export default function FieldModalTextarea({ field, hasContentBelow }: Props) {
  return (
    <>
      <StyledTextarea
        placeholder="Add notes…"
        autoFocus
        value={field.value || ''}
        onChange={(e) => field.handleValueChange(e.target.value || null)}
        disabled={field.isLoading}
        hasContentBelow={hasContentBelow}
      />
      {field.lastErrorList && (
        <StyledAreaErrors>
          <ErrorList errors={field.lastErrorList} />
        </StyledAreaErrors>
      )}
    </>
  );
}

const StyledTextarea = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  border: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 20px;
  height: 180px;
  font-size: 16px;
  display: block;
  margin: 0;
  ::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  ${(p) =>
    !p.hasContentBelow &&
    `
    height: 230px;
    border-radius: 5px;
  `};
`;

const StyledAreaErrors = styled.div`
  background-color: #f2f2f2;
  padding: 10px 20px 0;
`;
