// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = $ReadOnly<{|
  children: React.Node,
|}>;

export default function TableGroup({ children }: Props) {
  return (
    <>
      <StyledTbody>{children}</StyledTbody>
      <StyledSpacer>
        <tr />
      </StyledSpacer>
    </>
  );
}

const StyledTbody = styled.tbody`
  margin-bottom: 5px;
  margin-right: 100px;
  overflow: hidden;
  border-radius: 30px;
  /* Looked good, but made it hard to support runs of gigs */
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); */
`;

const StyledSpacer = styled.tbody`
  tr {
    height: 12px;
  }
`;
