// @flow

import styled from 'styled-components';

export default styled.div`
  height: 50px;
  background-color: #e9e9e9;
  display: flex;
  align-items: stretch;
`;
