App.Routers.Filters = Backbone.Router.extend({
    routes: {
        '': 'index',
        ":layout/:type(/:act)": "doFilter"
    },

    index: function() {
        // console.log('filters.router: index');
        App.filters.set({
            layout: 'grid',
            type: 'upcoming',
            act: null
        });
    },

    doFilter: function (layout, type, act) {
        // console.log('filters.router: doFilter ' + layout + '/' + type + '/' + act);
        App.filters.set({
            layout: layout,
            type: type,
            act: act
        });
    }
});