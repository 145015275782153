// @flow

import { normalize } from 'normalizr';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useApiCallable } from '../../../../api';
import { Modal, ModalStandardWrap } from '../../../../components';
import { useHardEntities } from '../../../../hooks';
import schemas from '../../../../schemas';
import { snakeToCamel, transformKeysRecursive } from '../../../../util';
import CreateGigModal from './CreateGigModal';

type Props = $ReadOnly<{|
  onDismiss: () => mixed,
  upsertEntities: (entity: {}, schema: mixed) => void,
|}>;

export default function CreateGigModalLoader(props: Props) {
  const hardVenues = useHardEntities().Venue;
  const dispatch = useDispatch();
  const { callApi } = useApiCallable();

  React.useEffect(() => {
    async function loadVenues() {
      if (!hardVenues) {
        const result = await callApi({ path: '/venues/typeahead' });
        const data = transformKeysRecursive(result.data, snakeToCamel);
        dispatch({
          type: 'HARD_ENTITIES_VENUES_SUCCESS',
          payload: normalize(data, [schemas.Venue]).entities.Venue,
        });
      }
    }
    loadVenues();
  }, [callApi, dispatch, hardVenues]);

  return hardVenues ? (
    <CreateGigModal {...props} hardVenues={hardVenues} />
  ) : (
    <Modal wide onDismiss={props.onDismiss}>
      <ModalStandardWrap>Loading venues list...</ModalStandardWrap>
    </Modal>
  );
}
