// @flow

import * as React from 'react';
import styled from 'styled-components';

import { Icon } from '../../../../components';

type Props = $ReadOnly<{|
  title: string,
  editing?: boolean,
  onEditPress?: () => void,
  minWidth?: number,
  children: React.Node,
|}>;

export default function Section({
  title,
  editing,
  onEditPress,
  minWidth,
  children,
}: Props) {
  return (
    <StyledContainer $minWidth={minWidth}>
      <StyledAreaTop>
        <strong>{title}</strong>

        {onEditPress && !editing && (
          <a onClick={onEditPress}>
            <Icon name="pencil" />
          </a>
        )}
      </StyledAreaTop>
      <StyledAreaMain>{children}</StyledAreaMain>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: ${(p) => p.$minWidth || 240}px;
`;

const StyledAreaTop = styled.div`
  border-bottom: 1px #ccc solid;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  strong {
    font-size: 17px;
    font-weight: 400;
  }
`;

const StyledAreaMain = styled.div`
  padding: 12px 0;
`;
