App.Views.Tabs = Backbone.View.extend({

    template: _.template($("#template-tabs").html()),
    currentTab: null,

    initialize: function () {
        this.currentTab = this.className;
        this.listenTo(App.vent, 'tab:changed', function(name) {
            this.currentTab = name;
            this.accentTab(this.currentTab);
        });

        this.listenTo(this.model, 'sync', this.render);
        this.listenTo(this.model, 'change ' +
            'add:hotel add:venue add:schedule_items add:contacts add:artist_sets add:documents add:party_users ' +
            'remove:hotel remove:venue remove:schedule_items remove:contacts remove:artist_sets remove:documents remove_party_users'
            , this.render);
    },

    render: function () {
        this.$el.html(this.template({
            currentTab: this.currentTab,
            gig: this.model.toJSON(),
            party_users: this.model.get('party_users').toJSON(),
            schedule_items: this.model.get('schedule_items').toJSON(),
            contacts: this.model.get('contacts').toJSON(),
            artist_sets: this.model.get('artist_sets').toJSON(),
            documents: this.model.get('documents').toJSON(),
            roomingDone: this.isRoomingDone()
        }));
        this.accentTab(this.currentTab);
        return this;
    },

    accentTab: function(name) {
        this.$('.js-gig-tab').removeClass('gig-tab--active');
        this.$('.js-gig-tab[data-show="' + name + '"]').addClass('gig-tab--active');
    },

    isRoomingDone: function() {
        var anythingFilled = false;
        this.model.get('party_users').each(function(partyUser) {
            if (
                partyUser.get('hotel_reservation_number')
            ) {
                anythingFilled = true;
            }
        });
        return anythingFilled;
    }
});