// @flow

import type { AccountUser, TokenUser } from '../reducers/auth';
import type { RootState } from '../types';

import { useSelector } from 'react-redux';

export function useMixedUser(): AccountUser | TokenUser {
  const { user } = useSelector<RootState, _>((state) => state.auth);
  if (!user) throw new Error('Called useAccountUser without being logged in');
  return user;
}

export function useAccountUser(): AccountUser {
  const user = useMixedUser();
  if (user.type !== 'Account')
    throw new Error("Called useAccountUser while user.type isn't Account");
  return user;
}

export function useTokenUser(): TokenUser {
  const user = useMixedUser();
  if (user.type !== 'Token')
    throw new Error("Called useAccountUser while user.type isn't Token");
  return user;
}
