// @flow

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Provider as ReduxProvider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

import { AnalyticsManager, Spinner, UpdateChecker } from '../components';
import { configureStoreAsync } from '../store';
import AppAuthCheck from './AppAuthCheck';

export default function AppLoader() {
  const [store, setStore] = React.useState(null);

  React.useEffect(() => {
    async function loadStore() {
      const result = await configureStoreAsync();
      setStore(result);
    }
    loadStore();
  }, []);

  const common = (
    <>
      <GlobalStyle />
      <Helmet>
        <link rel="shortcut icon" href="/img/favicon.png" />
        <link rel="icon" sizes="32x32" href="/img/favicon.png" />
      </Helmet>
      <UpdateChecker />
    </>
  );

  return store ? (
    <ReduxProvider store={store}>
      {common}
      <AppAuthCheck />
      <AnalyticsManager />
    </ReduxProvider>
  ) : (
    <>
      {common}
      <Spinner message="Loading saved state" noBars />
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #fafafa;
    color: #585858;
    /* Also in legacy-rooted.css, which probably wins specificity */
    font-family: 'Roboto', Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.5;
  }
`;
