// @flow

import * as React from 'react';

import SimpleWrap from './SimpleWrap';

export default function PrivacyPage() {
  return (
    <SimpleWrap>
      <h2>Artist mobile app</h2>
      <ol>
        <li>
          The artist mobile app does not actively request any personal data or
          information from users. Its sole purpose is as an information browsing
          tool, for content available to a given user.
        </li>
        <li>
          The only data collected by the app is high-level usage data (features
          of app used, time in app, etc.), and crash reports. These are solely
          used by Rooted Touring Ltd to improve our product, and never sold to
          any third parties.
        </li>
        <li>
          The app uses location services to show the user&apos;s current
          location on a map. This information is never recorded or sent to our
          servers.
        </li>
        <li>
          All content coming from our servers is delivered with SSL encryption.
        </li>
        <li>
          Privacy bugs are taken extremely seriously, and will be tackled with
          urgency. If you notice a bug, please report it to us by email rather
          than disclosing publicly.
        </li>
      </ol>

      <h2>Back-office website</h2>
      <ol>
        <li>
          Information provided during usage of the back-office system is treated
          as private data belonging to the registered brand. The brand retains
          full ownership of this data, and this data will never be shared with
          or made available to other parties.
        </li>
        <li>
          The only time that a brand&apos;s data may be accessed is when a
          member of that brand explicitly requests for assistance with an issue.
          In this case a member of our customer support team will use their
          discretion to access as much data as is needed to understand and
          resolve the problem.
        </li>
        <li>
          The website uses cookies to uniquely identify users. We use this in
          conjunction with the collection of high-level usage data (pages
          viewed, features used, time on website, etc), so we can monitor usage
          patterns and improve our product.
        </li>
        <li>
          When a brand closes their account, all submitted data (excluding some
          usage data) will be removed from our system within 60 days.
        </li>
        <li>
          All content coming from our servers is delivered with SSL encryption.
        </li>
        <li>
          Privacy bugs are taken extremely seriously, and will be tackled with
          urgency. If you notice a bug, please report it to us by email rather
          than disclosing publicly.
        </li>
      </ol>
    </SimpleWrap>
  );
}
