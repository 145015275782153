// @flow

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

/**
 * Call from inside any of the gigs pages with filters to remember the current
 * URL for later use.
 */
export default function useLastGigsPath() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  React.useEffect(() => {
    dispatch({ type: 'SET_LAST_GIGS_PATH', payload: pathname });
  }, [dispatch, pathname]);
}
