App.Views.Memo = App.Views.BaseItem.extend({

    editTemplate: '#template-memo-edit',
    viewTemplate: '#template-memo',
    editing: false,

    locView: null,

    initialize: function(options) {
        this.options = _.extend({}, this._options, {
            // defaults
        }, options);

        this.buildEvents();
        this.model.startTracking();
        
        this.listenTo(this.model.get('trips'), 'add remove', this.disableCancel);
    },

    serializeData: function() {
        return {
            memo: this.model.toJSON()
        };
    },

    injectSubviews: function() {
        this.locView = new App.Views.Widgets.Location({
            model: this.model, dirName: 'loc', dirLabel: 'Location'
        });
        this.$('.js-loc-view').html(this.locView.render().el);

        var startView = new App.Views.Widgets.DateTime({
            parentView: this, model: this.model, dirName: 'start',
            dirLabel: 'Start (local time)'
        });
        this.$('.js-start-view').html(startView.render().el);
        this.$('.js-finish-view').html(new App.Views.Widgets.DateTime({
            parentView: this, model: this.model, dirName: 'finish',
            dirLabel: 'Finish (local time)', syncWith: startView
        }).render().el);

        this.$('.js-trip-selection').html(new App.Views.Widgets.TripSelection({
            parentView: this, model: this.model, label: 'Who\'s included?'
        }).render().el);
    },

    onRender: function() {

        var $bottom = this.$('.js-journey-bottom');
        if ($bottom.text().trim() === '') {
            $bottom.hide();
        }
    },

    onBeforeSave: function() {
        this.attachGig();

        // Save the address ID
        this.locView.onBeforeSave();
    },

    onSave: function() {
        // Sort order may have changed, re-render the collection
        this.model.trigger('sort-order-changed');
    }

});