// @flow

import * as Sentry from '@sentry/browser';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import analytics from 'unlocked-analytics';

import AppLoader from './app/AppLoader';
import { APP_ENV, SERVER_ROOT } from './config';

if (APP_ENV !== 'dev') {
  Sentry.init({
    dsn: 'https://446d9083f442496f8dd344d420762e84@sentry.io/1869674',
    environment: APP_ENV,
  });
}

analytics.load('_', `${SERVER_ROOT}/analytics`);
analytics.setContext({
  app: {
    name: 'rooted-web',
  },
  screen: {
    height: window.screen.height,
    width: window.screen.width,
  },
});

const { body } = document;
if (!body) throw new Error('body not found');
let root = document.querySelector('#root');
if (!root) {
  root = document.createElement('div');
  root.id = 'root';
  body.appendChild(root);
}

ReactDOM.render(
  <BrowserRouter>
    <AppLoader />
  </BrowserRouter>,
  root
);
