App.Models.Document = App.Models.Base.extend({

    relations: [
        {
            type: Backbone.HasOne,
            key: 'folder',
            relatedModel: 'App.Models.Folder',
            includeInJSON: 'id'
        }
    ],

    iriFields: ['gig', 'folder'],
    
    getExtension: function() {
        return this.get('filename').split('.').pop().toLowerCase();
    }

});