// @flow

import type { RootState } from '../types';

import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import analytics from 'unlocked-analytics';

export default function AnalyticsManager() {
  const { user } = useSelector<RootState, _>((state) => state.auth);
  const { pathname } = useLocation();

  React.useEffect(() => {
    analytics.mergeOptions({
      headers: user?.apiKey
        ? { Authorization: `Basic ${window.btoa(user.apiKey)}` }
        : {},
    });
  }, [user]);

  React.useEffect(() => {
    analytics.screen(pathname);
  }, [pathname]);

  return null;
}
