// @flow

import type { V_Gig_Travel } from '../../../../models';

import * as React from 'react';

import { useApiRead } from '../../../../api';
import { Color } from '../../../../constants';
import { WorkflowDropdownPreview } from '../../components';

type Props = $ReadOnly<{|
  gigId: number,
|}>;

export default function DropdownPreview({ gigId }: Props) {
  const api = useApiRead<V_Gig_Travel>({ path: `/gigs/${gigId}/travel` });

  const gig = api.data;
  if (api.error) return 'Error!';
  if (!gig) return 'Loading...';

  return (
    <WorkflowDropdownPreview
      columnTitles={['In', 'Out']}
      rows={[
        {
          values: [gig.travelOptionsSentIn, gig.travelOptionsSentOut],
          title: 'Options Sent',
          onlyCompletedRowLabelColor: Color.orange,
        },
        {
          values: [gig.travelIssuedIn, gig.travelIssuedOut],
          title: 'Issued',
        },
        {
          values: [gig.travelAddedToAppIn, gig.travelAddedToAppOut],
          title: 'Added To App',
        },
      ]}
      notes={gig.travelNotes}
    />
  );
}
