// @flow

import type { V_Gig_Table } from '../../../models';
import type { TableRowBackgroundColor } from '../../../types';

import * as React from 'react';
import styled from 'styled-components';

import { TableCell } from '../../../components';
import Spacer from '../../../components/Spacer';
import { Color } from '../../../constants';
import CircleBlank from './CircleBlank';
import CircleIcon from './CircleIcon';

type Props = $ReadOnly<{|
  gig: V_Gig_Table,
  backgroundColor: TableRowBackgroundColor,
|}>;

export default React.memo<Props>(function PaymentsCell({
  gig,
  backgroundColor,
}: Props) {
  return (
    <TableCell gap="left" backgroundColor={backgroundColor} last>
      <StyledRow>
        {gig.travelPromoterCost ? (
          <CircleIcon iconName="flight" color={Color.orange} />
        ) : (
          <CircleBlank />
        )}
        <Spacer />
        {gig.hotelArtistPaying || gig.hotelNtrpBooking ? (
          <CircleIcon
            iconName="bed"
            color={gig.hotelArtistPaying ? '#999' : Color.red}
          />
        ) : (
          <CircleBlank />
        )}
      </StyledRow>
    </TableCell>
  );
});

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;
