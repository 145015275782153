// @flow

import type { TypedFieldProp } from 'react-typed-form';
import type { Iri } from '../types';

import * as React from 'react';
import styled from 'styled-components';

import { TextInput } from '../components';
import { ZIndex } from '../constants';

export const PARTIAL = 'partial';

type Choice = $ReadOnly<{|
  value: Iri,
  title: string,
  description?: ?string,
  editHref?: string,
|}>;

type Props = $ReadOnly<{|
  choices: $ReadOnlyArray<Choice>,
  field: TypedFieldProp<Iri | null | typeof PARTIAL>,
  hint?: string,
|}>;

export default function FieldTypeahead({ choices, field, ...rest }: Props) {
  const [inputValue, setInputValue] = React.useState('');
  const [focusedIndex, setFocusedIndex] = React.useState(null);

  const query = inputValue ? inputValue.trim().toLowerCase() : '';
  const suggestions = choices
    .filter((choice) => {
      const parts = query
        .split(' ')
        .map((p) => p.trim())
        .filter(Boolean);
      if (parts.length === 0) return false;

      for (const part of parts) {
        if (!choice.title.toLowerCase().includes(part)) return false;
      }

      return true;
    })
    .sort((a, b) => a.title.length - b.title.length)
    .slice(0, 5);

  const findChoice = (iri: Iri): Choice | void =>
    choices.find((c) => c.value === iri);

  return (
    <StyledContainer>
      <StyledTextInput
        label={field.label}
        value={
          field.value && field.value !== PARTIAL
            ? findChoice(field.value)?.title
            : inputValue
        }
        disabled={field.isLoading}
        errors={field.lastErrorList}
        onChange={(e) => {
          const val = e.target.value;
          field.handleValueChange(val.trim() ? PARTIAL : null);
          setInputValue(val);
        }}
        onKeyDown={(ev) => {
          if (ev.keyCode === 40) {
            // Down
            if (
              focusedIndex != null &&
              focusedIndex >= suggestions.length - 1
            ) {
              setFocusedIndex(null);
            } else {
              setFocusedIndex(focusedIndex == null ? 0 : focusedIndex + 1);
            }
          } else if (ev.keyCode === 38) {
            // Up
            if (focusedIndex != null && focusedIndex >= 1) {
              setFocusedIndex(focusedIndex - 1);
            } else {
              setFocusedIndex(null);
            }
          } else if (ev.keyCode === 9 || ev.keyCode === 13) {
            // Tab or enter
            if (focusedIndex == null) return;
            const suggestion = suggestions[focusedIndex];
            if (!suggestion) return;

            field.handleValueChange(suggestion.value);
            setInputValue('');

            // Don't submit the form (but allow tabbing to next field)
            if (ev.keyCode === 13) {
              ev.preventDefault();
            }
          }
        }}
        {...rest}
      />

      {suggestions.length > 0 && (
        <StyledSuggestions>
          {suggestions.map((s, i) => (
            <StyledSuggestion
              key={s.value.toString()}
              focused={focusedIndex === i}
            >
              <StyledSuggestionMain
                onClick={() => {
                  field.handleValueChange(s.value);
                  setInputValue('');
                }}
              >
                <strong>{s.title}</strong>
                {s.description && <span>{s.description}</span>}
              </StyledSuggestionMain>
            </StyledSuggestion>
          ))}
        </StyledSuggestions>
      )}
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  position: relative;
`;

const StyledTextInput = styled(TextInput)`
  margin-bottom: 15px;
`;

const StyledSuggestions = styled.ul`
  margin: 0;
  padding: 0;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  list-style-type: none;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: ${ZIndex.typeaheadSuggestions};
`;

const StyledSuggestion = styled.li`
  margin: 0;
  padding: 0;
  border-bottom: 1px #ddd solid;
  display: flex;
  align-items: stretch;

  ${(p) =>
    p.focused &&
    `
    background-color: #2a87d0;
    a, a:hover { color: #fff; }
  `};
`;

const StyledSuggestionMain = styled.a`
  flex: 1;
  padding: 8px 16px;
  color: #444;

  strong {
    display: block;
    font-weight: 400;
    font-size: 16px;
  }

  span {
    display: block;
    font-size: 13px;
  }
`;
