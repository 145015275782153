'use strict';

// Reference implementation for an item in a collection - keep this clean!
App.Views.Contact = App.Views.BaseItem.extend({

    editTemplate: '#template-contact-edit',
    viewTemplate: '#template-contact',
    editing: false,

    events: {
        'change select[data-attr=type]': 'checkTypeCustomVisible'
    },

    initialize: function(options) {
        this.options = _.extend({}, this._options, {
            // defaults
        }, options);

        this.buildEvents();
        this.model.startTracking();
    },

    serializeData: function() {
        return {
            contact: this.model.toJSON(),
            errors: this.model.validationError
        };
    },

    onRender: function () {
        this.$('select[data-attr=type]').val(this.model.get('type'));
        this.checkTypeCustomVisible();

        this.$('.js-phone').intlTelInput({
            defaultCountry: 'gb',
            utilsScript: '/js/intl-tel-input-utils.js'
        });
    },

    onBeforeValidate: function() {
        // Get the phone number including country code
        var mobile = this.$('.js-phone').intlTelInput('getNumber');
        this.model.set('phone', typeof mobile == 'string' ? mobile : null);
    },

    onBeforeSave: function() {
        this.attachGig();
    },

    onSave: function() {
        // Sort order may have changed, re-render the collection
        this.model.trigger('sort-order-changed');
    },

    checkTypeCustomVisible: function() {
        this.$('input[data-attr=type_custom]')
            .toggle(this.$('select[data-attr=type]').val() === 'other');
    }
});