// @flow

import * as React from 'react';
import styled from 'styled-components';

type Props = {
  content: string,
};

export default React.memo<Props>(function NotesDropdownPreview({
  content,
}: Props) {
  const truncatedContent =
    content.length > 200 ? `${content.substr(0, 180)}…` : content;

  return <StyledDropdownContent>{truncatedContent}</StyledDropdownContent>;
});

const StyledDropdownContent = styled.div`
  white-space: pre-wrap;
  font-size: 14px;
  text-align: left;
`;
